import React from 'react';
import { Avatar } from '@progress/kendo-react-layout';
import './styles.css';

const itemMenu = (item) => {
  const isImage = item.image?.length > 2;

  return (
    <div className="navbar-item">
      {item.image ? (
        <>
          <Avatar
            shape="circle"
            type="text"
            style={{ backgroundColor: item.imageColor }}
            className={isImage && item.fillAll ? 'fill-all' : ''}
          >
            {isImage ? <img alt={item.imageAlt} src={item.image} /> : item.image}
          </Avatar>
          <span>{item.text}</span>
        </>
      ) : (
        <p>{item.text}</p>
      )}
    </div>
  );
};

export default itemMenu;

import './style.css';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Upload } from '@progress/kendo-react-upload';
import TransparentCard from '../../transparentCard';
import { errorNotification, successNotification } from '../../notification';
import Popup from '../layout';

const uploadRef = React.createRef();

const PopUpUploadFile = ({ visible, setVisible, title, allowedExtensions, uploadFile }) => {
  const [fileSource, setFileSource] = useState(null);
  const [message, setMessage] = useState(null);

  const confirmAddFile = async () => {
    await uploadFile(fileSource.getRawFile());
    setFileSource(null);
    setMessage(null);
  };

  return (
    <>
      <Popup
        width={520}
        title={title}
        visible={visible}
        setVisible={setVisible}
        cancelText="Cancelar"
        confirmText="Enviar arquivo"
        onConfirm={(event) => {
          if (fileSource == null) {
            throw Error('Arquivo n�o selecionado.');
          } else {
            confirmAddFile();
          }
        }}
      >
        <Upload
          ref={uploadRef}
          autoUpload={true}
          restrictions={{ allowedExtensions }}
          onAdd={(event) => {
            setFileSource(event.newState[0]);
            setMessage(event.newState[0].name);
            event.affectedFiles.forEach((file) => {
              if (file.validationErrors?.length > 0) {
                if (file.validationErrors[0] === 'invalidFileExtension') {
                  setFileSource(null);
                  setMessage(
                    `Extens�o do arquivo � inv�lida. Somente arquivos ${allowedExtensions} s�o permitidos.`
                  );
                }
              }
            });
          }}
        />
        <TransparentCard
          className="selected-file-phrase"
          content={message != null ? message : 'Nenhum arquivo selecionado.'}
        />
      </Popup>
    </>
  );
};

PopUpUploadFile.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpUploadFile;

export const dayOfWeek = {
  0: "Domingo",
  1: "Segunda-feira",
  2: "Ter�a-feira",
  3: "Quarta-feira",
  4: "Quinta-feira",
  5: "Sexta-feira",
  6: "S�bado"
};

export const month = {
  0: "Janeiro",
  1: "Fevereiro",
  2: "Mar�o",
  3: "Abril",
  4: "Maio",
  5: "Junho",
  6: "Julho",
  7: "Agosto",
  8: "Setembro",
  9: "Outubro",
  10: "Novembro",
  11: "Dezembro"
}

export const frequencies = [
  'Di�rio',
  'Semanal',
  'Quinzenal',
  'Mensal',
  'Bimestral',
  'Trimestral',
  'Semestral',
  'Anual'
];

export const times = [
  'Dia(s)',
  'Semana(s)',
  'Quinzena(s)',
  'Mes(es)',
  'Bimestre(s)',
  'Trimestre(s)',
  'Semestre(s)',
  'Ano(s)'
];

export const addDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const calculateNewDate = (date, frequency) => {
  if (date !== null && date !== undefined &&
    frequency != null && frequency !== undefined) {
    const newDate = new Date(date);
    switch (frequency) {
      case 'Di�rio':
        return new Date(addDays(newDate, 1));
      case 'Semanal':
        return new Date(addDays(newDate, 7));
      case 'Quinzenal':
        return new Date(addDays(newDate, 15));
      case 'Mensal':
        return new Date(newDate.setMonth(newDate.getMonth() + 1));
      case 'Bimestral':
        return new Date(newDate.setMonth(newDate.getMonth() + 2));
      case 'Trimestral':
        return new Date(newDate.setMonth(newDate.getMonth() + 3));
      case 'Semestral':
        return new Date(newDate.setMonth(newDate.getMonth() + 6));
      case 'Anual':
        return new Date(newDate.setMonth(newDate.getMonth() + 12));
      default:
        return newDate;
    }
  }
  return date;
};

export const calculateNewDateFrequency = (date, frequency) => {
  if (date !== null && date !== undefined &&
    frequency != null && frequency !== undefined) {
    const newDate = new Date(date);
    switch (frequency) {
      case 0:
        return new Date(addDays(newDate, 1));
      case 1:
        return new Date(addDays(newDate, 7));
      case 2:
        return new Date(addDays(newDate, 15));
      case 3:
        return new Date(newDate.setMonth(newDate.getMonth() + 1));
      case 4:
        return new Date(newDate.setMonth(newDate.getMonth() + 2));
      case 5:
        return new Date(newDate.setMonth(newDate.getMonth() + 3));
      case 6:
        return new Date(newDate.setMonth(newDate.getMonth() + 6));
      case 7:
        return new Date(newDate.setMonth(newDate.getMonth() + 12));
      default:
        return newDate;
    }
  }
  return date;
};

export const FirstDayOfMonth = (date) => {
  const newDate = new Date(date.getFullYear(), date.getMonth(), 1);
  return newDate;
};

export const LastDayOfMonth = (date) => {
  const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return newDate;
};

export const compareMonthYear = (date1, date2) => {
  return (date1.getMonth() == date2.getMonth() && date1.getFullYear() == date2.getFullYear());
};

export const calculateFinalDate = (date, amount, frequency) => {
  if (date !== null && date !== undefined &&
    amount !== null && amount !== undefined &&
    frequencies != null && frequencies !== undefined) {
    const newDate = new Date(date);
    switch (frequency) {
      case 'Dia(s)':
        return new Date(addDays(newDate, amount));
      case 'Semana(s)':
        return new Date(addDays(newDate, 7 * amount));
      case 'Quinzena(s)':
        return new Date(addDays(newDate, 15 * amount));
      case 'Mes(es)':
        return new Date(newDate.setMonth(newDate.getMonth() + amount));
      case 'Bimestre(s)':
        return new Date(newDate.setMonth(newDate.getMonth() + 2 * amount));
      case 'Trimestre(s)':
        return new Date(newDate.setMonth(newDate.getMonth() + 3 * amount));
      case 'Semestre(s)':
        return new Date(newDate.setMonth(newDate.getMonth() + 6 * amount));
      case 'Ano(s)':
        return new Date(newDate.setMonth(newDate.getMonth() + 12 * amount));
      default:
        return newDate;
    }
  }
  return date;
};

export const getDateDiffInDays = (date1, date2) => {
  const diffTime = date2 - date1;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const DateNow = () => {
  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const DateTimeNow = () => {
  const newDate = new Date();
  return newDate;
};


export const CheckSameDate = (d1, d2) => {
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  let time1 = date1.getTime();
  let time2 = date2.getTime();
  if (time1 == time2)
    return true
  return false;
};


export const CheckValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

import PropTypes from 'prop-types';
import React from 'react';
import '../style.css';
import './style.css';

const OrangeButton = ({ children, type, disable, ...other }) => {
  const className = 'orange-button box-button general-button';
  switch (type) {
    case 'file':
      return (
        <label className={className}>
          <input {...other} type={type} />
          {children}
        </label>
      );
    case 'submit':
      return (
        <button {...other} type="submit" className={className} disabled={disable}>
          {children}
        </button>
      );
    default:
      return (
        <button {...other} type="button" className={className} disabled={disable}>
          {children}
        </button>
      );
  }
};

OrangeButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  disable: PropTypes.bool
};

OrangeButton.defaultProps = {
  type: 'button',
  disable: false
};

export default OrangeButton;

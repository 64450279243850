import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

const FormRow = ({
  children,
  className,
  space15,
  space10,
  justifyEnd,
  withShrink,
  spaceBetween,
  withoutMarginTop,
  halfSize,
  alignCenter,
  marginTop40,
  alignTop
}) => (
  <div
    className={'form-row'.concat(
      className,
      space10 ? ' space-10' : '',
      space15 ? ' space-15' : '',
      justifyEnd ? ' justify-end' : '',
      withShrink ? ' with-shrink' : '',
      spaceBetween ? ' space-between' : '',
      withoutMarginTop ? ' without-margin-top' : '',
      halfSize ? ' half-size' : '',
      alignCenter ? ' align-center' : '',
      alignTop ? ' align-top' : '',
      marginTop40 ? ' margin-top-40' : ''
    )}
  >
    {children}
  </div>
);

FormRow.propTypes = {
  children: PropTypes.node,
  space10: PropTypes.bool,
  space15: PropTypes.bool,
  withShrink: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  withoutMarginTop: PropTypes.bool,
  halfSize: PropTypes.bool,
  className: PropTypes.string,
  alignCenter: PropTypes.bool,
  marginTop40: PropTypes.bool
};

FormRow.defaultProps = {
  children: <></>,
  space10: false,
  space15: false,
  withShrink: false,
  justifyEnd: false,
  spaceBetween: false,
  withoutMarginTop: false,
  halfSize: false,
  className: '',
  alignCenter: false,
  marginTop40: false
};

export default FormRow;

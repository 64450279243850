import { getTokenFromStorage, TOKENS } from "../auth/Tokens";
import { setApiToken } from './AxiosRequest';

export const setUserTokenInAPI = () => setApiToken(getTokenFromStorage(TOKENS.USER.TOKEN));

export const setUserRefreshTokenInAPI = () => setApiToken(getTokenFromStorage(TOKENS.USER.REFRESH_TOKEN));

export const setWalletTokenInAPI = () => setApiToken(getTokenFromStorage(TOKENS.WALLET.TOKEN));

export const setWalletRefreshTokenInAPI = () => setApiToken(getTokenFromStorage(TOKENS.WALLET.REFRESH_TOKEN));

export const setBaseTokenInAPI = () => setApiToken(process.env.REACT_APP_AUTH_TOKEN);

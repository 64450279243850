import PropTypes from 'prop-types';
import React from 'react';
import RadioButton from './radioButton';
import './styles.css';

const RadioGroup = ({ elements, group, setValue }) => (
  <div className="radio-group" onChange={(e) => setValue(e.target.value)}>
    {elements.map(({ id, value, label, checked }) => (
      <RadioButton id={id} key={id} name={group} value={value} label={label} checked={checked} />
    ))}
  </div>
);

RadioGroup.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  group: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
};

export default RadioGroup;

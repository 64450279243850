export const saveByteArray = (reportName, base64, tipo) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  let blob = null;
  switch (tipo) {
    case 'xml': blob = new Blob([bytes], { type: 'application/xml' });
      break;
    case 'pdf': blob = new Blob([bytes], { type: 'application/pdf' });
      break;
    case 'xlsx': blob = new Blob([bytes], { type: 'application/xlsx' });
      break;
    default: blob = new Blob([bytes], { type: 'application/pdf' });
  }
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = reportName;
  link.download = fileName;
  link.click();
};

export const saveFile = (fileName, file) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(file);
  link.target = '_blank';
  link.download = fileName;
  link.click();
};

export const urlBase64 = (base64, tipo) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  let blob = null;
  switch (tipo) {
    case 'xml': blob = new Blob([bytes], { type: 'application/xml' });
      break;
    case 'pdf': blob = new Blob([bytes], { type: 'application/pdf' });
      break;
    case 'jpeg': blob = new Blob([bytes], { type: 'image/jpeg' });
      break;
    case 'png': blob = new Blob([bytes], { type: 'image/png' });
      break;
    default: blob = new Blob([bytes], { type: 'application/pdf' });
  }
  return window.URL.createObjectURL(blob);
};

export const mimeType = (type) => {
  if (type) {
    type = type.toLowerCase().replace('.', '');
    switch (type) {
      case 'xml': return 'application/xml';
      case 'pdf': return 'application/pdf';
      case 'jpeg': return 'image/jpeg';
      case 'jpg': return 'image/jpeg';
      case 'png': return 'image/png';
      case 'txt': return 'text/plain';
      default: return null;
    }
  }
  return null;
};


export const imageByType = (type) => {
  if (type) {
    type = type.toLowerCase();
    switch (type) {
      case '.xml': return <span className="k-icon k-font-icon k-i-html" />;
      case '.pdf': return <span className="k-icon k-font-icon k-i-pdf" />;
      case '.jpeg': return <span className="k-icon k-font-icon k-i-image" />;
      case '.jpg': return <span className="k-icon k-font-icon k-i-image" />;
      case '.png': return <span className="k-icon k-font-icon k-i-image" />;
      case '.txt': return <span className="k-icon k-font-icon k-i-txt" />;
      default: return <span className="k-icon k-font-icon k-i-file" />;
    }
  }
  return null;
};

export const heightByType = (type) => {
  if (type) {
    type = type.toLowerCase();
    switch (type) {
      case '.xml': return "100%";
      case '.pdf': return "100%";
      case '.jpeg': return null;
      case '.jpg': return null;
      case '.png': return null;
      case '.txt': return "100%";
      default: return "100%";
    }
  }
  return "100%";
};

export const stringBase64ToByteArray = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const urlByteArray = (bytes, type) => {
  let blob = null;
  switch (type) {
    case '.xml': blob = new Blob([bytes], { type: 'application/xml' });
      break;
    case '.pdf': blob = new Blob([bytes], { type: 'application/pdf' });
      break;
    case '.jpeg': blob = new Blob([bytes], { type: 'image/jpeg' });
      break;
    case '.png': blob = new Blob([bytes], { type: 'image/png' });
      break;
    case '.txt': blob = new Blob([bytes], { type: 'text/plain' });
    default: blob = new Blob([bytes], { type: 'text/plain' });
  }
  return window.URL.createObjectURL(blob);
};

export const isImage = (type) => {
  if (type) {
    type = type.toLowerCase().replace('.', '');
    switch (type) {
      case 'jpeg':
      case 'jpg':
      case 'png': return true;
      default: return false;
    }
  }
  return false;
};
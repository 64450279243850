import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { useInternationalization, load, IntlProvider } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import brNumbers from 'cldr-numbers-full/main/br/numbers.json';
import brCurrency from 'cldr-numbers-full/main/br/currencies.json';
import caGregorian from 'cldr-dates-full/main/br/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/br/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/br/timeZoneNames.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import { INTERNAL_BUTTON } from '../button';
import { CellRender, RowRender } from './cellRender';
import { SmallOrangeButton } from '../buttonsComponent';
import TrashIcon from '../../assets/icone-excluir.svg';
import './style.css';

load(brNumbers, brCurrency, currencyData, likelySubtags, caGregorian, dateFields, timeZoneNames);

const EDIT_FIELD = 'inEdit';

const GridInline = ({
  data,
  setData,
  children,
  onItemChange,
  deleteColumn,
  onItemDelete,
  canDelete,
  editColumn,
  onItemEdit,
  editImage,
  editTitle,
  noRecords,
  ...other
}) => {
  const [changes, setChanges] = useState(false);

  const remove = (dataItem) => {
    const index = data.findIndex((record) => record.Id === dataItem.Id);
    data.splice(index, 1);
    const newData = [...data];
    setData(newData);
  };

  const CommandCell = (props) => {
    const { dataItem } = props;
    return (
      <td className="k-command-cell">
        <div id="remove-icon" className="config-components-popup-button">
          {editColumn && (
            <SmallOrangeButton
              onClick={() => {
                onItemEdit(dataItem);
              }}
              title={!editTitle ? 'Atualizar' : editTitle}
            >
              <span className={!editImage ? 'k-icon k-font-icon k-i-edit' : editImage} />
            </SmallOrangeButton>
          )}
          <SmallOrangeButton
            onClick={() => {
              if (canDelete(dataItem)) {
                props.remove(dataItem);
                onItemDelete(dataItem);
              }
            }}
            title="Excluir"
          >
            <span className="k-icon k-font-icon k-i-trash" />
          </SmallOrangeButton>
        </div>
      </td>
    );
  };

  const GridCommandCell = (props) => <CommandCell {...props} remove={remove} />;
  const enterEdit = (dataItem, field) => {
    const newData = data.map((item) => ({
      ...item,
      [EDIT_FIELD]: item.Id === dataItem.Id ? field : undefined
    }));
    setData(newData);
  };

  const exitEdit = () => {
    const newData = data.map((item) => {
      if (onItemChange !== undefined) item = onItemChange(item);
      return {
        ...item,
        [EDIT_FIELD]: undefined
      };
    });
    setData(newData);
  };

  const itemChange = (event) => {
    const field = event.field || '';
    const newData = data.map((item) => {
      if (item.Id === event.dataItem.Id) {
        item[field] = event.value;
      }
      return item;
    });
    setData(newData);
    setChanges(true);
  };

  const customCellRender = (td, props) => {
    if (props.editor === 'numeric') {
      const newProps = { ...td.props, style: { textAlign: 'right' } };
      const newTd = { ...td, props: newProps };
      td = newTd;
    }
    return (
      <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />
    );
  };

  const customRowRender = (tr, props) => (
    <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />
  );

  noRecords = !noRecords ? 'Sem registros' : noRecords;
  return (
    <IntlProvider locale="pt-PT">
      <Grid
        {...other}
        data={data}
        dataItemKey="Id"
        onItemChange={itemChange}
        cellRender={customCellRender}
        rowRender={customRowRender}
        editField={EDIT_FIELD}
        resizable={true}
        className="grid-inline"
      >
        <GridNoRecords>{noRecords}</GridNoRecords>
        {children}
        {deleteColumn && editColumn && (
          <GridColumn cell={GridCommandCell} editable={false} width={70} />
        )}
        {deleteColumn && !editColumn && (
          <GridColumn cell={GridCommandCell} editable={false} width={50} />
        )}
      </Grid>
    </IntlProvider>
  );
};

GridInline.propTypes = {
  deleteColumn: PropTypes.bool,
  onItemDelete: PropTypes.func,
  canDelete: PropTypes.func
};

GridInline.defaultProps = {
  deleteColumn: false,
  onItemDelete: () => {
    // do nothing.
  },
  canDelete: () => true
};

export default GridInline;

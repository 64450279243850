import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getById = (id) => axiosWalletRequest(`Cartao/${id}`, METHOD.GET);

export const create = (id, cardTax) => axiosWalletRequest(`Cartao/${id}`, METHOD.POST, cardTax);

export const update = (id, cardTax) => axiosWalletRequest(`Cartao/${id}`, METHOD.PUT, cardTax);

export const exclude = (id) => axiosWalletRequest(`Cartao/${id}`, METHOD.DELETE);

export const getParcels = () => axiosWalletRequest('Cartao/Parcela', METHOD.GET);

export const updateParcels = (id, cardParcels) => axiosWalletRequest(`Cartao/CriarCartaoParcela/${id}`, METHOD.PUT, cardParcels);


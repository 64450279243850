import { axiosWalletRequest, METHOD } from '../api/Requests';
import { stringBase64ToByteArray, mimeType } from '../utils/download';

export const getAll = () => axiosWalletRequest('Contrato/', METHOD.GET);

export const getByPagination = (pagination) => axiosWalletRequest('Contrato/Filtrar', METHOD.POST, pagination);

export const getById = (id) => axiosWalletRequest(`Contrato/${id}`, METHOD.GET);

export const create = (body) => axiosWalletRequest('Contrato', METHOD.POST, body);

export const exclude = (id) => axiosWalletRequest(`Contrato/${id}`, METHOD.DELETE);

export const update = (id, body) => axiosWalletRequest(`Contrato/${id}`, METHOD.PUT, body);

export const getContractNumber = async () => {
    const { data } = await axiosWalletRequest(`Contrato/ObterNumeroContrato`, METHOD.GET);
    return data;
}

export const getLast = async () => {
    const { data } = await axiosWalletRequest(`Contrato/ObterUltimaParcelaContratoPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.DataVencimento);
    return null;
}

export const getFirst = async () => {
    const { data } = await axiosWalletRequest(`Contrato/ObterPrimeiraParcelaContratoPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.DataVencimento);
    return null;
}

export const getParcelsByDate = (dateInverval) => axiosWalletRequest('Contrato/FiltrarParcela', METHOD.POST, dateInverval);

export const getContractTypes = async () => await axiosWalletRequest(`Contrato/ListarTipoContrato`, METHOD.GET);

export const createContractType = async (body) => await axiosWalletRequest('Contrato/CriarTipoContrato', METHOD.POST, body);

export const updateContractType = (body, id) => axiosWalletRequest(`Contrato/AtualizarTipoContrato/${id}`, METHOD.PUT, body);

export const excludeContractType = (id) => axiosWalletRequest(`Contrato/ExcluirTipoContrato/${id}`, METHOD.DELETE);

export const generateInvoice = async (id) => await axiosWalletRequest(`Contrato/GerarFaturamento/${id}`, METHOD.GET);

export const getNumberOfContracts = async (pagination) => {
    const { data } = await axiosWalletRequest(`Contrato/ObterNumeroContratos`, METHOD.POST, pagination);
    return data;
}

export const createFile = (id, file) => {
    const fileName = file.name;
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
    const name = fileName.substr(0, fileName.lastIndexOf('.'));
    return axiosWalletRequest(`Contrato/InserirAnexo/${id}/${name}/${ext}`, METHOD.POST, file);
}

export const getFile = async (id) => {
    let { data } = await axiosWalletRequest(`Contrato/ListarAnexo/${id}`, METHOD.GET);
    let i = 1;
    data = data.map((item) => ({
        Id: i++,
        uid: i,
        name: item.Nome,
        extension: item.Extensao,
        size: item.TamanhoEmKBytes,
        getRawFile: () => {
            const byteArray = stringBase64ToByteArray(item.Dados);
            return new File([byteArray], item.Nome, { type: mimeType(item.Extensao) });
        }
    }));
    return data;
};

export const excludeFile = (id) => axiosWalletRequest(`Contrato/ExcluirAnexo/${id}`, METHOD.DELETE);
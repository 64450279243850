import { Avatar } from '@progress/kendo-react-layout';
import { useState } from 'react';
import PropTypes from 'prop-types';
import CloudIcon from '../../assets/icone-nuvem-selecionar.svg';
import GrayTrash from '../../assets/icone-lixeira-cinza.svg';
import WhiteTrash from '../../assets/icone-lixeira-branco.svg';
import './style.css';
import { OrangeButton, WhiteButton } from '../buttonsComponent';
import { getFileData } from '../../utils/file';
import { PopupConfirmResetAvatar } from '../popUps';

const Photo = ({
  removeAvatar,
  backgroundColor,
  textAvatar,
  className,
  imageSrc,
  imageAlt,
  setImageData
}) => {
  const [isRemoveHover, setIsRemoveHover] = useState(false);
  const [imageSource, setImageSource] = useState(imageSrc);
  const [popUpRemoveAvatarVisible, setPopUpRemoveAvatarVisible] = useState(false);

  const onChangeFile = async (e) => {
    const fileComplete = await getFileData(e);

    setImageSource(fileComplete.url);

    setImageData(fileComplete);
  };

  const handleRemove = () => {
    removeAvatar();
    setImageSource(null);
    setImageData(null);
  };

  return (
    <div className={`photo ${className}`}>
      <Avatar
        type="text"
        size="large"
        shape="circle"
        style={{
          backgroundColor
        }}
      >
        {imageSource && imageAlt ? <img src={imageSource} alt={imageAlt} /> : textAvatar}
      </Avatar>

      <div className="text-photo">
        <h4>Foto</h4>
        <div className="buttons">
          <OrangeButton onChange={onChangeFile} type="file">
            <img src={CloudIcon} alt="Nuvem com seta pra cima" />
            Selecionar
          </OrangeButton>

          <WhiteButton
            onClick={() => setPopUpRemoveAvatarVisible(true)}
            onMouseEnter={() => setIsRemoveHover(true)}
            onMouseLeave={() => setIsRemoveHover(false)}
            className="remove-button"
          >
            <img src={isRemoveHover ? WhiteTrash : GrayTrash} alt="Lixeira" />
            Remover
          </WhiteButton>
        </div>
      </div>

      <PopupConfirmResetAvatar
        visible={popUpRemoveAvatarVisible}
        setVisible={setPopUpRemoveAvatarVisible}
        resetAvatar={handleRemove}
      />
    </div>
  );
};

Photo.propTypes = {
  setImageData: PropTypes.func.isRequired,
  removeAvatar: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textAvatar: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string
};

Photo.defaultProps = {
  className: '',
  imageSrc: null,
  imageAlt: null
};

export default Photo;

/* eslint-disable no-undef */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useForms } from '../../../hooks';
import { AuthContext } from '../../../auth/AuthContext';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import { FormFieldDrop, FormFieldGeneric } from '../../../components/formField';
import FormFieldCheckbox from '../../../components/formField/checkbox';
import FormRow from '../../../components/formRow';
import ToggleInvoice from '../../../components/toggleInvoice';
import Photo from '../../../components/photo';
import PopUpAliquot from '../../../components/popUps/popUpAliquot';
import * as saleService from '../../../services/sale';
import * as planAccountService from '../../../services/planAccount';
import * as centerCostService from '../../../services/centerCost';
import {
  citiesFromState,
  statesFromCountry,
  getCountries,
  getCountryById,
  municipioFromCEP,
  getCitiesInvoice,
  getCityById
} from '../../../services/address';
import {
  updateWallet,
  resetAvatar,
  updateAvatar,
  resetLogo,
  updateLogo,
  recoverWalletByJwt
} from '../../../services/wallet';
import { getFirstInitial, isEmptyValue, stringToCnpj } from '../../../utils/string';
import './style.css';
import {
  errorNotification,
  successNotification,
  defaultErrorNotification
} from '../../../components/notification';
import * as especialTributeService from '../../../services/especialTribute';
import * as businessWalletService from '../../../services/businessWallet';
import LoadScreen from '../../load';
import HelpCard from '../../../components/helpCard';
import IconeSobreAPP from '../../../assets/icone-sobre-a-PP.svg';

const Company = () => {
  const { walletSelected, setWalletSelected } = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [estados, setEstados] = useState([]);
  const [cities, setCities] = useState([]);
  const [disabled, setDisabled] = useState({
    estado: true,
    city: true
  });
  const [photo, setPhoto] = useState();
  const initialForm = {
    Nome: walletSelected.NomeCorrentista ?? '',
    Pais: {},
    Logradouro: '',
    Numero: '',
    Bairro: '',
    Complemento: '',
    CodigoPostal: '',
    MunicipioNaoCadastrado: '',
    EstadoNaoCadastrado: '',
    CpfCnpj: '',
    InscricaoEstadual: null ?? '',
    InscricaoMunicipal: null ?? '',
    Municipio: '',
    EspecialTribute: '',
    CityInvoice: '',
    TokenIBPT: '',
    OutrasInformacoes: '',
    LoteNotaFiscal: '',
    NumeroNotaFiscal: '',
    PassNotaFiscal: ''
  };
  const [form, updateFormValue, setForm, updateFormProperty] = useForms(initialForm);
  const [simplesNacional, setSimplesNacional] = useState(false);
  const [mei, setMei] = useState(false);
  const [filterStartsWith, setFilterStartsWith] = useState(false);
  const [popUpAliquotVisible, setPopUpAliquotVisible] = useState(false);

  const getMunicipioData = async (idPais, CEP) => {
    if (idPais === 'zb' && CEP.length === 8) {
      setCities(['cep']);
      return (await municipioFromCEP(CEP)).data.Municipio;
    }

    return {};
  };

  const loadEstados = async (idPais) => {
    setLoading(true);

    const { data } = await statesFromCountry(idPais);
    setEstados(data);

    if (data.length === 0) {
      setDisabled({ estado: false, city: false });
      setCities([]);
    } else setDisabled({ estado: false, city: true });

    setLoading(false);
  };

  const loadCities = async (idEstado) => {
    setLoading(true);

    const { data } = await citiesFromState(idEstado);
    setCities(data);

    setDisabled({ estado: false, city: false });

    setLoading(false);
  };

  const loadCountries = async () => {
    const { data: countriesFromApi } = await getCountries();
    setCountries(countriesFromApi);
  };

  useEffect(async () => {
    setLoading(true);

    const { Endereco: endereco } = walletSelected;

    const idPais = endereco?.IdPais;

    const Municipio = await getMunicipioData(idPais, endereco?.CodigoPostal);

    let { Pais } = initialForm;

    await loadCountries();

    if (idPais !== undefined) Pais = (await getCountryById(idPais)).data;

    setForm({
      Nome: walletSelected.NomeCorrentista ?? '',
      Pais,
      CodigoPostal: endereco?.CodigoPostal ?? '',
      Numero: endereco?.Numero,
      Municipio,
      Logradouro: endereco?.Logradouro,
      Bairro: endereco?.Bairro,
      Complemento: endereco?.Complemento,
      MunicipioNaoCadastrado: endereco?.MunicipioNaoCadastrado,
      EstadoNaoCadastrado: endereco?.EstadoNaoCadastrado,
      CpfCnpj: walletSelected.CpfCnpj || '',
      InscricaoEstadual: walletSelected.InscricaoEstadual || '',
      InscricaoMunicipal: walletSelected.InscricaoMunicipal || ''
    });
    if (!isEmptyValue(idPais)) loadEstados(idPais);

    if (walletSelected.CarteiraBusiness) {
      setSimplesNacional(walletSelected.CarteiraBusiness.SimplesNacional);
      setMei(walletSelected.CarteiraBusiness.MEI);
      setFilterStartsWith(walletSelected.CarteiraBusiness.FiltroComecaCom);
    } else {
      errorNotification(
        'N�o existe um carteira Next Business associada a carteira do Next Finance.'
      );
    }

    setLoading(false);
  }, []);

  const history = useHistory();
  const redirectToHomeList = () => history.push('/home');

  const searchCEP = async (id) => {
    setLoading(true);

    try {
      const { data } = await municipioFromCEP(id);

      if (data.Logradouro === null) throw Error('CEP n�o encontrado');

      setDisabled({ estado: true, city: true });
      setCities(['cep']);

      setForm({
        ...form,
        Bairro: data.Bairro,
        Logradouro: data.Logradouro,
        Municipio: data.Municipio,
        CodigoPostal: data.CodigoPostal
      });
    } catch (error) {
      errorNotification(error.message);
    }

    setLoading(false);
  };

  const removeAvatarWallet = async () => {
    setLoading(true);

    await resetAvatar();
    setWalletSelected({
      ...walletSelected,
      UrlAvatar: null
    });

    setLoading(false);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    const userBodyForm = {
      Nome: walletSelected.NomeCarteira,
      CpfCnpj: form.CpfCnpj,
      Perfil: {
        ...form,
        IdPais: form.Pais.Id,
        IdMunicipio: form.Municipio.idMunicipio
      }
    };

    const updateAvatarPromise = photo
      ? await updateAvatar(photo.binary, photo.type)
      : new Promise((resolve) => resolve(null));

    const updateWalletPromise = await updateWallet(userBodyForm);

    const businessForm = {
      Id: walletSelected.CarteiraBusiness?.Id,
      SimplesNacional: simplesNacional,
      MEI: mei,
      FiltroComecaCom: filterStartsWith
    };
    const updateBusinessWalletPromise = await businessWalletService.updateSettings(
      businessForm.Id,
      businessForm
    );

    const [
      updateAvatarResponse,
      updateWalletResponse,
      updateBusinessWalletResponse
    ] = await Promise.all([updateAvatarPromise, updateWalletPromise, updateBusinessWalletPromise]);

    if (photo) {
      const { data: UrlAvatar } = updateAvatarResponse;
      setWalletSelected({
        ...walletSelected,
        UrlAvatar
      });
    }

    if (updateWalletResponse.status === 200 && updateBusinessWalletResponse.status === 200) {
      const walletData = await recoverWalletByJwt();
      setWalletSelected(walletData);
      successNotification('Carteira atualizada com sucesso!');
    } else {
      defaultErrorNotification();
    }
    setLoading(false);
  };

  if (loading) return <LoadScreen />;
  return (
    <div id="settings-page">
      <h1>Configura��es</h1>
      <form onSubmit={submitForm}>
        <ToggleInvoice title="Empresa" startExpanded>
          <FormRow>
            <Photo
              backgroundColor={walletSelected.CorPerfil}
              removeAvatar={removeAvatarWallet}
              textAvatar={getFirstInitial(walletSelected.NomeCorrentista)}
              imageSrc={walletSelected.UrlAvatar}
              imageAlt="Foto de perfil da carteira"
              className="fill-50-field"
              setImageData={setPhoto}
            />
          </FormRow>

          <FormRow>
            <FormFieldGeneric
              titleLabel="Nome da Empresa"
              classNameWrapper="fill-100-field"
              name="Nome"
              onChangeValue={updateFormValue}
              valueInput={form.Nome}
              required
            />
          </FormRow>

          <FormRow>
            <FormRow halfSize withoutMarginTop>
              <FormFieldDrop
                titleLabel="Pa�s"
                defaultValueSelect="Selecione..."
                infosSelect={countries}
                value={form.Pais}
                name="Pais"
                textField="Descricao"
                dataItemKey="Id"
                className="fill-50-field"
                onChangeValue={(event) => {
                  updateFormValue(event);
                  if (event.value) {
                    loadEstados(event.value.Id);
                  }
                }}
                required
              />
              <FormFieldGeneric
                titleLabel="CEP"
                onChangeValue={(ev) => {
                  if (
                    ev.value.length === 8 &&
                    form.Pais !== null &&
                    form.Pais.Descricao === 'Brasil'
                  )
                    searchCEP(ev.value);
                  else updateFormValue(ev);
                }}
                valueInput={form.CodigoPostal}
                classNameWrapper="fill-50-field"
                name="CodigoPostal"
                required
              />
            </FormRow>

            <FormRow halfSize withoutMarginTop withShrink>
              {estados.length === 0 ? (
                <FormFieldGeneric
                  titleLabel="Estado"
                  onChangeValue={updateFormValue}
                  valueInput={form.EstadoNaoCadastrado}
                  classNameWrapper="fill-50-field"
                  name="EstadoNaoCadastrado"
                />
              ) : (
                <FormFieldDrop
                  titleLabel="Estado"
                  defaultValueSelect="Selecione..."
                  onChangeValue={({ value }) => {
                    loadCities(value.idEstado);
                    updateFormProperty('Municipio', {
                      ...form.Municipio,
                      idEstado: value.idEstado,
                      nomeEstado: value.nomeEstado
                    });
                  }}
                  infosSelect={estados}
                  textField="nomeEstado"
                  dataItemKey="idEstado"
                  disabled={disabled.estado || form.CodigoPostal.length === 8}
                  value={form.Municipio}
                  className="fill-50-field"
                  required
                />
              )}

              {cities.length === 0 ? (
                <FormFieldGeneric
                  titleLabel="Cidade"
                  name="MunicipioNaoCadastrado"
                  onChangeValue={updateFormValue}
                  disabled={disabled.city}
                  classNameWrapper="fill-50-field"
                />
              ) : (
                <FormFieldDrop
                  titleLabel="Cidade"
                  defaultValueSelect="Selecione..."
                  name="Municipio"
                  onChangeValue={updateFormValue}
                  infosSelect={cities}
                  value={form.Municipio}
                  textField="nomeMunicipio"
                  dataItemKey="idMunicipio"
                  disabled={disabled.city || form.CodigoPostal.length === 8}
                  className="fill-50-field"
                  required
                />
              )}
            </FormRow>
          </FormRow>

          <FormRow>
            <FormRow halfSize withoutMarginTop>
              <FormFieldGeneric
                titleLabel="Rua/Avenida"
                name="Logradouro"
                onChangeValue={updateFormValue}
                valueInput={form.Logradouro}
                classNameWrapper="fill-70-field"
                required
              />

              <FormFieldGeneric
                titleLabel="N�mero"
                name="Numero"
                onChangeValue={updateFormValue}
                valueInput={form.Numero}
                classNameWrapper="fill-100-field"
                required
              />
            </FormRow>

            <FormRow halfSize withoutMarginTop>
              <FormFieldGeneric
                titleLabel="Complemento"
                name="Complemento"
                onChangeValue={updateFormValue}
                valueInput={form.Complemento}
                classNameWrapper="fill-25-field"
              />

              <FormFieldGeneric
                titleLabel="Bairro"
                name="Bairro"
                onChangeValue={updateFormValue}
                valueInput={form.Bairro}
                classNameWrapper="fill-100-field"
              />
            </FormRow>
          </FormRow>

          <FormRow>
            <FormRow halfSize withoutMarginTop withShrink>
              <FormFieldGeneric
                titleLabel="CNPJ"
                name="CpfCnpj"
                onChangeValue={updateFormValue}
                valueInput={stringToCnpj(form.CpfCnpj)}
                classNameWrapper="fill-50-field"
                mask="00.000.000/0000-00"
                required
              />
              <FormFieldGeneric
                titleLabel="Inscri��o Estadual"
                name="InscricaoEstadual"
                onChangeValue={updateFormValue}
                valueInput={form.InscricaoEstadual}
                classNameWrapper="fill-50-field"
                mask="000000000.00-00"
                typeNumber
              />
            </FormRow>

            <FormRow halfSize withoutMarginTop>
              <FormFieldGeneric
                titleLabel="Inscri��o Municipal"
                name="InscricaoMunicipal"
                onChangeValue={updateFormValue}
                valueInput={form.InscricaoMunicipal}
                classNameWrapper="fill-50-field"
              />
            </FormRow>
          </FormRow>
        </ToggleInvoice>
        <ToggleInvoice title="Fiscal" startExpanded>
          <FormRow>
            <FormFieldCheckbox
              label="Optante pelo Simples Nacional"
              classNameWrapper="fill-50-field"
              value={simplesNacional}
              onChange={(val) => {
                setSimplesNacional(val.value);
                if (val.value) setMei(false);
              }}
            />
            <FormFieldCheckbox
              label="Microempreendedor Individual (MEI)"
              classNameWrapper="fill-50-field"
              value={mei}
              onChange={(val) => {
                setMei(val.value);
                if (val.value) setSimplesNacional(false);
              }}
            />
          </FormRow>
          <FormRow withShrink>
            <OrangeButton disable={!simplesNacional} onClick={() => setPopUpAliquotVisible(true)}>
              Al�quota de Reten��o de ISS
            </OrangeButton>
          </FormRow>
        </ToggleInvoice>
        {/* <ToggleInvoice title="Software">
          <FormRow>
            <FormFieldCheckbox
              label="Filtrar Cliente por In�cio do Nome"
              classNameWrapper="fill-50-field"
              value={filterStartsWith}
              onChange={(val) => {
                setFilterStartsWith(val.value);
              }}
            />
          </FormRow>
        </ToggleInvoice> */}
        <FormRow justifyEnd space15 marginTop40>
          <OrangeButton type="submit">Salvar</OrangeButton>
          <WhiteButton onClick={() => history.goBack()}>Cancelar</WhiteButton>
        </FormRow>
      </form>
      <PopUpAliquot visible={popUpAliquotVisible} setVisible={setPopUpAliquotVisible} />
    </div>
  );
};

export default Company;

import React, { useContext, useEffect, useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { useHistory, useParams } from 'react-router-dom';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import FormFieldDate from '../../../components/formField/date';
import FormFieldDrop from '../../../components/formField/drop';
import FormFieldCheckbox from '../../../components/formField/checkbox';
import GenericField, { INTERNAL_ICON } from '../../../components/formField/genericField';
import FormRow from '../../../components/formRow';
import ToggleInvoice from '../../../components/toggleInvoice';
import { useForms } from '../../../hooks';
import * as personService from '../../../services/person';
import * as serviceService from '../../../services/service';
import * as attendanceService from '../../../services/attendance';
import * as proposalService from '../../../services/proposal';
import LoadScreen from '../../load';
import {
  successNotification,
  errorNotification,
  warningNotification
} from '../../../components/notification';
import PopupEditService from '../../../components/popUps/popUpEditService';
import PopUpError from '../../../components/popUps/popUpError';
import PopupAttendanceType from '../../../components/popUps/popUpAttendanceType';
import PopUpDocument from '../../../components/popUps/popUpDocument';
import GridInline from '../../../components/grid';
import { TotalValueCell } from '../../../components/grid/totalCell';
import { AuthContext } from '../../../auth/AuthContext';
import { DateNow } from '../../../utils/date';
import './styles.css';
import Client from '../../../components/client';
import NumberCell from '../../../components/grid/numberCell';

const initialForm = {
  Data: DateNow(),
  Numero: '',
  Situacao: 'Em aberto',
  Contato: null,
  Introducao: null,
  Validade: null,
  Garantia: null,
  Entrega: null,
  Condicao: null,
  Observacao: null,
  InformacaoAdicional: null,
  Email: null,
  EmailCc: null,
  EmailCco: null,
  IncluirTotal: false,
  IncluirEndereco: false,
  IncluirIntroducao: true,
  IncluirValidade: true,
  IncluirGarantia: true,
  IncluirEntrega: true,
  IncluirCondicao: true,
  IncluirObservacao: true,
  IncluirInformacao: true
};

const initialAttendanceType = {
  Nome: '',
  Editavel: false
};

const AttendanceRegistration = () => {
  let { id } = useParams();
  const [attendanceType, setAttendanceType] = useState(initialAttendanceType);
  const [attendanceTypes, setAttendanceTypes] = useState([]);
  const history = useHistory();
  const [form, updateProperty, setForm, updateFormValue] = useForms(initialForm);
  const [cpfCnpj, setCpfCpnj] = useState('');
  const [address, setAddress] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [client, setClient] = useState(null);
  const [attendanceServices, setAttendanceServices] = useState([]);
  const [proposal, setProposal] = useState(null);
  const [proposals, setProposals] = useState([]);
  const [totalService, setTotalService] = useState(0);
  const { walletSelected } = useContext(AuthContext);
  const [services, setServices] = useState([]);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const statusTypes = ['Em aberto', 'Atendido', 'Atendido Parcialmente'];
  const [contact, setContact] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDiv, setLoadingDiv] = useState(false);
  const [loadingType, setLoadingType] = useState(false);
  const [loadingProposalTypes, setLoadingProposalTypes] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingProposals, setLoadingProposals] = useState(false);
  const [popUpConfirmSale, setPopUpConfirmSale] = useState(false);
  const [popUpsVisibilities, setPopUpsVisibilities] = useState({
    addService: false,
    addAttendanceType: false,
    editAttendanceType: false,
    addAdditionalService: false
  });

  const setPopUpVisibility = (propName, value) => {
    setPopUpsVisibilities({
      ...popUpsVisibilities,
      [propName]: value
    });
  };

  const calculateTotalService = () => {
    let total = attendanceServices
      .filter(({ Confirmado }) => Confirmado)
      .reduce((acc, current) => acc + current.ValorTotal, 0);
    total = Math.round(total * 100) / 100;
    setTotalService(total);
    return total;
  };

  const onItemChangeAttendanceService = (item) => {
    item.ValorTotal = item.Quantidade * item.Valor - item.Desconto;
    calculateTotalService();
    return item;
  };

  const onItemDeleteAttendanceService = () => {
    calculateTotalService();
  };

  const onItemEditAttendanceService = (service) => {
    setPopUpVisibility('addService', true);
    const editService = attendanceServices.find((item) => item.Id === service.Id);
    setSelectedService(editService);
  };

  const addService = (service) => {
    const lastId =
      attendanceServices.length > 0 ? attendanceServices[attendanceServices.length - 1].Id : 0;
    const item = {
      Id: lastId + 1,
      ServicoId: service.Id,
      Quantidade: service.Quantidade,
      Valor: service.ValorServico,
      ServicoCodigo: service.Codigo,
      ServicoDescricao: service.Descricao,
      Desconto: service.Desconto,
      ValorTotal: service.ValorTotal,
      CodigoTributacao: service.CodigoTributacao,
      ListaServicos: service.ListaServicos,
      PlanoDeContas_Id: service.PlanoDeContas_Id,
      CentroDeCustos_Id: service.CentroDeCustos_Id,
      ServicoUnidadeMedida: service.UnidadeMedida_Nome,
      Confirmado: true,
      Descricao: service.DescricaoAdicional
    };
    const newItems = attendanceServices;
    if (selectedService) {
      const earlyIndex = attendanceServices.findIndex((item) => item.Id === selectedService.Id);
      newItems[earlyIndex] = item;
    } else {
      newItems.push(item);
    }
    calculateTotalService();
    setAttendanceServices(newItems);
    setSelectedService(null);
  };

  const updateClientDetails = async (client, address, contact) => {
    if (contact) setForm({ ...form, Email: contact.Email });
  };

  const setClientAddress = async (client, idAddress, contact) => {
    if (client.TipoPessoa === 0) {
      setCpfCpnj(client.CPF);
      setAddress(client.Endereco);
      setContact({ Nome: contact });
      setContacts(null);
    } else {
      let addresses = [];
      if (client.EnderecoPrincipal != null) addresses.push(client.EnderecoPrincipal);
      addresses = addresses.concat(client.OutrosEnderecos);
      addresses = addresses.map((item) => ({
        ...item,
        EnderecoCompleto: [item.Logradouro, item.Numero, item.Complemento, item.Bairro]
          .filter(Boolean)
          .join(', ')
      }));
      let newAddress = addresses.filter((x) => x.Id === idAddress);
      newAddress = newAddress.length > 0 ? newAddress[0] : null;
      if (newAddress != null) {
        setCpfCpnj(newAddress.CNPJ ? newAddress.CNPJ : '');
        setAddress(newAddress);
      } else if (addresses.length === 1) {
        setCpfCpnj(addresses[0].CNPJ ? addresses[0].CNPJ : '');
        setAddress(addresses[0]);
      }
      setAddresses(addresses);
      const contacts = [];
      if (client.Contatos?.length > 0) {
        client.Contatos.forEach((item) => {
          contacts.push(item);
        });
      }
      if (addresses?.length > 0) {
        addresses.forEach((item1) => {
          if (item1.Contatos?.length > 0) {
            item1.Contatos.forEach((item2) => {
              contacts.push(item2);
            });
          }
        });
      }
      contacts.sort();
      if (!contacts) {
        setContact({ Nome: contact });
        setContacts(null);
      } else if (contacts.length > 0) {
        const contato = contacts.find((x) => x.Id === contact);
        setContact(contato);
        contacts.sort((a, b) => {
          if (a.Nome < b.Nome) {
            return -1;
          }
          if (a.Nome > b.Nome) {
            return 1;
          }
          return 0;
        });
        setContacts(contacts);
      }
    }
  };

  const loadAttendanceTypes = async (attendanceType) => {
    setLoadingType(true);
    const { data } = await attendanceService.getAttendanceTypes();
    setAttendanceTypes(data);
    setAttendanceType(attendanceType);
    setLoadingType(false);
    return data;
  };

  const loadProposals = async () => {
    const { data } = await proposalService.getAll();
    setProposals(data);
    return data;
  };

  const onSelectionChange = (event) => {
    const newData = attendanceServices.map((item) => {
      if (item.Id === event.dataItem.Id) {
        item.Confirmado = !item.Confirmado;
      }
      return item;
    });
    setAttendanceServices(newData);
    calculateTotalService();
  };

  const onHeaderSelectionChange = (event) => {
    const { checked } = event.syntheticEvent.target;
    const newData = attendanceServices.map((item) => {
      item.Confirmado = checked;
      return item;
    });
    setAttendanceServices(newData);
  };

  useEffect(async () => {
    setLoadingProposalTypes(true);
    setLoadingClient(true);
    setLoadingServices(true);
    setLoadingProposals(true);
    if (id && id !== '0') {
      let response = await attendanceService.getById(id);
      const attendance = response.data;
      // ############## Load Proposal Types ##############
      setForm({
        Data: new Date(attendance.Data),
        Numero: attendance.Numero,
        Situacao: attendance.Situacao,
        DocumentoGerado: attendance.DocumentoGerado,
        Introducao: attendance.Introducao,
        Validade: attendance.Validade,
        Garantia: attendance.Garantia,
        Entrega: attendance.Entrega,
        Condicao: attendance.Condicao,
        Observacao: attendance.Observacao,
        InformacaoAdicional: attendance.InformacaoAdicional,
        Email: attendance.Email,
        EmailCc: attendance.EmailCc,
        EmailCco: attendance.EmailCco,
        IncluirTotal: attendance.IncluirTotal,
        IncluirEndereco: attendance.IncluirEndereco,
        IncluirIntroducao: attendance.IncluirIntroducao,
        IncluirValidade: attendance.IncluirValidade,
        IncluirGarantia: attendance.IncluirGarantia,
        IncluirEntrega: attendance.IncluirEntrega,
        IncluirCondicao: attendance.IncluirCondicao,
        IncluirObservacao: attendance.IncluirObservacao,
        IncluirInformacao: attendance.IncluirInformacao
      });
      const attendanceTypes = await loadAttendanceTypes();
      let itemAttendanceType = attendanceTypes.find(
        (element) => element.Id === attendance.TipoAtendimentoId
      );
      itemAttendanceType = {
        ...itemAttendanceType,
        Editavel: true
      };
      setAttendanceType(itemAttendanceType);
      setLoadingProposalTypes(false);

      // ############## Load Client ##############
      response = await personService.getById(attendance.PessoaId);
      const client = response.data;
      setClient(client);
      await setClientAddress(client, attendance.EnderecoId, attendance.Contato);
      setLoadingClient(false);

      // ############## Load Services ##############
      let apiAttendanceService = attendance.AtendimentoServico.filter((obj) => !obj.Adicional);
      let idService = 1;
      apiAttendanceService = apiAttendanceService.map((obj) => ({
        ...obj,
        Id: idService++,
        PlanoDeContas_Id: obj.Servico.PlanoDeContas_Id,
        CentroDeCustos_Id: obj.Servico.CentroDeCustos_Id,
        ServicoUnidadeMedida: obj.Servico.UnidadeMedida_Nome
      }));
      setAttendanceServices(apiAttendanceService);
      const total = attendance.AtendimentoServico.filter(({ Confirmado }) => Confirmado).reduce(
        (acc, current) => acc + current.ValorTotal,
        0
      );
      setTotalService(total);
      setLoadingServices(false);

      // ############## Load Proposals ##############
      const proposals = await loadProposals();
      const itemProposal = proposals.find((element) => element.Id === attendance.PropostaId);
      setProposal(itemProposal);
      setLoadingProposals(false);
    } else {
      // ############## Load Proposal Types ##############
      const attendanceTypes = await loadAttendanceTypes();
      const attendanceNumber = await attendanceService.getAttendanceNumber();
      setForm({
        ...form,
        Numero: attendanceNumber,
        DiscriminacaoServico: walletSelected.CarteiraBusiness.OutrasInformacoes
      });
      setLoadingProposalTypes(false);

      // ############## Load Client ##############
      setContact(null);
      setLoadingClient(false);

      // ############## Load Services ##############
      setLoadingServices(false);

      // ############## Load Proposals ##############
      const proposals = await loadProposals();
      setLoadingProposals(false);
    }
  }, []);

  const redirectToAttendances = (id) => {
    if (id) history.push(`/attendances/${id}`);
    else history.push(`/attendances`);
  };

  const validate = async () => {
    if (
      loading ||
      loadingDiv ||
      loadingType ||
      loadingClient ||
      loadingServices ||
      loadingProposalTypes ||
      loadingProposals
    ) {
      warningNotification('Aguarde o carregamento dos dados.');
      return false;
    }
    if (attendanceServices.length === 0) {
      warningNotification('Pelo menos um serv��o deve ser adicionado.');
      return false;
    }
    return true;
  };

  const sendAttendance = async (id) => {
    const attendancesSelectedsIds = [id];
    const sendAttendancesRequests = attendancesSelectedsIds.map((attendanceSelected) =>
      attendanceService.send(attendanceSelected)
    );

    const sendAttendancesResponses = await Promise.all(sendAttendancesRequests);

    const isAllRequestsRight = sendAttendancesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    if (isAllRequestsRight) {
      sendAttendancesResponses.forEach((element) => {
        const { data } = element;
        successNotification(`Proposta enviada com sucesso para: ${data}!`);
      });
    } else {
      let erro = 'Houve um erro ao tentar enviar a proposta por email:';
      sendAttendancesResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro += data.MensagemUsuario;
          else erro += data;
        }
      });
      errorNotification(erro);
    }
    redirectToAttendances(id);
  };

  const generateSale = async (id, idDocument) => {
    const attendancesSelectedsIds = [id];
    const generateSalesRequests = attendancesSelectedsIds.map((attendanceSelected) =>
      attendanceService.generateSale(attendanceSelected, idDocument)
    );

    const generateSalesResponses = await Promise.all(generateSalesRequests);

    const isAllRequestsRight = generateSalesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    if (isAllRequestsRight) {
      successNotification('Documento gerado com sucesso!');
      generateSalesResponses.forEach((element) => {
        const { data } = element;
        history.push(`/invoices/invoiceRegistration/${data}`);
      });
    } else {
      let erro = 'Houve um erro ao tentar gerar o documento:';
      generateSalesResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro += data.MensagemUsuario;
          else erro += data;
        }
      });
      errorNotification(erro);
    }
  };

  const submitFormValidated = async (button, idDocument) => {
    setLoading(true);
    let sum = attendanceServices
      .filter(({ Confirmado }) => Confirmado)
      .reduce((acc, current) => acc + current.ValorTotal, 0);

    sum = Math.round(sum * 100) / 100;
    const bodyForm = {
      ...form,
      Valor: sum,
      PessoaId: client.Id,
      TipoAtendimentoId: attendanceType?.Id,
      EnderecoId: address?.Id,
      AtendimentoServico: attendanceServices,
      PropostaId: proposal?.Id,
      Introducao: form.Introducao,
      Validade: form.Validade,
      Garantia: form.Garantia,
      Entrega: form.Entrega,
      Condicao: form.Condicao,
      Observacao: form.Observacao,
      InformacaoAdicional: form.InformacaoAdicional,
      Email: form.Email,
      EmailCc: form.EmailCc,
      EmailCco: form.EmailCco,
      IncluirTotal: form.IncluirTotal,
      IncluirEndereco: form.IncluirEndereco,
      IncluirIntroducao: form.IncluirIntroducao,
      IncluirValidade: form.IncluirValidade,
      IncluirGarantia: form.IncluirGarantia,
      IncluirEntrega: form.IncluirEntrega,
      IncluirCondicao: form.IncluirCondicao,
      IncluirObservacao: form.IncluirObservacao,
      IncluirInformacao: form.IncluirInformacao,
      Contato: contact?.Id ? contact?.Id : contact?.Nome
    };
    let response = null;
    if (id && id !== '0') {
      response = await attendanceService.update(id, bodyForm);
    } else {
      response = await attendanceService.create(bodyForm);
      const { data } = response;
      id = data;
    }
    if (response.status === 200) {
      window.localStorage.setItem('FILTER_DATE', form.Data);
      successNotification('Proposta criada/atualizada com sucesso!');
      if (button === 'salvarGerar') {
        await generateSale(id, idDocument);
      } else if (button === 'salvarEnviar') {
        await sendAttendance(id);
      } else redirectToAttendances(id);
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
      else errorNotification(data);
    }
    setLoading(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (await validate()) {
      const button = e.nativeEvent?.submitter?.name;
      if (button === 'salvarGerar') setPopUpConfirmSale(true);
      else submitFormValidated(button, null);
    }
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      {id && id !== '0' ? <h1>Atualizar Proposta</h1> : <h1>Nova Proposta</h1>}
      <form className="form-general-attendance" onSubmit={submitForm}>
        {loadingType ? (
          <LoadScreen />
        ) : (
          <>
            <ToggleInvoice title="Tipo de Proposta" startExpanded>
              {loadingProposalTypes ? (
                <LoadScreen />
              ) : (
                <>
                  <FormRow withShrink>
                    <FormFieldDrop
                      titleLabel="Tipo de Proposta"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        const attendanceType = val.value;
                        if (attendanceType == null) setAttendanceType(initialForm);
                        else {
                          attendanceType.Editavel = true;
                          setAttendanceType(attendanceType);
                        }
                      }}
                      value={attendanceType}
                      className="fill-25-field"
                      infosSelect={attendanceTypes}
                      dataItemKey="Id"
                      textField="Nome"
                      enablePencil={attendanceType?.Editavel}
                      enableIcon
                      onClickIcon={() => setPopUpVisibility('addAttendanceType', true)}
                      onClickPencil={() => setPopUpVisibility('editAttendanceType', true)}
                      required
                    />
                    <FormFieldDate
                      titleLabel="Data de Cria��o"
                      value={form.Data}
                      valueOnChange={(val) => updateFormValue('Data', val)}
                      className="fill-25-field"
                      required
                      disabled
                    />
                    <FormFieldDrop
                      titleLabel="Situa��o"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        updateProperty(val);
                        const {
                          target: { name, value }
                        } = val;
                      }}
                      value={form.Situacao}
                      name="Situacao"
                      className="fill-25-field"
                      infosSelect={statusTypes}
                    />
                    <GenericField
                      titleLabel="N�mero"
                      enableInfo
                      onChangeValue={updateProperty}
                      msg="N�mero sequencial das propostas"
                      valueInput={form.Numero}
                      classNameWrapper="fill-25-field"
                      required
                      name="Numero"
                      typeNumber
                      disabled
                    />
                  </FormRow>
                </>
              )}
            </ToggleInvoice>
          </>
        )}
        <ToggleInvoice title="Cliente" startExpanded>
          {loadingClient ? (
            <LoadScreen />
          ) : (
            <>
              <Client
                client={client}
                setClient={setClient}
                cpfCnpj={cpfCnpj}
                setCpfCpnj={setCpfCpnj}
                address={address}
                setAddress={setAddress}
                addresses={addresses}
                setAddresses={setAddresses}
                contact={contact}
                setContact={setContact}
                contacts={contacts}
                setContacts={setContacts}
                afterClientSelection={updateClientDetails}
              />
              {address?.RetemISS && (
                <FormRow>
                  <h3 style={{ color: 'red', fontWeight: 'bold' }}>Cliente ret�m ISS</h3>
                </FormRow>
              )}
            </>
          )}
        </ToggleInvoice>
        <ToggleInvoice title="Servi�os" startExpanded>
          {loadingServices ? (
            <LoadScreen />
          ) : (
            <>
              <FormRow>
                <GridInline
                  data={attendanceServices}
                  setData={setAttendanceServices}
                  onItemChange={onItemChangeAttendanceService}
                  deleteColumn={true}
                  onItemDelete={onItemDeleteAttendanceService}
                  selectedField="Confirmado"
                  onSelectionChange={onSelectionChange}
                  onHeaderSelectionChange={onHeaderSelectionChange}
                  editColumn={true}
                  onItemEdit={onItemEditAttendanceService}
                >
                  <GridColumn
                    field="Confirmado"
                    editable={false}
                    headerSelectionValue={
                      attendanceServices.findIndex((item) => !item.Confirmado) === -1
                    }
                  />
                  <GridColumn field="ServicoCodigo" title="C�digo" editable={false} />
                  <GridColumn field="ServicoDescricao" title="Descri��o" editable={false} />
                  <GridColumn field="Descricao" title="Descri��o Adicional" editable={false} />
                  <GridColumn
                    field="Quantidade"
                    title="Quantidade"
                    editable={true}
                    editor="numeric"
                  />
                  <GridColumn
                    field="ServicoUnidadeMedida"
                    title="Unidade de Medida"
                    editable={false}
                    cell={(props) => (
                      <td style={{ textAlign: 'right' }}>{props.dataItem.ServicoUnidadeMedida}</td>
                    )}
                  />
                  <GridColumn
                    field="Valor"
                    title="Valor"
                    editable={true}
                    editor="numeric"
                    cell={NumberCell}
                  />
                  <GridColumn
                    field="Desconto"
                    title="Desconto (valor)"
                    editable={true}
                    editor="numeric"
                    cell={NumberCell}
                  />
                  <GridColumn
                    field="ValorTotal"
                    title="Total"
                    editable={false}
                    editor="numeric"
                    format="{0:n2}"
                    footerCell={(props) => TotalValueCell(props, totalService)}
                  />
                </GridInline>
              </FormRow>
              <FormRow justifyEnd space10>
                <OrangeButton
                  onClick={() => {
                    setSelectedService(null);
                    setPopUpVisibility('addService', true);
                  }}
                >
                  Adicionar Servi�o
                </OrangeButton>
              </FormRow>
            </>
          )}
        </ToggleInvoice>
        <ToggleInvoice title="Condi��es" startExpanded>
          {loadingProposals ? (
            <LoadScreen />
          ) : (
            <>
              <FormRow>
                <FormFieldDrop
                  titleLabel="Utilizar Modelo de Proposta"
                  defaultValueSelect="Selecione..."
                  onChangeValue={(val) => {
                    const proposal = val.value;
                    setProposal(proposal);
                    if (proposal) {
                      setForm({
                        ...form,
                        Introducao: proposal.Introducao,
                        Validade: proposal.Validade,
                        Garantia: proposal.Garantia,
                        Entrega: proposal.Entrega,
                        Condicao: proposal.Condicao,
                        Observacao: proposal.Observacao,
                        InformacaoAdicional: proposal.InformacaoAdicional
                      });
                    }
                  }}
                  value={proposal}
                  className="fill-33-field-margin"
                  infosSelect={proposals}
                  dataItemKey="Id"
                  textField="Nome"
                />
              </FormRow>
              <FormRow>
                <GenericField
                  titleLabel="Introdu��o"
                  classNameWrapper="fill-100-field"
                  isTextArea
                  rows={6}
                  valueInput={form.Introducao}
                  onChangeValue={(val) => {
                    updateFormValue('Introducao', val.value);
                  }}
                />
              </FormRow>
              <FormRow>
                <GenericField
                  titleLabel="Validade"
                  classNameWrapper="fill-100-field"
                  isTextArea
                  rows={6}
                  valueInput={form.Validade}
                  onChangeValue={(val) => {
                    updateFormValue('Validade', val.value);
                  }}
                />
              </FormRow>
              <FormRow>
                <GenericField
                  titleLabel="Garantia"
                  classNameWrapper="fill-100-field"
                  isTextArea
                  rows={6}
                  valueInput={form.Garantia}
                  onChangeValue={(val) => {
                    updateFormValue('Garantia', val.value);
                  }}
                />
              </FormRow>
              <FormRow>
                <GenericField
                  titleLabel="Prazos de Entrega"
                  classNameWrapper="fill-100-field"
                  isTextArea
                  rows={6}
                  valueInput={form.Entrega}
                  onChangeValue={(val) => {
                    updateFormValue('Entrega', val.value);
                  }}
                />
              </FormRow>
              <FormRow>
                <GenericField
                  titleLabel="Condi��es de Pagamento"
                  classNameWrapper="fill-100-field"
                  isTextArea
                  rows={6}
                  valueInput={form.Condicao}
                  onChangeValue={(val) => {
                    updateFormValue('Condicao', val.value);
                  }}
                />
              </FormRow>
              <FormRow>
                <GenericField
                  titleLabel="Observa��es"
                  classNameWrapper="fill-100-field"
                  isTextArea
                  rows={6}
                  valueInput={form.Observacao}
                  onChangeValue={(val) => {
                    updateFormValue('Observacao', val.value);
                  }}
                />
              </FormRow>
              <FormRow>
                <GenericField
                  titleLabel="Informa��es Adicionais e Finaliza��o"
                  classNameWrapper="fill-100-field"
                  isTextArea
                  rows={6}
                  valueInput={form.InformacaoAdicional}
                  onChangeValue={(val) => {
                    updateFormValue('InformacaoAdicional', val.value);
                  }}
                />
              </FormRow>
            </>
          )}
        </ToggleInvoice>
        <ToggleInvoice title="Envio de Proposta" startExpanded>
          <FormRow>
            <GenericField
              titleLabel="Destinat�rio"
              classNameWrapper="fill-100-field"
              valueInput={form.Email}
              onChangeValue={updateProperty}
              name="Email"
              required
            />
          </FormRow>
          <FormRow>
            <GenericField
              titleLabel="Cc (Para m�ltiplos emails utilizar o separador ;)"
              classNameWrapper="fill-100-field"
              valueInput={form.EmailCco}
              onChangeValue={updateProperty}
              name="EmailCco"
            />
          </FormRow>
        </ToggleInvoice>
        <ToggleInvoice title="Campos Inclusos na Proposta">
          <FormRow>
            <FormFieldCheckbox
              label="Total dos Servi�os"
              classNameWrapper="fill-15-field"
              value={form.IncluirTotal}
              onChange={(val) => {
                updateFormValue('IncluirTotal', val.value);
              }}
            />
            <FormFieldCheckbox
              label="Endere�o"
              classNameWrapper="fill-15-field"
              value={form.IncluirEndereco}
              onChange={(val) => {
                updateFormValue('IncluirEndereco', val.value);
              }}
            />
            <FormFieldCheckbox
              label="Introdu��o"
              classNameWrapper="fill-15-field"
              value={form.IncluirIntroducao}
              onChange={(val) => {
                updateFormValue('IncluirIntroducao', val.value);
              }}
            />
            <FormFieldCheckbox
              label="Validade"
              classNameWrapper="fill-15-field"
              value={form.IncluirValidade}
              onChange={(val) => {
                updateFormValue('IncluirValidade', val.value);
              }}
            />
            <FormFieldCheckbox
              label="Garantia"
              classNameWrapper="fill-15-field"
              value={form.IncluirGarantia}
              onChange={(val) => {
                updateFormValue('IncluirGarantia', val.value);
              }}
            />
          </FormRow>
          <FormRow>
            <FormFieldCheckbox
              label="Prazos de Entrega"
              classNameWrapper="fill-15-field"
              value={form.IncluirEntrega}
              onChange={(val) => {
                updateFormValue('IncluirEntrega', val.value);
              }}
            />
            <FormFieldCheckbox
              label="Condi��es de Pagamento"
              classNameWrapper="fill-15-field"
              value={form.IncluirCondicao}
              onChange={(val) => {
                updateFormValue('IncluirCondicao', val.value);
              }}
            />
            <FormFieldCheckbox
              label="Observa��es"
              classNameWrapper="fill-15-field"
              value={form.IncluirObservacao}
              onChange={(val) => {
                updateFormValue('IncluirObservacao', val.value);
              }}
            />
            <FormFieldCheckbox
              label="Informa��o Adicional"
              classNameWrapper="fill-15-field"
              value={form.IncluirInformacao}
              onChange={(val) => {
                updateFormValue('IncluirInformacao', val.value);
              }}
            />
          </FormRow>
        </ToggleInvoice>
        <FormRow justifyEnd space15 marginTop40>
          <OrangeButton type="submit" name="salvarEnviar">
            Salvar e Enviar
          </OrangeButton>
          <OrangeButton type="submit" name="salvar">
            Salvar
          </OrangeButton>
          <OrangeButton type="submit" name="salvarGerar">
            Salvar e Gerar
          </OrangeButton>
          <WhiteButton onClick={() => redirectToAttendances(id)}>Cancelar</WhiteButton>
        </FormRow>
      </form>
      <PopupEditService
        visible={popUpsVisibilities.addService}
        setVisible={(val) => setPopUpVisibility('addService', val)}
        addService={(service) => addService(service)}
        showAdditionalDescription={true}
        selectedService={selectedService}
      />
      <PopupAttendanceType
        visible={popUpsVisibilities.addAttendanceType}
        setVisible={(val) => setPopUpVisibility('addAttendanceType', val)}
        reloadAttendanceTypes={loadAttendanceTypes}
      />
      <PopupAttendanceType
        visible={popUpsVisibilities.editAttendanceType}
        setVisible={(val) => setPopUpVisibility('editAttendanceType', val)}
        reloadAttendanceTypes={loadAttendanceTypes}
        entity={attendanceType}
        isEdit
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
      <PopUpDocument
        onConfirm={(idDocument) => submitFormValidated('salvarGerar', idDocument)}
        visible={popUpConfirmSale}
        setVisible={setPopUpConfirmSale}
        title="Confirma��o"
        message="Voc� tem certeza que deseja gerar o documento para a proposta selecionada?"
      />
    </>
  );
};

export default AttendanceRegistration;

import React from 'react';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import './style.css';
import PropTypes from 'prop-types';
import iconPix from '../../../assets/Pix-icon-branco.svg';
import iconExcluir from '../../../assets/icone-excluir.svg';

const ThreePointsAnimationCustom = ({ item }) => {
  const themeColors = {
    info: '#7099A6',
    primary: '#00a6d2',
    secondary: '#e16996',
    tertiary: '#5da57d',
    inherit: '#EA9900',
    success: '#00b248',
    error: '#ff3b3d',
    warning: '#f7b91f',
    dark: '#6F6F6F',
    ligth: '#596CAE',
    pix: '#32BCAD',
    pdf: '#C70C00',
    bluepurple: '#596AA9'
  };

  const translateHex = (kendoColor) => themeColors[kendoColor];
  return (
    <>
      <br />
      <br />
      <div className="tooltip-popup-threePoints">
        <FloatingActionButton
          popupSettings={{
            animate: false
          }}
          icon={item.icon}
          themeColor={item.themeColor}
          shape="circle"
          size="small"
          positionMode="relative"
          onClick={() => item.onClick()}
        />

        {item.icon === 'iconPix' && <img src={iconPix} alt="Logo do Pix" className="icone-pix" />}
        {item.icon === 'iconExcluir' && (
          <img src={iconExcluir} alt="Excluir" className="icone-excluir" />
        )}

        <span
          className="tooltip-threePoints-popuptext"
          style={{ backgroundColor: translateHex(item.themeColor) }}
        >
          {item.text}
        </span>
      </div>
    </>
  );
};

ThreePointsAnimationCustom.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    themeColor: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired
};
export default ThreePointsAnimationCustom;

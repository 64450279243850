import { createContext, useState } from 'react';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [walletSelected, setWalletSelected] = useState(false);

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
      walletSelected,
      setWalletSelected
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

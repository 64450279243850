import { axiosWalletRequest, METHOD } from '../api/Requests';

export const exportPDF = (id) => axiosWalletRequest(`Faturamento/GerarPDF/${id}`, METHOD.GET);

export const transmit = (id) => axiosWalletRequest(`Faturamento/Faturar/${id}`, METHOD.GET);

export const cancel = (id, reason) => axiosWalletRequest(`Faturamento/Cancelar/${id}/${reason}`, METHOD.GET);

export const recoveryPDF = (id) => axiosWalletRequest(`Faturamento/RecuperarPDF/${id}`, METHOD.GET);

export const recoveryXML = (id) => axiosWalletRequest(`Faturamento/RecuperarXML/${id}`, METHOD.GET);

export const send = (id, bill, email) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    var response = axiosWalletRequest(`Faturamento/Enviar/${id}/${bill}`, METHOD.POST, email, config);
    return response;
}

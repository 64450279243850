import '@progress/kendo-theme-default/dist/all.css';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';

import React from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';

import './App.css';
import './fieldsWidth.css';
import './toastifyOverrides.css';

import View from './view';

const App = () => (
  <div className="App">
    <View />
    <ToastContainer
      position="bottom-right"
      autoClose={2000}
      hideProgressBar
      newestOnTop
      closeOnClick={false}
      draggable={false}
      icon={false}
      closeButton={false}
      transition={cssTransition({
        enter: 'animate__animated animate__fadeIn',
        exit: 'animate__animated animate__fadeOut'
      })}
      theme="colored"
    />
  </div>
);

export default App;

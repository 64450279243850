import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { DrawerContent } from '@progress/kendo-react-layout';
import NavBar from '../components/navBar';
import SideBar from '../components/sideBar';

import './styles.css';

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(true);

  const toggleMenuOpen = () => setMenuOpen(!menuOpen);

  return (
    <div className="LayoutWrapper">
      <SideBar isOpen={menuOpen} />
      <div className="ScreenContent">
        <NavBar clickHamburguerMenu={toggleMenuOpen} menuOpen={menuOpen} />
        <DrawerContent className="drawer-content">{children}</DrawerContent>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;

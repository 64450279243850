import * as React from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { IntlProvider, load, LocalizationProvider } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import caGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/pt/dateFields.json';

load(likelySubtags, caGregorian, dateFields);

const DateCellMonth = (props) => {
  const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
  const isInEdit = field === dataItem.inEdit;
  const value = field && dataItem[field] ? dataItem[field] : null;
  const onChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value
      });
    }
  };
  const padTo2Digits = (num) => num.toString().padStart(2, '0');
  const formatDate = (date) => [padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');

  const dateString = value !== null && value !== undefined ? formatDate(value) : '';
  const defaultRendering = (
    <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
      {isInEdit ? (
        <LocalizationProvider language="pt">
          <IntlProvider locale="pt">
            <div>
              <DatePicker name={field} defaultValue={value} format="MM/yyyy" onChange={onChange} />
            </div>
          </IntlProvider>
        </LocalizationProvider>
      ) : (
        dateString
      )}
    </td>
  );
  return render?.call(undefined, defaultRendering, props);
};

export default DateCellMonth;

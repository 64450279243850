import './style.css';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Upload } from '@progress/kendo-react-upload';
import TransparentCard from '../../transparentCard';
import Popup from '../layout';
import FormRow from '../../formRow';
import GenericField from '../../formField/genericField';
import PopUpError from '../popUpError';

const uploadRef = React.createRef();

const PopUpUploadFileOrLink = ({ visible, setVisible, title, allowedExtensions, uploadFile }) => {
  const [fileSource, setFileSource] = useState(null);
  const [message, setMessage] = useState(null);
  const [link, setLink] = useState(null);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorMessage, setPopUpErrorMessage] = useState(null);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState(null);

  const confirmAdd = async () => {
    if (fileSource) {
      await uploadFile(fileSource.getRawFile(), null);
      setFileSource(null);
      setMessage(null);
      setVisible(false);
    } else if (link) {
      await uploadFile(null, link);
      setLink(null);
      setMessage(null);
      setVisible(false);
    } else {
      setPopUpErrorTitle('Importar arquivo ou link');
      setPopUpErrorMessage('Arquivo ou link n�o informado.');
      setPopUpError(true);
    }
  };
  return (
    <>
      <Popup
        width={520}
        title={title}
        visible={visible}
        setVisible={setVisible}
        cancelText="Cancelar"
        confirmText="Enviar"
        haveConfirm={false}
        onOthers={[confirmAdd]}
        othersText={['Enviar']}
      >
        <FormRow withShrink>
          <span>Insira o arquivo ou o link para importar cupom fiscal:</span>
        </FormRow>
        <FormRow withShrink>
          <span style={{ marginBottom: '5px' }}>Arquivo</span>
        </FormRow>
        <Upload
          ref={uploadRef}
          autoUpload={true}
          restrictions={{ allowedExtensions }}
          onAdd={(event) => {
            setFileSource(event.newState[0]);
            setMessage(event.newState[0].name);
            event.affectedFiles.forEach((file) => {
              if (file.validationErrors?.length > 0) {
                if (file.validationErrors[0] === 'invalidFileExtension') {
                  setFileSource(null);
                  setMessage(
                    `Extens�o do arquivo � inv�lida. Somente arquivos ${allowedExtensions} s�o permitidos.`
                  );
                }
              }
            });
          }}
        />
        <TransparentCard
          className="selected-file-phrase"
          content={message != null ? message : 'Nenhum arquivo selecionado.'}
        />
        <FormRow withShrink>
          <GenericField
            titleLabel="Link"
            valueInput={link}
            classNameWrapper="fill-100-field"
            onChangeValue={(val) => {
              setLink(val.value);
            }}
            name="link"
          />
        </FormRow>
        <PopUpError
          visible={popUpError}
          setVisible={setPopUpError}
          title={popUpErrorTitle}
          message={popUpErrorMessage}
        />
      </Popup>
    </>
  );
};

PopUpUploadFileOrLink.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpUploadFileOrLink;

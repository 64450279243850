import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import FormFieldDrop from '../../../components/formField/drop';
import GenericField, { INTERNAL_ICON } from '../../../components/formField/genericField';
import FormRow from '../../../components/formRow';
import PopUpError from '../../../components/popUps/popUpError';
import ToggleInvoice from '../../../components/toggleInvoice';
import { useForms } from '../../../hooks';
import * as proposalService from '../../../services/proposal';
import { successNotification, errorNotification } from '../../../components/notification';
import LoadScreen from '../../load';

const initialProposal = {
  Nome: null,
  Introducao: null,
  Validade: null,
  Garantia: null,
  Entrega: null,
  Condicao: null,
  Observacao: null,
  InformacaoAdicional: null
};

const ProposalRegistration = () => {
  const { id } = useParams();
  const history = useHistory();
  const [form, updateProperty, setForm, updateFormValue] = useForms(initialProposal);
  const [loading, setLoading] = useState(true);

  const redirectToProposalList = () => history.push('/proposals');

  useEffect(async () => {
    setLoading(true);
    if (id) {
      const { data } = await proposalService.getById(id);
      setForm({
        Nome: data.Nome,
        Introducao: data.Introducao,
        Validade: data.Validade,
        Garantia: data.Garantia,
        Entrega: data.Entrega,
        Condicao: data.Condicao,
        Observacao: data.Observacao,
        InformacaoAdicional: data.InformacaoAdicional
      });
    }
    setLoading(false);
  }, []);

  const validation = async () => true;

  const submitForm = async (e) => {
    e.preventDefault();
    if (await validation()) {
      setLoading(true);
      const bodyForm = {
        Nome: form.Nome,
        Introducao: form.Introducao,
        Validade: form.Validade,
        Garantia: form.Garantia,
        Entrega: form.Entrega,
        Condicao: form.Condicao,
        Observacao: form.Observacao,
        InformacaoAdicional: form.InformacaoAdicional
      };
      let response = null;
      if (id) response = await proposalService.update(id, bodyForm);
      else response = await proposalService.create(bodyForm);
      if (response.status === 200) {
        successNotification('Proposta criada/atualizada com sucesso!');
        redirectToProposalList();
      } else {
        const { data } = response;
        errorNotification(data.MensagemUsuario);
      }
      setLoading(false);
    }
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      {id ? <h1>Atualizar Padr�o de Proposta</h1> : <h1>Criar Padr�o de Proposta</h1>}
      <form onSubmit={submitForm}>
        <FormRow>
          <GenericField
            titleLabel="Nome do Padr�o"
            classNameWrapper="fill-100-field"
            valueInput={form.Nome}
            onChangeValue={updateProperty}
            name="Nome"
            required
            msg="Informe um nome para identificar e selecionar o padr�o a ser utilizado em uma nova proposta. Ex.:  
            Proposta Padr�o para Consultoria"
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Introdu��o"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Introducao}
            onChangeValue={(val) => {
              updateFormValue('Introducao', val.value);
            }}
            msg="Insira aqui uma apresenta��o inicial de sua proposta, que ser� enviada antes dos itens e valores cotados. Manter o campo em branco, caso n�o queira que a informa��o seja enviada na proposta.            Ex.: Agradecemos o contato e, conforme solicitado, enviamos proposta para nosso servi�o de consultoria de gest�o de seu neg�cio."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Validade"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Validade}
            onChangeValue={(val) => {
              updateFormValue('Validade', val.value);
            }}
            msg="Informe por quanto tempo a proposta ser� v�lida. Manter o campo em branco, caso n�o queira que a informa��o seja enviada na proposta. Ex.: Proposta v�lida por 10 (dez) dias."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Garantia"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Garantia}
            onChangeValue={(val) => {
              updateFormValue('Garantia', val.value);
            }}
            msg="Informe a garantia dos produtos ou servi�os propostos. Manter o campo em branco, caso n�o queira que a informa��o seja enviada na proposta. Ex.: A garantia dos servi�os propostos � de 30 (trinta) dias, contados a partir da entrega."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Prazos de Entrega"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Entrega}
            onChangeValue={(val) => {
              updateFormValue('Entrega', val.value);
            }}
            msg="Informar em quanto tempo os produtos ou servi�os propostos ser�o entregues ao cliente. Manter o campo em branco, caso n�o queira que a informa��o seja enviada na proposta. Ex.: O servi�o proposto ser� entre que 90 (noventa) dias."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Condi��es de Pagamento"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Condicao}
            onChangeValue={(val) => {
              updateFormValue('Condicao', val.value);
            }}
            msg="Informar as condi��es de pagamento dispon�veis para o seu cliente. Manter o campo em branco, caso n�o queira que a informa��o seja enviada na proposta. Ex.: Pre�o em Reais, v�lidos para pagamento � vista ou em at� 3(tr�s) vezes sem juros. Formas de pagamento dispon�veis: PIX (QR Code ou Chave PIX), boleto banc�rio, dep�sito em conta corrente, cart�o de cr�dito (consulte bandeiras aceitas pelo estabelecimento)."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Observa��es"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.Observacao}
            onChangeValue={(val) => {
              updateFormValue('Observacao', val.value);
            }}
            msg="Insira aqui observa��es relevantes a serem enviadas para seu cliente na proposta. Manter o campo em branco, caso n�o queira que a informa��o seja enviada na proposta. Ex.: Os servi�os propostos ser�o executados em formato h�brido (parcialmente virtuais). Custos de viagens n�o est�o inclusos nesta proposta."
            enableInfo
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Informa��es Adicionais e Finaliza��o"
            classNameWrapper="fill-100-field"
            isTextArea
            rows={6}
            valueInput={form.InformacaoAdicional}
            onChangeValue={(val) => {
              updateFormValue('InformacaoAdicional', val.value);
            }}
            msg="Insira aqui todas as informa��es adicionais a serem enviadas na proposta, tais como detalhamento e descri��es adicionais dos produtos ou servi�os cotados, informa��es sobre a pr�pria empresa e uma mensagem de finaliza��o da proposta. Manter o campo em branco, caso n�o queira que a informa��o seja enviada na proposta. Ex.: Sobre o Servi�o de Consultoria: A finalidade da consultoria � levantar as necessidades, por meio de diagn�sticos e processos, identificar solu��es e, ent�o, recomendar a��es de melhoria. Nossa Empresa �, h� mais de X anos, refer�ncia em presta��o de servi�os de consultoria, atuando em todo o territ�rio nacional. Permanecemos � disposi��o para quaisquer d�vidas."
            enableInfo
          />
        </FormRow>
        <FormRow justifyEnd space15 marginTop40>
          <OrangeButton type="submit">Salvar</OrangeButton>
          <WhiteButton onClick={() => history.goBack()}>Cancelar</WhiteButton>
        </FormRow>
      </form>
    </>
  );
};

export default ProposalRegistration;

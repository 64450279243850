import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import FormFieldDrop from '../../formField/drop';
import FormFieldDate from '../../formField/date';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import FormRow from '../../formRow';
import Popup from '../layout';
import * as personService from '../../../services/person';
import * as coinService from '../../../services/coin';
import LoadScreen from '../../../view/load';

const PopUpSalesList = ({ visible, setVisible, add }) => {
  const [description, setDescription] = useState(null);
  const [coin, setCoin] = useState(null);
  const [coins, setCoins] = useState([]);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const confirm = () => {
    const item = {
      ListaVenda: description,
      MoedaId: coin?.Id,
      MoedaNome: coin?.Simbolo,
      Valor: value
    };
    add(item);
    setDescription(null);
    setCoin(null);
  };

  const loadCoins = async () => {
    const { data } = await coinService.getAll();
    setCoins(data);
    if (data?.length > 0) setCoin(data[0]);
    return data;
  };

  useEffect(async () => {
    if (visible) {
      setLoading(true);
      await loadCoins();
      setLoading(false);
    }
  }, [visible]);

  return (
    <Popup
      title="Adicionar Lista de Venda"
      width={500}
      visible={visible}
      setVisible={setVisible}
      confirmText="Adicionar"
      cancelText="Cancelar"
      onConfirm={confirm}
    >
      {loading ? (
        <LoadScreen />
      ) : (
        <>
          <FormRow>
            <GenericField
              titleLabel="Lista de Venda"
              classNameWrapper="fill-100-field"
              valueInput={description}
              onChangeValue={(val) => {
                setDescription(val.value);
              }}
              required
            />
          </FormRow>
          <FormRow>
            <FormFieldDrop
              titleLabel="Moeda"
              defaultValueSelect="Selecione..."
              onChangeValue={(val) => {
                setCoin(val.value);
              }}
              value={coin}
              className="fill-100-field"
              infosSelect={coins}
              dataItemKey="Id"
              textField="Simbolo"
            />
          </FormRow>
          <FormRow justifyEnd>
            <GenericField
              titleLabel="Valor"
              enableReais
              classNameWrapper="fill-100-field"
              valueInput={value}
              setValue={setValue}
              iconEnabled={INTERNAL_ICON.REAIS_ICON}
              required
              min="0"
            />
          </FormRow>
        </>
      )}
    </Popup>
  );
};

PopUpSalesList.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

PopUpSalesList.defaultProps = {};

export default PopUpSalesList;

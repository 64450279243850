import { axiosUserRequest, METHOD } from '../api/Requests';

export const getCountries = () => axiosUserRequest('/Pais', METHOD.GET);

export const getCountryById = (id) => axiosUserRequest(`/Pais/${id}`, METHOD.GET);

export const municipioFromCEP = (CEP) => axiosUserRequest(`/Cep/${CEP}`, METHOD.GET);

export const statesFromCountry = (idPais) => axiosUserRequest(`/Estado/${idPais}`, METHOD.GET);

export const citiesFromState = (idEstado) => axiosUserRequest(`/Municipio/${idEstado}`, METHOD.GET);

export const getCitiesInvoice = () => axiosUserRequest(`/Municipio/ObterMunicipiosNotaFiscal`, METHOD.GET);

export const getCityById = (idMunicipio) => axiosUserRequest(`/Municipio/ObterMunicipioPorId/${idMunicipio}`, METHOD.GET);

export const statesFromBrazil = () => axiosUserRequest(`/Estado/ObterEstadoBrasil`, METHOD.GET);

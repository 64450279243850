import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import { CalendarWithButtons, DateFilter } from '../../components/dateComponents';
import Table from '../../components/table';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpError from '../../components/popUps/popUpError';
import './styles.css';
import LoadScreen from '../load';
import * as humanResourceService from '../../services/humanResource';
import * as expenseService from '../../services/expense';
import {
  successNotification,
  errorNotification,
  warningNotification
} from '../../components/notification';
import { FirstDayOfMonth, LastDayOfMonth, DateNow } from '../../utils/date';
import PopUpFiles from '../../components/popUps/popUpFiles';
import PopUpViewer from '../../components/popUps/popUpViewer';
import { urlBase64 } from '../../utils/download';
import PopUpSettings from '../../components/popUps/popUpSettings';

const HumanResources = () => {
  const { id } = useParams();
  const editId = window.localStorage.getItem('EDIT_ID');
  const history = useHistory();
  const [humanResources, setHumanResources] = useState([]);
  const [expensesSelectedsIds, setExpensesSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popUpConfirmDelete, setPopUpConfirmDelete] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [rowIndex, setRowIndex] = useState(0);
  const [popUpConfirmTaker, setPopUpConfirmMaker] = useState(false);
  const [file, setFile] = useState(null);
  const [popUpFile, setPopUpFile] = useState(false);
  const [idAttach, setIdAttach] = useState(null);
  const [popUpViewer, setPopUpViewer] = useState(false);
  const [dataViewer, setDataViewer] = useState(null);
  const [popUpSettings, setPopUpSettings] = useState(false);
  const filterDateLocal1 = window.localStorage.getItem('HUMAN_FILTER_DATE1');
  const filterDateLocal2 = window.localStorage.getItem('HUMAN_FILTER_DATE2');
  const dateNow = DateNow();
  let filterDate = { date1: FirstDayOfMonth(dateNow), date2: LastDayOfMonth(dateNow) };
  if (filterDateLocal1 && id)
    filterDate = { date1: new Date(filterDateLocal1), date2: new Date(filterDateLocal2) };
  const [date, setDate] = useState(filterDate);
  const filterTypeLocal = window.localStorage.getItem('HUMAN_FILTER_TYPE');
  const [filterType, setFilterType] = useState(filterTypeLocal || 'month');

  window.localStorage.setItem('HUMAN_FILTER_DATE1', date.date1);
  window.localStorage.setItem('HUMAN_FILTER_DATE2', date.date2);
  window.localStorage.setItem('HUMAN_FILTER_TYPE', filterType);

  const columns = [
    {
      field: 'Id',
      title: 'Anexos',
      width: 90,
      cell: (props) => {
        let td = null;
        const cellClick = {
          onClick: () => {
            setIdAttach(props.dataItem.Id);
            setPopUpFile(true);
          }
        };
        if (props.dataItem.PossuiAnexo) {
          td = (
            <td style={{ textAlign: 'center' }}>
              <button type="button" className="general-button" {...cellClick}>
                <span className="k-icon k-font-icon k-i-attachment" />
              </button>
            </td>
          );
        } else {
          td = <td style={{ textAlign: 'center' }} {...cellClick} />;
        }
        return td;
      }
    },
    {
      field: 'Data',
      title: 'Data de Emiss�o',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      columnMenu: true
    },
    {
      field: 'DataVencimento',
      title: 'Data de Venc.',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      isCenter: true,
      columnMenu: true
    },
    {
      field: 'Numero',
      title: 'N�m.',
      isRight: true,
      columnMenuCheckbox: true
    },
    {
      field: 'PessoaNome',
      title: 'Nome',
      width: 350,
      columnMenuCheckbox: true
    },
    {
      field: 'ValorLiquido',
      title: 'Valor (R$)',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}',
      columnMenu: true,
      totalCell: true
    }
  ];

  const seekScroll = async (data) => {
    if (idAttach != null && idAttach !== '0') {
      const index = data.findIndex((item) => item.Id === idAttach);
      if (index >= 0) {
        setRowIndex(index);
        return;
      }
    }
    if (data.length > 0) setRowIndex(data.length - 1);
    else setRowIndex(0);
  };

  const fetchHumanResources = async () => {
    setLoading(true);
    const dateInterval = {
      DataInicio: date.date1,
      DataFim: date.date2
    };
    let { data } = await humanResourceService.getHumanResourceByDate(dateInterval);
    data = data.map((r) => {
      const due = r.TipoPagamento === 1 ? new Date(r.DataVencimento) : 'Parcelado';
      return {
        ...r,
        Data: new Date(r.Data),
        DataVencimento: due
      };
    });
    setHumanResources(data);
    seekScroll(data);
    setExpensesSelectedsIds([]);
    setLoading(false);
  };

  const excludeHumanResources = async () => {
    setLoading(true);
    const excludeHumanResourcesRequests = expensesSelectedsIds.map((expenseSelected) =>
      humanResourceService.exclude(expenseSelected)
    );

    const excludeHumanResourcesResponses = await Promise.all(excludeHumanResourcesRequests);

    const isAllRequestsRight = excludeHumanResourcesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);
    setPopUpConfirmDelete(false);
    if (isAllRequestsRight) successNotification('Documento(s) exclu�do(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar excluir uma ou mais documentos:'];
      excludeHumanResourcesResponses.forEach((element) => {
        const { data } = element;
        if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
        else erro.push(data);
      });
      setPopUpErrorTitle('Erro ao Excluir Documento');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
    await fetchHumanResources();
  };

  const uploadFile = async (file) => {
    const response = await humanResourceService.uploadFile(file);
    const { data } = response;
    if (response.status === 200) {
      successNotification('Arquivo importado com sucesso!');
      if (data) history.push(`/humanResources/humanRegistration/${data}`);
      else await fetchHumanResources();
    } else if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
    else errorNotification(data);
  };

  const uploadFileConfirm = async () => {
    const response = await humanResourceService.uploadFile(file);
    const { data } = response;
    if (response.status === 200) {
      successNotification('Arquivo importado com sucesso!');
      if (data) history.push(`/humanResources/humanRegistration/${data}`);
      else await fetchHumanResources();
    } else if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
    else errorNotification(data);
  };

  const generateReceipt = async () => {
    setLoading(true);
    const generateReceiptRequests = expensesSelectedsIds.map((attendanceSelected) =>
      humanResourceService.generateReceipt(attendanceSelected)
    );

    const generateReceiptResponses = await Promise.all(generateReceiptRequests);

    const isAllRequestsRight = generateReceiptResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );
    setLoading(false);
    generateReceiptResponses.forEach((currentResponse) => {
      if (currentResponse.status === 200) {
        const base64 = currentResponse.data;
        const url = urlBase64(base64, 'pdf');
        setDataViewer(url);
        setPopUpViewer(true);
      }
    });

    if (!isAllRequestsRight) {
      const erro = ['Houve um erro ao tentar gerar PDF para a proposta selecionada:'];
      generateReceiptResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Gerar PDF');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }
  };

  useEffect(async () => {
    setLoading(true);
    await fetchHumanResources();
    setLoading(false);
  }, [date]);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="expense-list">
        <h1>Recursos Humanos</h1>

        <div className="buttons-expense-list">
          {filterType === 'month' && (
            <CalendarWithButtons
              date={date.date1}
              setDate={(val) => {
                const date1 = FirstDayOfMonth(val);
                const date2 = LastDayOfMonth(val);
                setDate({ date1, date2 });
                window.localStorage.setItem('HUMAN_FILTER_DATE1', date1);
                window.localStorage.setItem('HUMAN_FILTER_DATE2', date2);
              }}
              service={humanResourceService}
            />
          )}
          {filterType === 'day' && (
            <DateFilter
              date1={date.date1}
              date2={date.date2}
              setDate={(date1, date2) => {
                setDate({ date1, date2 });
                window.localStorage.setItem('HUMAN_FILTER_DATE1', date1);
                window.localStorage.setItem('HUMAN_FILTER_DATE2', date2);
              }}
            />
          )}
          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_ADD}
            url="/humanResources/humanRegistration/0"
          />

          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS}
            internalItems={[
              {
                id: INTERNAL_ITEMS.EXCLUIR,
                onClick: () => {
                  if (expensesSelectedsIds.length > 0) setPopUpConfirmDelete(true);
                  else warningNotification('Nenhum documento selecionado');
                },
                text: 'Excluir Documento'
              },
              {
                id: INTERNAL_ITEMS.GERAR_PDF,
                onClick: () => {
                  if (expensesSelectedsIds.length === 1) generateReceipt();
                  else if (expensesSelectedsIds.length > 1)
                    warningNotification('Somente um recurso humano deve ser selecionado');
                  else warningNotification('Nenhuma recurso humano selecionado');
                },
                text: 'Gerar Recibo'
              },
              {
                id: INTERNAL_ITEMS.CONFIGURAR,
                onClick: () => setPopUpSettings(true),
                text: 'Configurar Tela'
              }
            ]}
          />
        </div>
        <Table
          name="HumanResources"
          data={humanResources}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setExpensesSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          onClickInRow={({ Id }) => {
            window.localStorage.setItem('EDIT_ID', Id);
            history.push(`/humanResources/humanRegistration/${Id}`);
          }}
          sortable={true}
          rowIndex={rowIndex}
        />
      </div>
      <PopUpConfirm
        onConfirm={excludeHumanResources}
        visible={popUpConfirmDelete}
        setVisible={setPopUpConfirmDelete}
        title="Confirma��o"
        message="Voc� tem certeza que deseja excluir o(s) documentos(s) selecionado(s)?"
      />
      <PopUpConfirm
        onConfirm={uploadFileConfirm}
        visible={popUpConfirmTaker}
        setVisible={setPopUpConfirmMaker}
        title="Confirma��o"
        message="O tomador da NF � diferente da empresa cadastrada no NEXTBusiness. Desejar importar a NF?"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
      <PopUpViewer
        visible={popUpViewer}
        setVisible={setPopUpViewer}
        title="Pr�-visualiza��o"
        data={dataViewer}
        type="application/pdf"
      />
      <PopUpFiles
        visible={popUpFile}
        setVisible={setPopUpFile}
        title="Anexos"
        id={idAttach}
        afterAttaching={async () => {
          setIdAttach(null);
          await fetchHumanResources();
        }}
        service={expenseService}
      />
      <PopUpSettings
        onConfirm={(val) => {
          setFilterType(val);
          window.localStorage.setItem('HUMAN_FILTER_TYPE', val);
        }}
        visible={popUpSettings}
        setVisible={setPopUpSettings}
        title="Prefer�ncias"
        filter={filterType}
      />
    </>
  );
};

export default HumanResources;

import { axiosWalletRequest, METHOD } from '../api/Requests';
import { stringBase64ToByteArray, mimeType } from '../utils/download';

export const getById = (id) => axiosWalletRequest(`Despesa/${id}`, METHOD.GET);

export const create = (body) => axiosWalletRequest('Despesa', METHOD.POST, body);

export const exclude = (id) => axiosWalletRequest(`Despesa/${id}`, METHOD.DELETE);

export const update = (id, body) => axiosWalletRequest(`Despesa/${id}`, METHOD.PUT, body);

export const getExpenseTypes = () => axiosWalletRequest(`Despesa/ListarTipoDespesa`, METHOD.GET);

export const getParcelTypes = () => axiosWalletRequest(`Despesa/ListarTipoPagamento`, METHOD.GET);

export const getFrequencies = () => axiosWalletRequest(`Despesa/ListarPeriodicidade`, METHOD.GET);

export const getLast = async () => {
    const { data } = await axiosWalletRequest(`Despesa/ObterUltimoRecursoHumanoPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.Data);
    return null;
}

export const getFirst = async () => {
    const { data } = await axiosWalletRequest(`Despesa/ObterPrimeiroRecursoHumanoPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.Data);
    return null;
}

export const uploadFile = (file) => {
    let fileName = file.name;
    fileName = fileName.split(".");
    return axiosWalletRequest(`Despesa/ImportarArquivo/${fileName[0]}`, METHOD.POST, file);
}

export const excludeAttach = (id) => axiosWalletRequest(`Despesa/ExcluirAnexo/${id}`, METHOD.DELETE);

export const getHumanResourceTypes = () => axiosWalletRequest(`Despesa/ListarTipoRecursoHumano`, METHOD.GET);

export const getHumanResourceByDate = (dateInverval) => axiosWalletRequest('Despesa/FiltrarRecursoHumano', METHOD.POST, dateInverval);

export const generateReceipt = (id) => axiosWalletRequest(`Despesa/GerarRecibo/${id}`, METHOD.GET);
import { getTokenFromStorage, setTokenOnStorage, TOKENS } from "../auth/Tokens";
import axios from './AxiosRequest';
import {
  setUserRefreshTokenInAPI,
  setUserTokenInAPI,
  setWalletRefreshTokenInAPI,
  setWalletTokenInAPI,
  setBaseTokenInAPI
} from './utils';

const refreshToken = async walletRequest => {
  const axiosConfig = { headers: { 'Content-Type': 'application/json' } };

  const userRefreshToken = getTokenFromStorage(TOKENS.USER.REFRESH_TOKEN);
  const walletRefreshToken = getTokenFromStorage(TOKENS.WALLET.REFRESH_TOKEN);

  if (walletRequest) setWalletRefreshTokenInAPI();
  else setUserRefreshTokenInAPI();

  const { data: newToken } = await axios.post(
    "/RefreshToken/UsarRefreshToken",
    walletRequest ? walletRefreshToken : userRefreshToken,
    axiosConfig
  );

  if (walletRequest) {
    setTokenOnStorage(TOKENS.WALLET.TOKEN, newToken);
    setWalletTokenInAPI(newToken);
  } else {
    setTokenOnStorage(TOKENS.USER.TOKEN, newToken);
    setUserTokenInAPI(newToken);
  }
}

const axiosRequest = ({
  url,
  method,
  body,
  options
}) => axios[method](url, body, options);

const tryRequest = async (url, method, body, walletRequest, options) => {
  let res;

  try {
    res = await axiosRequest({ url, method, body, options });
  } catch (e) {
    res = e.response;
  }

  if (res.status === 401 && res.data.Codigo === 12) {
    await refreshToken(walletRequest);

    res = await axiosRequest({ url, method, body, options });
  }
  // else if(res.status >= 400){
  //   throw {response: res}
  // }

  return res;
}

export const axiosBaseRequest = async (url, method, body, options) => {
  setBaseTokenInAPI();
  return await tryRequest(url, method, body, false, options);
}

export const axiosUserRequest = async (url, method, body, options) => {
  setUserTokenInAPI();
  return await tryRequest(url, method, body, false, options);
}

export const axiosWalletRequest = async (url, method, body, options) => {
  setWalletTokenInAPI();
  return await tryRequest(url, method, body, true, options);
}

export const METHOD = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete'
};

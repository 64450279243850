import { Dialog } from '@progress/kendo-react-dialogs';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Error } from '@progress/kendo-react-labels';
import { OrangeButton, SimpleGrayButton, WhiteButton } from '../../buttonsComponent';
import FormRow from '../../formRow';
import { errorNotification } from '../../notification';
import './style.css';

const PopupLayoutOnClick = ({
  title,
  visible,
  setVisible,
  width,
  children,
  cancelText,
  confirmText,
  onConfirm,
  className,
  haveExclude,
  excludeText,
  onDelete,
  disable,
  haveConfirm
}) => {
  const closePopUp = () => setVisible(false);

  const confirmAction = async () => {
    try {
      await onConfirm();
      closePopUp();
    } catch ({ message }) {
      errorNotification(message, { autoClose: 2000 });
    }
  };

  const deleteAction = async () => {
    try {
      await onDelete();
      closePopUp();
    } catch ({ message }) {
      errorNotification(message, { autoClose: 2000 });
    }
  };

  return (
    visible && (
      <Dialog
        title={title}
        className={`popup-layout ${className}`}
        onClose={closePopUp}
        width={width}
      >
        {children}
        <FormRow spaceBetween alignCenter marginTop40>
          {haveExclude && <SimpleGrayButton onClick={deleteAction}>{excludeText}</SimpleGrayButton>}
          <FormRow justifyEnd space15 withoutMarginTop>
            {haveConfirm && (
              <OrangeButton onClick={confirmAction} disable={disable}>
                {confirmText}
              </OrangeButton>
            )}
            <WhiteButton onClick={closePopUp}>{cancelText}</WhiteButton>
          </FormRow>
        </FormRow>
      </Dialog>
    )
  );
};

PopupLayoutOnClick.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  width: PropTypes.number,
  className: PropTypes.string,
  haveExclude: PropTypes.bool,
  excludeText: PropTypes.string,
  onDelete: PropTypes.func,
  disable: PropTypes.bool,
  haveConfirm: PropTypes.bool
};

PopupLayoutOnClick.defaultProps = {
  width: 500,
  className: '',
  haveExclude: false,
  excludeText: 'Excluir',
  disable: false,
  onDelete: () => {
    throw Error('Unexpected Click');
  },
  haveConfirm: true
};

export default PopupLayoutOnClick;

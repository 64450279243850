import React, { useState, useCallback, cloneElement } from 'react';
import { Grid, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { load, IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools';
import { groupBy, orderBy, filterBy } from '@progress/kendo-data-query';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import brNumbers from 'cldr-numbers-full/main/br/numbers.json';
import brCurrency from 'cldr-numbers-full/main/br/currencies.json';
import caGregorian from 'cldr-dates-full/main/br/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/br/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/br/timeZoneNames.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import { WhiteButton } from '../buttonsComponent';
import './style.css';

load(brNumbers, brCurrency, currencyData, likelySubtags, caGregorian, dateFields, timeZoneNames);

const GridGroup = ({ data, initialGroup, totalField, noRecords, children, ...other }) => {
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState([]);
  const [group, setGroup] = useState(initialGroup);
  const [hasFilter, setHasFilter] = useState(false);

  const processWithGroups = (data, group, sort, filter) => {
    let newDataState = filterBy(data, filter);
    newDataState = orderBy(newDataState, sort);
    newDataState = groupBy(newDataState, group);
    setGroupIds({
      data: newDataState,
      group
    });
    return newDataState;
  };

  const [resultState, setResultState] = useState(
    processWithGroups(data, initialGroup, sort, filter)
  );

  const [collapsedState, setCollapsedState] = React.useState([]);

  const onGroupChange = React.useCallback((event) => {
    const newGroups = event.group;
    const areNewGroupsUnique = !newGroups.some(
      (item, index) => newGroups.findIndex((group) => group.field === item.field) !== index
    );
    if (areNewGroupsUnique) {
      const newDataState = processWithGroups(data, event.group, sort, filter);
      setGroup(event.group);
      setResultState(newDataState);
    }
  }, []);

  const onExpandChange = useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );

  const onSortChange = (event) => {
    const newDataState = processWithGroups(data, group, event.sort, filter);
    setSort(event.sort);
    setResultState(newDataState);
  };

  const onFilterChange = (event) => {
    const newDataState = processWithGroups(data, group, sort, event.filter);
    setFilter(event.filter);
    if (event.filter) setHasFilter(true);
    else setHasFilter(false);
    setResultState(newDataState);
  };

  const clearFilters = () => {
    onFilterChange({ filter: null });
  };

  const newData = setExpandedState({
    data: resultState,
    collapsedIds: collapsedState
  });

  const cellRender = (td, props) => {
    if (td && td.props && props.editor === 'date') {
      const newProps = { ...td.props, style: { textAlign: 'center' } };
      const newTd = { ...td, props: newProps };
      td = newTd;
    }
    if (td && td.props && props.editor === 'numeric') {
      const newProps = { ...td.props, style: { textAlign: 'right' } };
      const newTd = { ...td, props: newProps };
      td = newTd;
    }
    if (td && td.props.children && props.rowType === 'groupHeader') {
      const elements = props.dataItem.items;
      let { children } = td.props.children.props;
      if (props.dataItem.value instanceof Date) {
        if (children.length > 1)
          children = [children[0], props.dataItem.value.toLocaleDateString()];
      }
      if (totalField) {
        let total = elements.reduce((acc, current) => acc + current[totalField], 0);
        total = total.toLocaleString('pt-br', { minimumFractionDigits: 2 });
        children = (
          <span>
            {children} - Total: {total}
          </span>
        );
      }
      return cloneElement(td, td.props, children);
    }
    return td;
  };

  noRecords = !noRecords ? 'Sem registros' : noRecords;
  return (
    <LocalizationProvider language="pt">
      <IntlProvider locale="pt-PT">
        <Grid
          {...other}
          data={newData}
          onGroupChange={onGroupChange}
          group={group}
          onExpandChange={onExpandChange}
          expandField="expanded"
          groupable={true}
          resizable={true}
          sortable={true}
          sort={sort}
          onSortChange={onSortChange}
          onFilterChange={onFilterChange}
          className="grid-group"
          cellRender={cellRender}
        >
          {hasFilter && (
            <GridToolbar>
              <WhiteButton onClick={clearFilters}>Limpar Filtro</WhiteButton>
            </GridToolbar>
          )}
          <GridNoRecords>{noRecords}</GridNoRecords>
          {children}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default GridGroup;

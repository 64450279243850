import React, { forwardRef, useRef } from 'react';
import InputField, { ICON } from '../formField/genericField/input';

const CustomInput = forwardRef((params, ref) => {
  const myRef = useRef();
  return (
    <InputField
      valueInput={params.value}
      onClick={params.onClick}
      refs={myRef}
      iconEnabled={ICON.CALENDAR}
    />
  );
});

export default CustomInput;

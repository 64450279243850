import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getAll = () => axiosWalletRequest('Proposta', METHOD.GET);

export const getById = (id) => axiosWalletRequest(`Proposta/${id}`, METHOD.GET);

export const create = (proposal) => axiosWalletRequest('Proposta', METHOD.POST, proposal);

export const update = (id, proposal) => axiosWalletRequest(`Proposta/${id}`, METHOD.PUT, proposal);

export const exclude = (id) => axiosWalletRequest(`Proposta/${id}`, METHOD.DELETE);
import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getAll = () => axiosWalletRequest('Pessoa/ObterCliente', METHOD.GET);

export const getById = (id) => axiosWalletRequest(`Pessoa/${id}`, METHOD.GET);

export const getByName = (nome) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    return axiosWalletRequest(`Pessoa/ObterClientePorNome`, METHOD.POST, nome, config);
}

export const getBySupplierName = (nome) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    return axiosWalletRequest(`Pessoa/ObterFornecedorPorNome`, METHOD.POST, nome, config);
}

export const getByCollaboratorName = (nome) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    return axiosWalletRequest(`Pessoa/ObterColaboradorPorNome`, METHOD.POST, nome, config);
}
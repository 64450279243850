import { axiosWalletRequest, METHOD } from '../api/Requests';
import { sortArrayByField } from '../utils/arrays';

export const getAll = async () => {
  const response = await axiosWalletRequest('CentroDeCustos', METHOD.GET);

  return {
    ...response,
    data: sortArrayByField(response.data, 'Nome')
  };
};

export const getById = (id) => axiosWalletRequest(`CentroDeCustos/${id}`, METHOD.GET);

export const create = (bodyRequest) => axiosWalletRequest('CentroDeCustos', METHOD.POST, bodyRequest);

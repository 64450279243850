import PropTypes from 'prop-types';
import React from 'react';
import { Label } from 'semantic-ui-react';
import './styles.css';

const CustomLabel = ({ enableIcon, onClickIcon, children }) => (
  <div className="label-wrapper">
    {enableIcon ? (
      <span className="wrapper-title-icon-plus">
        <Label className="text-label">{children}</Label>

        <input
          type="button"
          className="config-icon-plus"
          alt="Icon Adicionar"
          onClick={onClickIcon}
        />
      </span>
    ) : (
      <Label className="text-label">{children}</Label>
    )}
  </div>
);

CustomLabel.propTypes = {
  children: PropTypes.string.isRequired,
  enableIcon: PropTypes.bool,
  onClickIcon: PropTypes.func
};

CustomLabel.defaultProps = {
  enableIcon: false,
  onClickIcon: () => {
    throw Error('NotImplemented');
  }
};

export default CustomLabel;

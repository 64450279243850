import { axiosWalletRequest, METHOD } from '../api/Requests';
import { stringBase64ToByteArray, mimeType } from '../utils/download';

export const getAll = () => axiosWalletRequest('Despesa/', METHOD.GET);

export const getByDate = (dateInverval) => axiosWalletRequest('Despesa/Filtrar', METHOD.POST, dateInverval);

export const getById = (id) => axiosWalletRequest(`Despesa/${id}`, METHOD.GET);

export const create = (body) => axiosWalletRequest('Despesa', METHOD.POST, body);

export const exclude = (id) => axiosWalletRequest(`Despesa/${id}`, METHOD.DELETE);

export const update = (id, body) => axiosWalletRequest(`Despesa/${id}`, METHOD.PUT, body);

export const getExpenseTypes = () => axiosWalletRequest(`Despesa/ListarTipoDespesa`, METHOD.GET);

export const getParcelTypes = () => axiosWalletRequest(`Despesa/ListarTipoPagamento`, METHOD.GET);

export const getFrequencies = () => axiosWalletRequest(`Despesa/ListarPeriodicidade`, METHOD.GET);

export const getTaxTypes = () => axiosWalletRequest(`Despesa/ListarImposto`, METHOD.GET);

export const getLast = async () => {
    const { data } = await axiosWalletRequest(`Despesa/ObterUltimaDespesaPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.Data);
    return null;
}

export const getFirst = async () => {
    const { data } = await axiosWalletRequest(`Despesa/ObterPrimeiraDespesaPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.Data);
    return null;
}

export const uploadFile = (file) => {
    let fileName = file.name;
    fileName = fileName.split(".");
    return axiosWalletRequest(`Despesa/ImportarArquivo/${fileName[0]}`, METHOD.POST, file);
}

export const validateTaker = (file) => axiosWalletRequest('Despesa/ValidarTomador', METHOD.POST, file);

export const getTransportTypes = () => axiosWalletRequest(`Despesa/ListarFrete`, METHOD.GET);

export const createFile = (id, file) => {
    const fileName = file.name;
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
    const name = fileName.substr(0, fileName.lastIndexOf('.'));
    return axiosWalletRequest(`Despesa/InserirAnexo/${id}/${name}/${ext}`, METHOD.POST, file);
}

export const getFile = async (id) => {
    let { data } = await axiosWalletRequest(`Despesa/ListarAnexo/${id}`, METHOD.GET);
    let i = 1;
    data = data.map((item) => ({
        Id: i++,
        uid: i,
        name: item.Nome,
        extension: item.Extensao,
        size: item.TamanhoEmKBytes,
        getRawFile: () => {
            const byteArray = stringBase64ToByteArray(item.Dados);
            return new File([byteArray], item.Nome, { type: mimeType(item.Extensao) });
        }
    }));
    return data;
};

export const excludeFile = (id) => axiosWalletRequest(`Despesa/ExcluirAnexo/${id}`, METHOD.DELETE);

export const getHumanResourceTypes = () => axiosWalletRequest(`Despesa/ListarTipoRecursoHumano`, METHOD.GET);

export const getHumanResourceByDate = (dateInverval) => axiosWalletRequest('Despesa/FiltrarRecursoHumano', METHOD.POST, dateInverval);

export const generateReceipt = (id) => axiosWalletRequest(`Despesa/GerarRecibo/${id}`, METHOD.GET);

export const consultInvoice = (nsu) => axiosWalletRequest(`Despesa/ConsultarNF/${nsu}`, METHOD.GET);

export const getInvoice = () => axiosWalletRequest(`Despesa/ListarNF`, METHOD.GET);

export const importInvoice = (id) => axiosWalletRequest(`Despesa/ImportarNF/${id}`, METHOD.GET);

export const importCouponFile = (file) => {
    let fileName = file.name;
    fileName = fileName.split(".");
    return axiosWalletRequest(`Despesa/ImportarCupomFiscalArquivo/${fileName[0]}/${fileName[1]}`, METHOD.POST, file);
}

export const importCouponLink = (link) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    return axiosWalletRequest(`Despesa/ImportarCupomFiscalLink`, METHOD.POST, link, config);
}

export const getShopping = (filter) => axiosWalletRequest('Despesa/FiltrarDespesaServProd', METHOD.POST, filter);

export const exportExcel = (dateInverval) => axiosWalletRequest('Despesa/ExportarExcel', METHOD.POST, dateInverval);
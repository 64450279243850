/* eslint prefer-destructuring: 0 */
import React, { useState, useEffect } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import FormRow from '../formRow';
import * as saleService from '../../services/sale';
import { TotalCell, TotalTextCell } from '../grid/totalCell';
import NumberCell from '../grid/numberCell';
import LoadScreen from '../../view/load';
import GridInline from '../grid';
import './styles.css';

const Transaction = ({ invoiceId, setTransactionsIds, onItemDelete, deleteColumn }) => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const loadTransaction = async (invoiceId) => {
    if (invoiceId && invoiceId !== '0') {
      const { data } = await saleService.recoveryTransaction(invoiceId);
      const transactions = data.map((x) => ({
        ...x,
        Data: new Date(x.Data)
      }));
      setTransactions(transactions);
      if (setTransactionsIds) {
        const debitNoteIds = transactions.map((item) => item.Id);
        setTransactionsIds(debitNoteIds);
      }
      return transactions;
    }
    return [];
  };

  const onItemDeleteLocal = async () => {
    if (setTransactionsIds) {
      const debitNoteIds = transactions.map((item) => item.Id);
      setTransactionsIds(debitNoteIds);
    }
    if (onItemDelete) onItemDelete(transactions);
  };

  useEffect(async () => {
    setLoading(true);
    await loadTransaction(invoiceId);
    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <LoadScreen />
      ) : (
        <>
          <FormRow withShrink>
            <GridInline
              data={transactions}
              setData={setTransactions}
              deleteColumn={deleteColumn}
              onItemDelete={onItemDeleteLocal}
            >
              <GridColumn
                field="Data"
                title="Data"
                editable={false}
                format="{0:dd/MM/yyyy}"
                width={100}
              />
              <GridColumn field="NumeroDocumento" title="Documento" editable={false} width={100} />
              <GridColumn field="NumeroConciliacao" title="N�mero" editable={false} width={100} />
              <GridColumn field="Descricao" title="Descri��o" editable={false} />
              <GridColumn field="NomeCentroDeCustos" title="Centro de Custo" editable={false} />
              <GridColumn
                field="Valor"
                title="Valor (R$)"
                editable={false}
                editor="numeric"
                format="{0:n2}"
                footerCell={(props) => TotalCell(props, transactions, 'Valor')}
              />
            </GridInline>
          </FormRow>
        </>
      )}
    </div>
  );
};

export default Transaction;

import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Infos = ({ title, text, classNameTitle, classNameText }) => (
  <div className="text-modal">
    <span className={classNameTitle}>{title}</span>
    <span className={classNameText}>{text}</span>
  </div>
);

Infos.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  classNameTitle: PropTypes.string,
  classNameText: PropTypes.string
};

Infos.defaultProps = {
  classNameTitle: '',
  classNameText: ''
};

export default Infos;

export const PayRollItems = [
    {
        "Code": "1000",
        "Description": "Sal�rio, vencimento, soldo"
    },
    {
        "Code": "1001",
        "Description": "Subs�dio"
    },
    {
        "Code": "1002",
        "Description": "Descanso semanal remunerado - DSR"
    },
    {
        "Code": "1003",
        "Description": "Horas extraordin�rias"
    },
    {
        "Code": "1004",
        "Description": "Horas extraordin�rias - Indeniza��o de banco de horas"
    },
    {
        "Code": "1005",
        "Description": "Direito de arena"
    },
    {
        "Code": "1006",
        "Description": "Intervalos intra e inter jornadas n�o concedidos"
    },
    {
        "Code": "1007",
        "Description": "Luvas e premia��es"
    },
    {
        "Code": "1009",
        "Description": "Sal�rio-fam�lia - Complemento"
    },
    {
        "Code": "1010",
        "Description": "Sal�rio�in natura�- Pagos em bens ou servi�os"
    },
    {
        "Code": "1011",
        "Description": "Sobreaviso e prontid�o"
    },
    {
        "Code": "1020",
        "Description": "F�rias"
    },
    {
        "Code": "1021",
        "Description": "F�rias - Abono ou gratifica��o de f�rias superior a 20 dias"
    },
    {
        "Code": "1022",
        "Description": "F�rias - Abono ou gratifica��o de f�rias n�o excedente a 20 dias"
    },
    {
        "Code": "1023",
        "Description": "F�rias - Abono pecuni�rio"
    },
    {
        "Code": "1024",
        "Description": "F�rias - Dobro na vig�ncia do contrato"
    },
    {
        "Code": "1040",
        "Description": "Licen�a-pr�mio"
    },
    {
        "Code": "1041",
        "Description": "Licen�a-pr�mio indenizada"
    },
    {
        "Code": "1050",
        "Description": "Remunera��o de dias de afastamento"
    },
    {
        "Code": "1080",
        "Description": "Stock option"
    },
    {
        "Code": "1099",
        "Description": "Outras verbas salariais"
    },
    {
        "Code": "1201",
        "Description": "Adicional de fun��o / cargo confian�a"
    },
    {
        "Code": "1202",
        "Description": "Adicional de insalubridade"
    },
    {
        "Code": "1203",
        "Description": "Adicional de periculosidade"
    },
    {
        "Code": "1204",
        "Description": "Adicional de transfer�ncia"
    },
    {
        "Code": "1205",
        "Description": "Adicional noturno"
    },
    {
        "Code": "1206",
        "Description": "Adicional por tempo de servi�o"
    },
    {
        "Code": "1207",
        "Description": "Comiss�es, porcentagens, produ��o"
    },
    {
        "Code": "1208",
        "Description": "Gueltas ou gorjetas - Repassadas por fornecedores ou clientes"
    },
    {
        "Code": "1209",
        "Description": "Gueltas ou gorjetas - Repassadas pelo empregador"
    },
    {
        "Code": "1210",
        "Description": "Gratifica��o por acordo ou conven��o coletiva"
    },
    {
        "Code": "1211",
        "Description": "Gratifica��es"
    },
    {
        "Code": "1212",
        "Description": "Gratifica��es ou outras verbas de natureza permanente"
    },
    {
        "Code": "1213",
        "Description": "Gratifica��es ou outras verbas de natureza transit�ria"
    },
    {
        "Code": "1214",
        "Description": "Adicional de penosidade"
    },
    {
        "Code": "1215",
        "Description": "Adicional de unidoc�ncia"
    },
    {
        "Code": "1216",
        "Description": "Adicional de localidade"
    },
    {
        "Code": "1217",
        "Description": "Gratifica��o de curso/concurso"
    },
    {
        "Code": "1225",
        "Description": "Quebra de caixa"
    },
    {
        "Code": "1230",
        "Description": "Remunera��o do dirigente sindical"
    },
    {
        "Code": "1299",
        "Description": "Outros adicionais"
    },
    {
        "Code": "1300",
        "Description": "PLR - Participa��o em Lucros ou Resultados"
    },
    {
        "Code": "1350",
        "Description": "Bolsa de estudo - Estagi�rio"
    },
    {
        "Code": "1351",
        "Description": "Bolsa de estudo - M�dico residente"
    },
    {
        "Code": "1352",
        "Description": "Bolsa de estudo ou pesquisa"
    },
    {
        "Code": "1401",
        "Description": "Abono"
    },
    {
        "Code": "1402",
        "Description": "Abono PIS/PASEP"
    },
    {
        "Code": "1403",
        "Description": "Abono legal"
    },
    {
        "Code": "1404",
        "Description": "Aux�lio bab�"
    },
    {
        "Code": "1405",
        "Description": "Assist�ncia m�dica"
    },
    {
        "Code": "1406",
        "Description": "Aux�lio-creche"
    },
    {
        "Code": "1407",
        "Description": "Aux�lio-educa��o"
    },
    {
        "Code": "1409",
        "Description": "Sal�rio-fam�lia"
    },
    {
        "Code": "1410",
        "Description": "Aux�lio - Locais de dif�cil acesso"
    },
    {
        "Code": "1411",
        "Description": "Aux�lio-natalidade"
    },
    {
        "Code": "1412",
        "Description": "Abono perman�ncia"
    },
    {
        "Code": "1601",
        "Description": "Ajuda de custo - Aeronauta"
    },
    {
        "Code": "1602",
        "Description": "Ajuda de custo de transfer�ncia"
    },
    {
        "Code": "1603",
        "Description": "Ajuda de custo"
    },
    {
        "Code": "1604",
        "Description": "Ajuda de custo - Acima de 50% da remunera��o mensal"
    },
    {
        "Code": "1619",
        "Description": "Ajuda compensat�ria - Programa Emergencial de Manuten��o do Emprego e da Renda"
    },
    {
        "Code": "1620",
        "Description": "Ressarcimento de despesas pelo uso de ve�culo pr�prio"
    },
    {
        "Code": "1621",
        "Description": "Ressarcimento de despesas de viagem, exceto despesas com ve�culos"
    },
    {
        "Code": "1623",
        "Description": "Ressarcimento de provis�o"
    },
    {
        "Code": "1629",
        "Description": "Ressarcimento de outras despesas"
    },
    {
        "Code": "1650",
        "Description": "Di�rias de viagem"
    },
    {
        "Code": "1651",
        "Description": "Di�rias de viagem - At� 50% do sal�rio"
    },
    {
        "Code": "1652",
        "Description": "Di�rias de viagem - Acima de 50% do sal�rio"
    },
    {
        "Code": "1800",
        "Description": "Alimenta��o concedida em pec�nia"
    },
    {
        "Code": "1801",
        "Description": "Alimenta��o"
    },
    {
        "Code": "1802",
        "Description": "Etapas (mar�timos)"
    },
    {
        "Code": "1805",
        "Description": "Moradia"
    },
    {
        "Code": "1806",
        "Description": "Alimenta��o em ticket ou cart�o, vinculada ao PAT"
    },
    {
        "Code": "1807",
        "Description": "Alimenta��o em ticket ou cart�o, n�o vinculada ao PAT"
    },
    {
        "Code": "1808",
        "Description": "Cesta b�sica ou refei��o, vinculada ao PAT"
    },
    {
        "Code": "1809",
        "Description": "Cesta b�sica ou refei��o, n�o vinculada ao PAT"
    },
    {
        "Code": "1810",
        "Description": "Transporte"
    },
    {
        "Code": "1899",
        "Description": "Outros aux�lios"
    },
    {
        "Code": "1901",
        "Description": "Juros e/ou atualiza��o monet�ria"
    },
    {
        "Code": "2501",
        "Description": "Pr�mios"
    },
    {
        "Code": "2502",
        "Description": "Liberalidades concedidas em mais de duas parcelas anuais"
    },
    {
        "Code": "2510",
        "Description": "Direitos autorais e intelectuais"
    },
    {
        "Code": "2801",
        "Description": "Quarentena remunerada"
    },
    {
        "Code": "2901",
        "Description": "Empr�stimos"
    },
    {
        "Code": "2902",
        "Description": "Vestu�rio e equipamentos"
    },
    {
        "Code": "2903",
        "Description": "Vestu�rio e equipamentos"
    },
    {
        "Code": "2920",
        "Description": "Reembolsos diversos"
    },
    {
        "Code": "2930",
        "Description": "Insufici�ncia de saldo"
    },
    {
        "Code": "2999",
        "Description": "Arredondamentos"
    },
    {
        "Code": "3501",
        "Description": "Remunera��o por presta��o de servi�os"
    },
    {
        "Code": "3505",
        "Description": "Retiradas (pr�-labore) de diretores empregados"
    },
    {
        "Code": "3506",
        "Description": "Retiradas (pr�-labore) de diretores n�o empregados"
    },
    {
        "Code": "3508",
        "Description": "Retiradas (pr�-labore) de propriet�rios ou s�cios"
    },
    {
        "Code": "3509",
        "Description": "Honor�rios a conselheiros"
    },
    {
        "Code": "3510",
        "Description": "Gratifica��o (jeton)"
    },
    {
        "Code": "3511",
        "Description": "Gratifica��o eleitoral"
    },
    {
        "Code": "3520",
        "Description": "Remunera��o de cooperado"
    },
    {
        "Code": "3525",
        "Description": "C�ngruas, prebendas e afins"
    },
    {
        "Code": "4010",
        "Description": "Complementa��o salarial de aux�lio-doen�a"
    },
    {
        "Code": "4011",
        "Description": "Complemento de sal�rio-m�nimo - RPPS"
    },
    {
        "Code": "4050",
        "Description": "Sal�rio-maternidade"
    },
    {
        "Code": "4051",
        "Description": "Sal�rio-maternidade - 13� sal�rio"
    },
    {
        "Code": "5001",
        "Description": "13� sal�rio"
    },
    {
        "Code": "5005",
        "Description": "13� sal�rio complementar"
    },
    {
        "Code": "5501",
        "Description": "Adiantamento de sal�rio"
    },
    {
        "Code": "5504",
        "Description": "13� sal�rio - Adiantamento"
    },
    {
        "Code": "5510",
        "Description": "Adiantamento de benef�cios previdenci�rios"
    },
    {
        "Code": "6000",
        "Description": "Saldo de sal�rios na rescis�o contratual"
    },
    {
        "Code": "6001",
        "Description": "13� sal�rio relativo ao aviso pr�vio indenizado"
    },
    {
        "Code": "6002",
        "Description": "13� sal�rio proporcional na rescis�o"
    },
    {
        "Code": "6003",
        "Description": "Indeniza��o compensat�ria do aviso pr�vio"
    },
    {
        "Code": "6004",
        "Description": "F�rias - Dobro na rescis�o"
    },
    {
        "Code": "6006",
        "Description": "F�rias proporcionais"
    },
    {
        "Code": "6007",
        "Description": "F�rias vencidas na rescis�o"
    },
    {
        "Code": "6101",
        "Description": "Indeniza��o compensat�ria - Multa rescis�ria 20 ou 40% (CF/88)"
    },
    {
        "Code": "6102",
        "Description": "Indeniza��o do art. 9� da Lei 7.238/1984"
    },
    {
        "Code": "6103",
        "Description": "Indeniza��o do art. 14 da Lei 5.889/1973"
    },
    {
        "Code": "6104",
        "Description": "Indeniza��o do art. 479 da CLT"
    },
    {
        "Code": "6105",
        "Description": "Indeniza��o recebida a t�tulo de incentivo a demiss�o"
    },
    {
        "Code": "6106",
        "Description": "Multa do art. 477 da CLT"
    },
    {
        "Code": "6107",
        "Description": "Indeniza��o por quebra de estabilidade"
    },
    {
        "Code": "6108",
        "Description": "Tempo de espera do motorista profissional"
    },
    {
        "Code": "6119",
        "Description": "Indeniza��o rescis�ria - Programa Emergencial de Manuten��o do Emprego e da Renda"
    },
    {
        "Code": "6129",
        "Description": "Outras multas ou indeniza��es"
    },
    {
        "Code": "6901",
        "Description": "Desconto do aviso pr�vio"
    },
    {
        "Code": "6904",
        "Description": "Multa prevista no art. 480 da CLT"
    },
    {
        "Code": "7001",
        "Description": "Proventos"
    },
    {
        "Code": "7002",
        "Description": "Proventos - Pens�o por morte Civil"
    },
    {
        "Code": "7003",
        "Description": "Proventos - Reserva"
    },
    {
        "Code": "7004",
        "Description": "Proventos - Reforma"
    },
    {
        "Code": "7005",
        "Description": "Pens�o Militar"
    },
    {
        "Code": "7006",
        "Description": "Aux�lio-reclus�o"
    },
    {
        "Code": "7007",
        "Description": "Pens�es especiais"
    },
    {
        "Code": "7008",
        "Description": "Complementa��o de aposentadoria/ pens�o"
    },
    {
        "Code": "9200",
        "Description": "Desconto de adiantamentos"
    },
    {
        "Code": "9201",
        "Description": "Contribui��o previdenci�ria"
    },
    {
        "Code": "9202",
        "Description": "Contribui��o militar"
    },
    {
        "Code": "9203",
        "Description": "Imposto de Renda Retido na Fonte"
    },
    {
        "Code": "9205",
        "Description": "Provis�o de contribui��o previdenci�ria"
    },
    {
        "Code": "9207",
        "Description": "Faltas"
    },
    {
        "Code": "9208",
        "Description": "Atrasos"
    },
    {
        "Code": "9209",
        "Description": "Faltas ou atrasos"
    },
    {
        "Code": "9210",
        "Description": "DSR s/faltas e atrasos"
    },
    {
        "Code": "9211",
        "Description": "DSR sobre faltas"
    },
    {
        "Code": "9212",
        "Description": "DSR sobre atrasos"
    },
    {
        "Code": "9213",
        "Description": "Pens�o aliment�cia"
    },
    {
        "Code": "9214",
        "Description": "13� sal�rio - Desconto de adiantamento"
    },
    {
        "Code": "9216",
        "Description": "Desconto de vale-transporte"
    },
    {
        "Code": "9217",
        "Description": "Contribui��o a Outras Entidades e Fundos"
    },
    {
        "Code": "9218",
        "Description": "Reten��es judiciais"
    },
    {
        "Code": "9219",
        "Description": "Desconto de assist�ncia m�dica ou odontol�gica"
    },
    {
        "Code": "9220",
        "Description": "Alimenta��o - Desconto"
    },
    {
        "Code": "9221",
        "Description": "Desconto de f�rias"
    },
    {
        "Code": "9222",
        "Description": "Desconto de outros impostos e contribui��es"
    },
    {
        "Code": "9223",
        "Description": "Previd�ncia complementar - Parte do empregado"
    },
    {
        "Code": "9224",
        "Description": "FAPI - Parte do empregado"
    },
    {
        "Code": "9225",
        "Description": "Previd�ncia complementar - Parte do servidor"
    },
    {
        "Code": "9226",
        "Description": "Desconto de f�rias - Abono"
    },
    {
        "Code": "9230",
        "Description": "Contribui��o sindical laboral"
    },
    {
        "Code": "9231",
        "Description": "Mensalidade sindical ou associativa"
    },
    {
        "Code": "9232",
        "Description": "Contribui��o sindical - Assistencial"
    },
    {
        "Code": "9233",
        "Description": "Contribui��o sindical - Confederativa"
    },
    {
        "Code": "9240",
        "Description": "Alimenta��o concedida em pec�nia - Desconto"
    },
    {
        "Code": "9241",
        "Description": "Alimenta��o em ticket ou cart�o, vinculada ao PAT - Desconto"
    },
    {
        "Code": "9242",
        "Description": "Alimenta��o em ticket ou cart�o, n�o vinculada ao PAT - Desconto"
    },
    {
        "Code": "9243",
        "Description": "Cesta b�sica ou refei��o, vinculada ao PAT - Desconto"
    },
    {
        "Code": "9244",
        "Description": "Cesta b�sica ou refei��o, n�o vinculada ao PAT - Desconto"
    },
    {
        "Code": "9250",
        "Description": "Seguro de vida - Desconto"
    },
    {
        "Code": "9254",
        "Description": "Empr�stimos consignados - Desconto"
    },
    {
        "Code": "9255",
        "Description": "Empr�stimos do empregador - Desconto"
    },
    {
        "Code": "9258",
        "Description": "Conv�nios"
    },
    {
        "Code": "9260",
        "Description": "FIES - Desconto"
    },
    {
        "Code": "9270",
        "Description": "Danos e preju�zos causados pelo trabalhador"
    },
    {
        "Code": "9290",
        "Description": "Desconto de pagamento indevido em meses anteriores"
    },
    {
        "Code": "9291",
        "Description": "Abate-teto"
    },
    {
        "Code": "9292",
        "Description": "Ressarcimento ao er�rio"
    },
    {
        "Code": "9293",
        "Description": "Honor�rios advocat�cios"
    },
    {
        "Code": "9294",
        "Description": "Redutor EC 41/03"
    },
    {
        "Code": "9299",
        "Description": "Outros descontos"
    },
    {
        "Code": "9901",
        "Description": "Base de c�lculo da contribui��o previdenci�ria"
    },
    {
        "Code": "9902",
        "Description": "Total da base de c�lculo do FGTS"
    },
    {
        "Code": "9903",
        "Description": "Total da base de c�lculo do IRRF"
    },
    {
        "Code": "9904",
        "Description": "Total da base de c�lculo do FGTS rescis�rio"
    },
    {
        "Code": "9905",
        "Description": "Servi�o militar"
    },
    {
        "Code": "9906",
        "Description": "Remunera��o no exterior"
    },
    {
        "Code": "9907",
        "Description": "Total da contribui��o da previdenci�ria patronal - RPPS"
    },
    {
        "Code": "9908",
        "Description": "FGTS - Dep�sito"
    },
    {
        "Code": "9910",
        "Description": "Seguros"
    },
    {
        "Code": "9911",
        "Description": "Assist�ncia M�dica"
    },
    {
        "Code": "9930",
        "Description": "Sal�rio-maternidade pago pela Previd�ncia Social"
    },
    {
        "Code": "9931",
        "Description": "Sal�rio-maternidade pago pela Previd�ncia Social - 13� sal�rio"
    },
    {
        "Code": "9932",
        "Description": "Aux�lio-doen�a acident�rio"
    },
    {
        "Code": "9933",
        "Description": "Aux�lio-doen�a"
    },
    {
        "Code": "9938",
        "Description": "Isen��o IRRF - 65 anos"
    },
    {
        "Code": "9939",
        "Description": "Outros valores tribut�veis"
    },
    {
        "Code": "9950",
        "Description": "Horas extraordin�rias - Banco de horas"
    },
    {
        "Code": "9951",
        "Description": "Horas compensadas - Banco de horas"
    },
    {
        "Code": "9989",
        "Description": "Outros valores informativos"
    }
].sort((a, b) => a.Description - b.Description);
import { isEmptyValue } from './string';

export const getFinalValues = (obj) => {
  let finalObj = [];

  Object.values(obj).forEach(value => {
    if (typeof value === 'object') value = getFinalValues(value);
    else value = [value];

    finalObj = finalObj.concat(value);
  });

  return finalObj;
}

export const filterEmptyValues = (obj) => Object.fromEntries(
  Object.entries(obj).filter(current => !isEmptyValue(current[1]))
);
import React from 'react';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import Label from '../components/label';
import '../style.css';
import './styles.css';

const FormFieldAutoComplete = ({
  titleLabel,
  data,
  defaultValue,
  classNameWrapper,
  required,
  ...others
}) => (
  <div className={`auto-complete-field-wrapper ${classNameWrapper}`}>
    <Label> {required ? `${titleLabel}*` : `${titleLabel}`} </Label>
    <div className="wrapper-input">
      <AutoComplete
        className="auto-complete-list-wrapper"
        data={data}
        defaultValue={defaultValue}
        required={required}
        validationMessage="Preencha este campo."
        {...others}
      />
    </div>
  </div>
);

export default FormFieldAutoComplete;

import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

const TransparentCard = ({ title, content, className }) => (
  <div className={className || 'transparentCardContainer'}>
    <span className="titleContainer">{title}</span>
    <span className="contentContainer">{content}</span>
  </div>
);

TransparentCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

export default TransparentCard;

import { Input, TextArea, MaskedTextBox } from '@progress/kendo-react-inputs';
import PropTypes from 'prop-types';
import React from 'react';
import { getFinalValues } from '../../../../utils/object';
import Information from '../../components/information';
import '../../style.css';
import './style.css';
import MoneyInput from './components/moneyInput';
import PercentageInput from './components/percentageInput';
import NumberInput from './components/numberInput';

export const ICON = {
  PERCENTAGE_ICON: 'percentage',
  REAIS_ICON: 'reais',
  CALENDAR: 'calendar'
};

const InputField = ({
  classNameInput,
  onChangeValue,
  disabled,
  enableInfo,
  isTextArea,
  msg,
  valueInput,
  iconEnabled,
  required,
  setValue,
  typeNumber,
  mask,
  ...others
}) => {
  valueInput = valueInput || '';
  const commonProps = {
    value: valueInput,
    disabled,
    required,
    ...others
  };

  const alignRight = `${iconEnabled === ICON.PERCENTAGE_ICON && 'align-right'}`;
  const className = `${classNameInput} formField k-textbox ${alignRight}`;
  let inputComponent = <Input onChange={onChangeValue} className={className} {...commonProps} />;

  if (isTextArea)
    inputComponent = (
      <TextArea
        rows={commonProps.rows !== null ? commonProps.rows : 4}
        onChange={onChangeValue}
        className={`${classNameInput} formField`}
        {...commonProps}
      />
    );
  else if (iconEnabled === ICON.REAIS_ICON)
    inputComponent = (
      <MoneyInput
        setValue={setValue}
        className={`${classNameInput} formField k-textbox align-right`}
        {...commonProps}
      />
    );
  else if (mask)
    inputComponent = (
      <MaskedTextBox
        className={`${classNameInput} formField k-textbox`}
        mask={mask}
        defaultValue={valueInput}
        onChange={onChangeValue}
        {...commonProps}
      />
    );
  else if (iconEnabled === ICON.PERCENTAGE_ICON) {
    inputComponent = (
      <NumberInput
        onChange={onChangeValue}
        className={`${classNameInput} formField k-textbox align-right`}
        {...commonProps}
      />
    );
  } else if (typeNumber) {
    inputComponent = (
      <NumberInput
        onChange={onChangeValue}
        className={`${classNameInput} formField k-textbox align-right`}
        {...commonProps}
      />
    );
  }

  return (
    <div className="input-field">
      {inputComponent}

      {iconEnabled === ICON.REAIS_ICON && <span className="external-icon money-icon">R$</span>}

      {enableInfo && (
        <div className="icon-info">
          <Information msg={msg} />
        </div>
      )}

      {iconEnabled === ICON.PERCENTAGE_ICON && <span className="external-icon">%</span>}

      {iconEnabled === ICON.CALENDAR && <span className="k-icon k-i-calendar internal-icon" />}
    </div>
  );
};

InputField.propTypes = {
  onChangeValue: PropTypes.func,
  disabled: PropTypes.bool,
  classNameInput: PropTypes.string,
  iconEnabled: PropTypes.oneOf(getFinalValues(ICON)),
  isTextArea: PropTypes.bool,
  msg: PropTypes.string,
  enableInfo: PropTypes.bool,
  valueInput: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  setValue: PropTypes.func,
  typeNumber: PropTypes.bool,
  mask: PropTypes.string
};

InputField.defaultProps = {
  onChangeValue: undefined,
  disabled: false,
  enableInfo: false,
  isTextArea: false,
  classNameInput: '',
  msg: '',
  iconEnabled: null,
  valueInput: '',
  required: false,
  setValue: () => {
    // do nothing
  },
  typeNumber: false,
  mask: null
};

export default InputField;

import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../auth/AuthContext';
import { useForms } from '../../../hooks';
import FormRow from '../../formRow';
import GenericField from '../../formField/genericField';
import FormFieldDrop from '../../formField/drop';
import Popup from '../layout';
import * as cardService from '../../../services/card';
import { ICON } from '../../formField/genericField/input';
import { successNotification, errorNotification, warningNotification } from '../../notification';
import ToggleInvoice from '../../toggleInvoice';

const initialForm = {
  Operadora: null,
  TaxaDebito: null,
  DiaDebito: null,
  TaxaCredito: null,
  DiaCredito: null,
  ContaPagamento: null
};

const PopUpCardTax = ({ addCardTax, visible, setVisible, accounts, cardTax }) => {
  const history = useHistory();
  const [form, updateProperty, setForm] = useForms(initialForm);
  const { walletSelected, setWalletSelected } = useContext(AuthContext);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(async () => {
    if (cardTax) {
      setForm({
        Id: cardTax.Id,
        Operadora: cardTax.Operadora,
        TaxaDebito: cardTax.TaxaDebito,
        DiaDebito: cardTax.DiaDebito,
        TaxaCredito: cardTax.TaxaCredito,
        DiaCredito: cardTax.DiaCredito,
        ContaPagamento: cardTax.ContaPagamento
      });
      setIsEdit(true);
    } else {
      setForm(initialForm);
      setIsEdit(false);
    }
  }, [cardTax]);

  const confirmAddCardTax = async () => {
    if (form.Operadora === null || form.Operadora.length === 0) {
      throw Error('O campo estabelecimento/operadora/bandeira � obrigat�rio.');
    } else if (form.TaxaDebito < 0) {
      throw Error('O campo taxa de d�bito deve ser maior ou igual 0.');
    } else if (form.DiaDebito < 0) {
      throw Error('O campo dia de d�bito n�o pode ser negativo.');
    } else if (form.TaxaCredito <= 0) {
      throw Error('O campo taxa de cr�dito deve ser maior 0.');
    } else if (form.DiaCredito <= 0) {
      throw Error('O campo dia de d�bito deve ser maior 0.');
    }
    const bodyForm = {
      ...form,
      ContaId: form.ContaPagamento?.Id
    };
    let response;
    if (cardTax) response = await cardService.update(bodyForm.Id, bodyForm);
    else response = await cardService.create(walletSelected.CarteiraBusiness.Id, bodyForm);
    if (response.status === 200) {
      successNotification('Taxas de cart�o criadas/atualizadas com sucesso!');
      addCardTax();
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar criar/atualizar as taxas de cart�o.');
    }
  };

  const excludeCardTax = async () => {
    const response = await cardService.exclude(form.Id);
    if (response.status === 200) {
      successNotification('Taxas de cart�o exclu�das com sucesso!');
      addCardTax();
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) warningNotification(data.MensagemUsuario);
      else errorNotification('Houve um erro ao tentar excluir a taxa de cart�o.');
    }
  };

  const title = cardTax ? 'Atualizar Taxa de Cart�o' : 'Criar Taxa de Cart�o';

  return (
    <Popup
      title={title}
      width={650}
      visible={visible}
      setVisible={setVisible}
      confirmText="Salvar"
      cancelText="Cancelar"
      onConfirm={confirmAddCardTax}
      onDelete={excludeCardTax}
      haveExclude={isEdit}
    >
      <FormRow justifyEnd>
        <GenericField
          titleLabel="Estabelecimento/Operadora/Bandeira"
          classNameWrapper="fill-100-field"
          valueInput={form.Operadora}
          onChangeValue={updateProperty}
          name="Operadora"
          required
        />
      </FormRow>
      <FormRow withShrink>
        <FormFieldDrop
          titleLabel="Local de Recebimento"
          defaultValueSelect="Selecione..."
          value={form.ContaPagamento}
          onChangeValue={updateProperty}
          name="ContaPagamento"
          className="fill-100-field"
          infosSelect={accounts}
          dataItemKey="Id"
          textField="Nome"
        />
      </FormRow>
      <ToggleInvoice title="Venda com Cart�o de D�bito" startExpanded>
        <FormRow justifyEnd>
          <GenericField
            titleLabel="Taxa para Venda � Vista"
            iconEnabled={ICON.PERCENTAGE_ICON}
            classNameWrapper="fill-50-field"
            valueInput={form.TaxaDebito}
            onChangeValue={(val) => {
              if (val) updateProperty(val);
            }}
            name="TaxaDebito"
            step=".01"
          />
          <GenericField
            titleLabel="Prazo de Recebimento em Dias"
            classNameWrapper="fill-50-field"
            valueInput={form.DiaDebito}
            onChangeValue={(val) => {
              if (val) updateProperty(val);
            }}
            name="DiaDebito"
            typeNumber
            min="0"
          />
        </FormRow>
      </ToggleInvoice>
      <ToggleInvoice title="Venda com Cart�o de Cr�dito" startExpanded>
        <FormRow justifyEnd>
          <GenericField
            titleLabel="Taxa para Venda � Vista"
            iconEnabled={ICON.PERCENTAGE_ICON}
            classNameWrapper="fill-50-field"
            valueInput={form.TaxaCredito}
            onChangeValue={updateProperty}
            name="TaxaCredito"
            required
          />
          <GenericField
            titleLabel="Prazo de Recebimento em Dias"
            classNameWrapper="fill-50-field"
            valueInput={form.DiaCredito}
            onChangeValue={updateProperty}
            name="DiaCredito"
            required
            typeNumber
            min="0"
          />
        </FormRow>
      </ToggleInvoice>
    </Popup>
  );
};

export default PopUpCardTax;

import * as React from 'react';
import { GridColumnMenuFilter, GridColumnMenuCheckboxFilter } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';

export const ColumnMenu = (props) => (
  <div>
    <GridColumnMenuFilter {...props} expanded={true} />
  </div>
);
export const ColumnMenuCheckbox = (props) => (
  <div>
    <GridColumnMenuCheckboxFilter {...props} expanded={true} />
  </div>
);

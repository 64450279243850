import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getAll = () => axiosWalletRequest('Documento/', METHOD.GET);

export const getByDate = (dateInverval) => axiosWalletRequest('Documento/Filtrar', METHOD.POST, dateInverval);

export const getById = (id) => axiosWalletRequest(`Documento/${id}`, METHOD.GET);

export const create = (body) => axiosWalletRequest('Documento', METHOD.POST, body);

export const exclude = (id) => axiosWalletRequest(`Documento/${id}`, METHOD.DELETE);

export const update = (id, body) => axiosWalletRequest(`Documento/${id}`, METHOD.PUT, body);

export const getInvoiceTypes = () => axiosWalletRequest(`Documento/ListarTipoDocumento`, METHOD.GET);

export const updateLogo = (binaryFile, type, id) => {
    return axiosWalletRequest(`/Documento/Logo/${id}`, METHOD.POST, binaryFile, {
        headers: {
            'Content-Type': type
        }
    });
}

export const excludeLogo = (id) => axiosWalletRequest(`Documento/ExcluirLogo/${id}`, METHOD.PUT);
export const recoveryLogo = (id) => axiosWalletRequest(`Documento/RecuperarLogo/${id}`, METHOD.GET);

import { useState } from 'react';

const useForms = (initialState) => {
  const [forms, setForms] = useState(initialState || {});

  const updateProperty = (key, value) => {
    setForms({
      ...forms,
      [key]: value
    });
  };

  const updateFormValue = (e) => {
    const { target: { name, value } } = e;
    updateProperty(name, value);
  };

  return [forms, updateFormValue, setForms, updateProperty];
};

export default useForms;

import './style.css';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Upload } from '@progress/kendo-react-upload';
import TransparentCard from '../../transparentCard';
import { updateCertificate } from '../../../services/businessWallet';
import { errorNotification, successNotification } from '../../notification';
import Popup from '../layout';
import LoadScreen from '../../../view/load';
import { axiosWalletRequest } from '../../../api/Requests';

const uploadRef = React.createRef();

const PopUpCertificateForms = ({ addCertificate, visible, setVisible, setImported }) => {
  const [fileSource, setFileSource] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  const confirmAddCertificate = async () => {
    const response = await updateCertificate(fileSource.getRawFile());
    if (response.status === 200) {
      addCertificate({ nome: fileSource.name, file: fileSource });
      setImported(true);
      successNotification('Certificado importado com sucesso!');
    } else {
      const { data } = response;
      if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
      else errorNotification(data);
    }
    setFileSource(null);
    setMessage(null);
  };

  return (
    <>
      <Popup
        width={520}
        title="Vincular Certificado Digital"
        visible={visible}
        setVisible={setVisible}
        cancelText="Cancelar"
        confirmText="Enviar Certificado"
        // FIXME retirar esse bloqueio do eslint ou entender o que ele est� fazendo
        // eslint-disable-next-line react/jsx-no-bind
        onConfirm={(event) => {
          if (fileSource == null) {
            throw Error('Arquivo n�o selecionado.');
          } else {
            confirmAddCertificate();
          }
        }}
      >
        <Upload
          ref={uploadRef}
          autoUpload={true}
          restrictions={{ allowedExtensions: ['.pfx'] }}
          onAdd={(event) => {
            setFileSource(event.newState[0]);
            setMessage(event.newState[0].name);
            event.affectedFiles.forEach((file) => {
              if (file.validationErrors?.length > 0) {
                if (file.validationErrors[0] === 'invalidFileExtension') {
                  setFileSource(null);
                  setMessage(
                    'Extens�o do arquivo � inv�lida. Somente arquivos .pfx s�o permitidos.'
                  );
                }
              }
            });
          }}
        />
        <TransparentCard
          className="selected-file-phrase"
          content={message != null ? message : 'Nenhum arquivo selecionado.'}
        />
      </Popup>
    </>
  );
};

PopUpCertificateForms.propTypes = {
  addCertificate: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpCertificateForms;

import { Avatar } from '@progress/kendo-react-layout';
import { useState } from 'react';
import PropTypes from 'prop-types';
import CloudIcon from '../../assets/icone-nuvem-selecionar.svg';
import GrayTrash from '../../assets/icone-lixeira-cinza.svg';
import WhiteTrash from '../../assets/icone-lixeira-branco.svg';
import './style.css';
import { OrangeButton, WhiteButton } from '../buttonsComponent';
import { getFileData } from '../../utils/file';
import { PopupConfirmResetAvatar } from '../popUps';

const Logo = ({
  removeAvatar,
  backgroundColor,
  textAvatar,
  className,
  imageSrc,
  imageAlt,
  setImageData,
  messageRemoveAvatar,
  height = 100,
  width = 270
}) => {
  const [isRemoveHover, setIsRemoveHover] = useState(false);
  const [imageSource, setImageSource] = useState(imageSrc);
  const [popUpRemoveAvatarVisible, setPopUpRemoveAvatarVisible] = useState(false);
  const [size, setSize] = useState({ height, width });

  const onChangeFile = async (e) => {
    const fileComplete = await getFileData(e);

    setImageSource(fileComplete.url);

    setImageData(fileComplete);
  };

  const handleRemove = () => {
    removeAvatar();
    setImageSource(null);
    setImageData(null);
  };

  const adjustSize = (props) => {
    const { naturalHeight } = props.nativeEvent.target;
    const { naturalWidth } = props.nativeEvent.target;
    if (naturalWidth > naturalHeight) {
      const rate = width / naturalWidth;
      const newHeight = rate * naturalHeight;
      if (newHeight > height) {
        setSize({ height: `${height}px`, width: 'auto' });
      } else {
        setSize({ height: 'auto', width: `${width}px` });
      }
    } else {
      const rate = height / naturalHeight;
      const newWidth = rate * naturalWidth;
      if (newWidth > width) {
        setSize({ height: 'auto', width: `${width}px` });
      } else {
        setSize({ height: `${height}px`, width: 'auto' });
      }
    }
  };

  return (
    <div className={`logo ${className}`}>
      <Avatar
        type="text"
        shape="square"
        style={{
          backgroundColor: 'white',
          height,
          width
        }}
      >
        {imageSource && imageAlt ? (
          <img src={imageSource} alt={imageAlt} style={size} onLoad={adjustSize} />
        ) : (
          textAvatar
        )}
      </Avatar>
      <div className="text-logo">
        <div className="buttons" style={{ height: '33.5px' }}>
          <OrangeButton onChange={onChangeFile} type="file">
            <img src={CloudIcon} alt="Nuvem com seta pra cima" />
            Selecionar
          </OrangeButton>

          <WhiteButton
            onClick={() => setPopUpRemoveAvatarVisible(true)}
            onMouseEnter={() => setIsRemoveHover(true)}
            onMouseLeave={() => setIsRemoveHover(false)}
            className="remove-button"
          >
            <img src={isRemoveHover ? WhiteTrash : GrayTrash} alt="Lixeira" />
            Remover
          </WhiteButton>
        </div>
      </div>

      <PopupConfirmResetAvatar
        visible={popUpRemoveAvatarVisible}
        setVisible={setPopUpRemoveAvatarVisible}
        resetAvatar={handleRemove}
        messageResetAvatar={messageRemoveAvatar}
      />
    </div>
  );
};

Logo.propTypes = {
  setImageData: PropTypes.func.isRequired,
  removeAvatar: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textAvatar: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string
};

Logo.defaultProps = {
  className: '',
  imageSrc: null,
  imageAlt: null
};

export default Logo;

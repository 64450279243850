import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import FormFieldDrop from '../../formField/drop';
import FormFieldDate from '../../formField/date';
import GenericField, { INTERNAL_ICON } from '../../formField/genericField';
import FormRow from '../../formRow';
import Popup from '../layout';
import { DateNow } from '../../../utils/date';
import * as serviceService from '../../../services/service';
import LoadScreen from '../../../view/load';

const PopUpAddService = ({ addService, visible, setVisible, isAdditional = false }) => {
  const [service, setService] = useState(null);
  const [selectValue, setSelectValue] = useState(0);
  const [amount, setAmount] = useState(0);
  const [referenceDate, setReferenceDate] = useState(DateNow());
  const [unit, setUnit] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const changeServiceHandler = ({ target: { value } }) => {
    setService(value);
    setSelectValue(value?.Valor);
    setAmount(1);
    setUnit(value?.UnidadeMedida_Nome);
  };

  const confirmAddService = () => {
    if (service == null) {
      throw Error('O campo nome do servi�o � obrigat�rio.');
    } else if (amount <= 0) {
      throw Error('O campo quantidade deve ser maior 0.');
    } else {
      let item = {
        ...service,
        Quantidade: amount,
        Desconto: 0,
        ValorServico: selectValue,
        ValorTotal: amount * selectValue
      };
      if (isAdditional) {
        item = {
          ...item,
          DataReferencia: referenceDate
        };
      }
      addService(item);
      setService(null);
      setSelectValue(0);
      setAmount(0);
    }
  };

  const loadServices = async () => {
    const { data } = await serviceService.getAll();
    setServices(data);
    return data;
  };

  useEffect(async () => {
    if (visible) {
      setLoading(true);
      await loadServices();
      setLoading(false);
    }
  }, [visible]);

  return (
    <Popup
      title="Adicionar Servi�o"
      width={500}
      visible={visible}
      setVisible={setVisible}
      confirmText="Adicionar"
      cancelText="Cancelar"
      onConfirm={confirmAddService}
    >
      {loading ? (
        <LoadScreen />
      ) : (
        <>
          {isAdditional && (
            <FormRow>
              <FormFieldDate
                titleLabel="Data de Refer�ncia"
                value={referenceDate}
                valueOnChange={setReferenceDate}
                className="fill-100-field"
                calendarIsMonth
                required
              />
            </FormRow>
          )}
          <FormRow>
            <FormFieldDrop
              titleLabel="C�digo do servi�o"
              defaultValueSelect="Selecione..."
              infosSelect={services}
              className="fill-100-field"
              value={service}
              onChangeValue={changeServiceHandler}
              dataItemKey="Id"
              textField="Codigo"
              required
            />
          </FormRow>
          <FormRow>
            <FormFieldDrop
              titleLabel="Nome do servi�o"
              defaultValueSelect="Selecione..."
              infosSelect={services}
              className="fill-100-field"
              value={service}
              onChangeValue={changeServiceHandler}
              dataItemKey="Id"
              textField="Descricao"
              required
            />
          </FormRow>
          <FormRow justifyEnd>
            <GenericField
              titleLabel="Quantidade"
              classNameWrapper="fill-100-field"
              valueInput={amount}
              onChangeValue={(e) => {
                const {
                  target: { name, valueAsNumber }
                } = e;
                setAmount(valueAsNumber);
              }}
              name="amount"
              required
              typeNumber
              min="0"
            />
          </FormRow>
          <FormRow justifyEnd>
            <GenericField
              titleLabel="Unidade de Medida"
              classNameWrapper="fill-100-field"
              classNameInput="align-right"
              valueInput={unit}
              disabled
            />
          </FormRow>
          <FormRow justifyEnd>
            <GenericField
              titleLabel="Valor"
              enableReais
              classNameWrapper="fill-100-field"
              valueInput={selectValue}
              setValue={setSelectValue}
              iconEnabled={INTERNAL_ICON.REAIS_ICON}
              required
              min="0"
            />
          </FormRow>
        </>
      )}
    </Popup>
  );
};

PopUpAddService.propTypes = {
  addService: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

PopUpAddService.defaultProps = {};

export default PopUpAddService;

import { toast } from 'react-toastify';
import CustomContent from './customContent';

const notify = ({
  title,
  content,
  notificationType
}, options) => {
  const notificationsTypeAllowed = ['success', 'error', 'warning', 'info'];

  if (!notificationsTypeAllowed.includes(notificationType)) throw new Error("notificationType not allowed!");
  toast[notificationType](<CustomContent content={content} title={title} />, options);
}

export const successNotification = (content = "", options) => {
  if (options == null)
    options = { autoClose: true, closeOnClick: true, closeButton: true };
  notify({
    title: "Sucesso!",
    content,
    notificationType: "success"
  }, options)
};

export const errorNotification = (content = "", options) => {
  if (options == null)
    options = { autoClose: false, closeOnClick: true, closeButton: true };
  notify({
    title: "Erro!",
    content,
    notificationType: "error"
  }, options);
}

export const defaultErrorNotification = (options) => {
  if (options == null)
    options = { autoClose: false, closeOnClick: true, closeButton: true };
  notify({
    title: "Erro!",
    content: "Houve um erro inesperado, tente novamente mais tarde",
    notificationType: "error"
  }, options)
};

export const warningNotification = (content = "", options) => {
  if (options == null)
    options = { autoClose: false, closeOnClick: true, closeButton: true };
  notify({
    title: "Aten��o!",
    content,
    notificationType: "warning"
  }, options)
};

export default notify;

import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../components/button';
import FormRow from '../../components/formRow';
import Table from '../../components/table';
import PopUpConfirm from '../../components/popUps/popUpConfirm';
import PopUpError from '../../components/popUps/popUpError';
import { successNotification, warningNotification } from '../../components/notification';
import { getAll, exclude } from '../../services/service';
import LoadScreen from '../load';
import './styles.css';

const Services = () => {
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [servicesSelectedsIds, setServicesSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popUpConfirmVisibility, setPopUpConfirmVisibility] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const onClickInRow = ({ Id }) => history.push(`services/editService/${Id}`);

  const columns = [
    {
      field: 'Codigo',
      title: 'C�digo',
      columnMenuCheckbox: true
    },
    {
      field: 'Descricao',
      title: 'Descri��o',
      columnMenuCheckbox: true
    },
    {
      field: 'ListaServicos',
      title: 'Lista de Servi�os',
      cell: (props) => {
        const cellClickHandlerProps = {
          onClick: () => onClickInRow(props.dataItem)
        };
        return (
          <td {...cellClickHandlerProps} style={{ textAlign: 'right' }}>
            {props.dataItem.ListaServicos}
          </td>
        );
      },
      columnMenuCheckbox: true
    },
    {
      field: 'UnidadeMedida',
      title: 'Unidade de Medida',
      cell: (props) => {
        const cellClickHandlerProps = {
          onClick: () => onClickInRow(props.dataItem)
        };
        return (
          <td {...cellClickHandlerProps} style={{ textAlign: 'right' }}>
            {props.dataItem.UnidadeMedida_Nome}
          </td>
        );
      },
      columnMenuCheckbox: true
    },
    {
      field: 'Valor',
      title: 'Valor (R$)',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}',
      columnMenu: true
    }
  ];

  const fetchServices = useCallback(async () => {
    setLoading(true);
    const { data } = await getAll();
    setServices(data);
    setLoading(false);
  }, []);

  useEffect(async () => {
    await fetchServices();
  }, []);

  const excludeServices = async () => {
    const excludeServicesRequests = servicesSelectedsIds.map((serviceSelected) =>
      exclude(serviceSelected)
    );

    const excludeServicesResponses = await Promise.all(excludeServicesRequests);

    const isAllRequestsRight = excludeServicesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    if (isAllRequestsRight) successNotification('Servi�o(s) exclu�do(s) com sucesso!');
    else {
      const erro = ['Houve um erro ao tentar excluir um ou mais servi�os:'];
      excludeServicesResponses.forEach((element) => {
        const { data } = element;
        erro.push(data.MensagemUsuario);
      });
      setPopUpErrorMessage(erro);
      setPopUpError(true);
    }

    setServicesSelectedsIds([]);
    await fetchServices();
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="service-list">
        <h1>Servi�os</h1>

        <FormRow justifyEnd withoutMarginTop space10>
          <PopUpButton
            url="/services/serviceRegistration"
            internalButton={INTERNAL_BUTTON.BUTTON_ADD}
          />
          <PopUpButton
            internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS}
            internalItems={[
              {
                id: INTERNAL_ITEMS.EXCLUIR,
                onClick: () => {
                  if (servicesSelectedsIds.length > 0) setPopUpConfirmVisibility(true);
                  else warningNotification('Nenhum servi�o selecionado');
                },
                text: 'Excluir servi�os'
              }
            ]}
          />
        </FormRow>

        <Table
          name="Services"
          data={services}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setServicesSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          onClickInRow={({ Id }) => history.push(`services/editService/${Id}`)}
          sortable={true}
        />
      </div>
      <PopUpConfirm
        onConfirm={excludeServices}
        visible={popUpConfirmVisibility}
        setVisible={setPopUpConfirmVisibility}
        title="Confirma��o"
        message="Voc� tem certeza que deseja excluir o(s) servi�o(s) selecionado(s)?"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title="Erro ao excluir um servi�o"
        message={popUpErrorMessage}
      />
    </>
  );
};

export default Services;

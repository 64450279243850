import React, { useContext, useEffect, useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { useHistory, useParams } from 'react-router-dom';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import FormFieldDate from '../../../components/formField/date';
import FormFieldDrop from '../../../components/formField/drop';
import FormFieldCheckbox from '../../../components/formField/checkbox';
import GenericField, { INTERNAL_ICON } from '../../../components/formField/genericField';
import FormRow from '../../../components/formRow';
import ToggleInvoice from '../../../components/toggleInvoice';
import { useForms } from '../../../hooks';
import * as saleService from '../../../services/sale';
import * as personService from '../../../services/person';
import * as transactionService from '../../../services/transaction';
import * as planAccountService from '../../../services/planAccount';
import * as centerCostService from '../../../services/centerCost';
import * as accountService from '../../../services/account';
import * as taxClassService from '../../../services/taxClass';
import * as addressService from '../../../services/address';
import * as serviceService from '../../../services/service';
import * as invoiceService from '../../../services/invoice';
import * as businessWalletService from '../../../services/businessWallet';
import * as cardService from '../../../services/card';
import * as documentService from '../../../services/document';
import LoadScreen from '../../load';
import {
  successNotification,
  errorNotification,
  warningNotification
} from '../../../components/notification';
import PopupAddService from '../../../components/popUps/popUpAddService';
import PopupAddClassification from '../../../components/popUps/popUpAddClassification';
import PopupDivideClassification from '../../../components/popUps/popUpDivideClassification';
import PopUpViewer from '../../../components/popUps/popUpViewer';
import PopUpConfirm from '../../../components/popUps/popUpConfirm';
import GridInline from '../../../components/grid';
import DropDownCell from '../../../components/grid/dropDownCell';
import DateCell from '../../../components/grid/dateCell';
import { TotalCell, TotalValueCell, TotalTextCell } from '../../../components/grid/totalCell';
import GridRowSpan from '../../../components/grid/gridRowSpan';
import { AuthContext } from '../../../auth/AuthContext';
import LabelField from '../../../components/formField/label';
import { urlBase64 } from '../../../utils/download';
import { calculateNewDate, frequencies, addDays, DateNow } from '../../../utils/date';
import { maxValue } from '../../../utils/arrays';
import * as numberUtil from '../../../utils/number';
import Client from '../../../components/client';
import Transaction from '../../../components/transaction';
import './styles.css';
import NumberCell from '../../../components/grid/numberCell';

const initialForm = {
  Data: DateNow(),
  Numero: '',
  DataVencimento: DateNow(),
  DiscriminacaoServico: '',
  NumeroPrefeitura: null,
  SituacaoNotaFiscal: null
};

const initialSaleTax = {
  Desconto: 0,
  DescontoIncondicionado: 0,
  Deducao: 0
};

const InvoiceRegistration = () => {
  let { id } = useParams();
  const history = useHistory();
  const [form, updateProperty, setForm, updateFormValue] = useForms(initialForm);
  const [cpfCnpj, setCpfCpnj] = useState('');
  const [address, setAddress] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [document, setDocument] = useState({});
  const [documents, setDocuments] = useState([]);
  const [invoiceCity, setInvoiceCity] = useState({});
  const [invoiceCities, setInvoiceCities] = useState([]);
  const [transactionNature, setTransactionNature] = useState({});
  const [transactionNatures, setTransactionNatures] = useState([]);
  const [client, setClient] = useState(null);
  const [sellServices, setSellServices] = useState([]);
  const [sellClassifications, setSellClassifications] = useState([]);
  const [planAccount, setPlanAccount] = useState({});
  const [planAccounts, setPlanAccounts] = useState([]);
  const [centerCost, setCenterCost] = useState({});
  const [centerCosts, setCenterCosts] = useState([]);
  const [paymentForms, setPaymentForms] = useState([]);
  const [paymentForm, setPaymentForm] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState({});
  const [totalService, setTotalService] = useState(0);
  const [tributeCode, setTributeCode] = useState('-');
  const [serviceList, setServiceList] = useState('-');
  const [taxClasses, setTaxClasses] = useState([]);
  const [taxClass, setTaxClass] = useState({});
  const [saleTax, setSaleTax] = useState(initialSaleTax);
  const [simplesNacional, setSimplesNacional] = useState(false);
  const { walletSelected } = useContext(AuthContext);
  const [discrimination, setDiscrimination] = useState('');
  const [taxDebts, setTaxDebts] = useState([]);
  const [services, setServices] = useState([]);
  const [saleSettings, setSaleSettings] = useState([]);
  const types = ['Sem parcelamento', 'Valor ser� parcelado em'];
  const [classification, setClassification] = useState({});
  const [popUpViewer, setPopUpViewer] = useState(false);
  const [dataViewer, setDataViewer] = useState(null);
  const [sellPayments, setSellPayments] = useState([]);
  const [cardTaxes, setCardTaxes] = useState([]);
  const [cardTaxDebts, setCardTaxDebts] = useState([]);
  const [cardTax, setCardTax] = useState(null);
  const [cardTaxValue, setCardTaxValue] = useState(null);
  const [card, setCard] = useState(false);
  const [parcelNumber, setParcelNumber] = useState(null);
  const [parcelType, setParcelType] = useState('Sem parcelamento');
  const [parcelPeriod, setParcelPeriod] = useState(null);
  const [retainISS, setRetainISS] = useState(false);
  const [hasState, setHasState] = useState(false);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [state, setState] = useState(null);
  const [states, setStates] = useState([]);
  const [contact, setContact] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [buttonName, setButtonName] = useState(null);
  const [popUpConfirm, setPopUpConfirm] = useState(false);
  const [invoiced, setInvoiced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDiv, setLoadingDiv] = useState(false);
  const [loadingGeneral, setLoadingGeneral] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingTaxs, setLoadingTaxs] = useState(false);
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [loadingClassifications, setLoadingClassifications] = useState(false);
  const [loadingCityDiv, setLoadingCityDiv] = useState(false);
  const [debitNoteIds, setDebitNoteIds] = useState([]);

  const getInvoiceStatusDescription = (status) => {
    switch (status) {
      case 'S':
        return 'Emitida';
      case 'C':
        return 'Cancelada';
      default:
        return '';
    }
  };
  const [popUpsVisibilities, setPopUpsVisibilities] = useState({
    addService: false,
    addPayment: false,
    addClassification: false,
    divideClassification: false
  });
  const calculateTotalService = () => {
    let total = sellServices.reduce((acc, current) => acc + current.ValorTotal, 0);
    total = Math.round(total * 100) / 100;
    setTotalService(total);
    return total;
  };
  const loadServiceDetails = (service) => {
    const taxClass = taxClasses.find((element) => element.Id === service.ClasseDeImpostos_Id);
    if (taxClass !== null) setTaxClass(taxClass);
    setTributeCode(service.CodigoTributacao);
    setServiceList(service.ListaServicos);
  };
  const updateClassification = (newTaxDebts = null, newCardTaxDebts) => {
    let id = 1;
    const newClassifications = [];
    sellServices.forEach((service) => {
      const itemPlanAccount = planAccounts.find(
        (element) => element.Id === service.PlanoDeContas_Id
      );
      const itemCenterCost = centerCosts.find(
        (element) => element.Id === service.CentroDeCustos_Id
      );
      setPlanAccount(itemPlanAccount);
      setCenterCost(itemCenterCost);
      const classification = {
        Id: id,
        Descricao: service.ServicoDescricao,
        PlanoDeContas: itemPlanAccount,
        CentroDeCustos: itemCenterCost,
        Valor: service.ValorTotal
      };
      newClassifications.push(classification);
      id++;
    });
    const _taxDebts = newTaxDebts != null ? newTaxDebts : taxDebts;
    _taxDebts.forEach((taxDebt) => {
      const classification = {
        Id: id,
        Descricao: taxDebt.Descricao,
        Valor: taxDebt.Valor,
        PlanoDeContas: taxDebt.PlanoDeContas,
        CentroDeCustos: taxDebt.CentroDeCustos
      };
      newClassifications.push(classification);
      id++;
    });
    const _taxCardDebts = newCardTaxDebts != null ? newCardTaxDebts : cardTaxDebts;
    _taxCardDebts.forEach((cardTaxDebt) => {
      const classification = {
        Id: id,
        Descricao: cardTaxDebt.Descricao,
        Valor: cardTaxDebt.Valor,
        PlanoDeContas: cardTaxDebt.PlanoDeContas,
        CentroDeCustos: cardTaxDebt.CentroDeCustos
      };
      newClassifications.push(classification);
      id++;
    });
    setSellClassifications(newClassifications);
    return newClassifications;
  };
  const findCardParcel = (cardParcels, parcel) => {
    if (cardParcels) {
      const cardParcel = cardParcels.find(
        (item) => parcel >= item.ParcelaInicio && parcel <= item.ParcelaFim
      );
      return cardParcel ? cardParcel.Taxa / 100 : 0;
    }
    return 0;
  };
  const calculateCardTax = (paymentForm, parcel, cardTax) => {
    let calculated = null;
    if (paymentForm && cardTax) {
      if (paymentForm.Descricao === 'Cart�o de Cr�dito') {
        let value = 0;
        if (!parcel || parcel === 1) value = cardTax.TaxaCredito / 100;
        else if (parcel > 1) value = findCardParcel(cardTax.CartaoParcela, parcel);
        calculated = { Description: 'Cart�o de Cr�dito', Code: 'CARTAO_CREDITO', Value: value };
      } else if (paymentForm.Descricao === 'Cart�o de D�bito') {
        const value = cardTax.TaxaDebito / 100;
        calculated = { Description: 'Cart�o de D�bito', Code: 'CARTAO_DEBITO', Value: value };
      }
      setCardTaxValue(calculated?.Value);
    }
    return calculated;
  };
  const launchTaxDebts = (saleTax) => {
    const taxDebts = [];
    if (saleTax.Desconto > 0) {
      const saleSetting = saleSettings.find(
        (item) => item.CodigoTipoVenda === 'DESCONTO_CONDICIONADO'
      );
      taxDebts.push({
        Descricao: 'Desconto Condicionado',
        Valor: saleTax.Desconto * -1,
        PlanoDeContas: saleSetting?.PlanoDeContas,
        CentroDeCustos: saleSetting?.CentroDeCustos
      });
    }
    if (saleTax.DescontoIncondicionado > 0) {
      const saleSetting = saleSettings.find(
        (item) => item.CodigoTipoVenda === 'DESCONTO_INCONDICIONADO'
      );
      taxDebts.push({
        Descricao: 'Desconto Incondicionado',
        Valor: saleTax.DescontoIncondicionado * -1,
        PlanoDeContas: saleSetting?.PlanoDeContas,
        CentroDeCustos: saleSetting?.CentroDeCustos
      });
    }
    if (saleTax.ISSRetidoFonte > 0) {
      const saleSetting = saleSettings.find((item) => item.CodigoTipoVenda === 'ISS');
      taxDebts.push({
        Descricao: 'ISS Retido',
        Valor: saleTax.ISSRetidoFonte * -1,
        PlanoDeContas: saleSetting?.PlanoDeContas,
        CentroDeCustos: saleSetting?.CentroDeCustos
      });
    }
    if (saleTax.ValorPIS > 0) {
      const saleSetting = saleSettings.find((item) => item.CodigoTipoVenda === 'PIS');
      taxDebts.push({
        Descricao: 'PIS Retido',
        Valor: saleTax.ValorPIS * -1,
        PlanoDeContas: saleSetting?.PlanoDeContas,
        CentroDeCustos: saleSetting?.CentroDeCustos
      });
    }
    if (saleTax.ValorCOFINS > 0) {
      const saleSetting = saleSettings.find((item) => item.CodigoTipoVenda === 'COFINS');
      taxDebts.push({
        Descricao: 'COFINS Retido',
        Valor: saleTax.ValorCOFINS * -1,
        PlanoDeContas: saleSetting?.PlanoDeContas,
        CentroDeCustos: saleSetting?.CentroDeCustos
      });
    }
    if (saleTax.ValorCSLL > 0) {
      const saleSetting = saleSettings.find((item) => item.CodigoTipoVenda === 'CSLL');
      taxDebts.push({
        Descricao: 'CSLL Retido',
        Valor: saleTax.ValorCSLL * -1,
        PlanoDeContas: saleSetting?.PlanoDeContas,
        CentroDeCustos: saleSetting?.CentroDeCustos
      });
    }
    if (saleTax.ValorIR > 0) {
      const saleSetting = saleSettings.find((item) => item.CodigoTipoVenda === 'IRRF');
      taxDebts.push({
        Descricao: 'IRRF',
        Valor: saleTax.ValorIR * -1,
        PlanoDeContas: saleSetting?.PlanoDeContas,
        CentroDeCustos: saleSetting?.CentroDeCustos
      });
    }
    if (saleTax.ValorINSS > 0) {
      const saleSetting = saleSettings.find((item) => item.CodigoTipoVenda === 'INSS');
      taxDebts.push({
        Descricao: 'INSS',
        Valor: saleTax.ValorINSS * -1,
        PlanoDeContas: saleSetting?.PlanoDeContas,
        CentroDeCustos: saleSetting?.CentroDeCustos
      });
    }
    setTaxDebts(taxDebts);
    return taxDebts;
  };
  const calculateTax = async (client, taxClass, address, _retainISS = null) => {
    if (client != null && sellServices.length > 0) {
      setLoadingDiv(true);
      let sum = sellServices.reduce((partialSum, item) => partialSum + item.ValorTotal, 0);
      sum = Math.round(sum * 100) / 100;
      const bodyForm = {
        ...form,
        Valor: sum,
        PessoaId: client.Id,
        VendaServico: sellServices,
        ClasseDeImpostosId: taxClass?.Id,
        EnderecoId: address?.Id,
        VendaImposto: {
          Desconto: saleTax?.Desconto,
          DescontoIncondicionado: saleTax?.DescontoIncondicionado,
          Deducao: saleTax?.Deducao
        },
        DocumentoId: document.Id,
        RetemISS: _retainISS == null ? retainISS : _retainISS
      };
      const { data } = await saleService.calculateTax(bodyForm);
      setSaleTax(data);
      const taxDebts = launchTaxDebts(data);
      updateClassification(taxDebts);
      setLoadingDiv(false);
      return taxDebts;
    }
    setSaleTax(initialSaleTax);
    updateClassification([]);
    return [];
  };
  const launchCardTax = async (calculatedCardTax) => {
    const newCardTaxDebts = [];
    if (calculatedCardTax != null) {
      let total = sellServices.reduce((acc, current) => acc + current.ValorTotal, 0);
      total = saleTax?.ValorLiquido ? saleTax.ValorLiquido : total;
      const saleSetting = saleSettings.find(
        (item) => item.CodigoTipoVenda === calculatedCardTax.Code
      );
      newCardTaxDebts.push({
        Descricao: calculatedCardTax.Description,
        Valor: calculatedCardTax.Value * total * -1,
        PlanoDeContas: saleSetting?.PlanoDeContas,
        CentroDeCustos: saleSetting?.CentroDeCustos
      });
    }
    if (newCardTaxDebts.length > 0) {
      setCardTaxDebts(newCardTaxDebts);
      const taxDebts = await calculateTax(client, taxClass, address);
      const classifications = updateClassification(taxDebts, newCardTaxDebts);
      return classifications;
    }
    const taxDebts = await calculateTax(client, taxClass, address);
    const classifications = updateClassification(taxDebts, []);
    return classifications;
  };
  const addClassification = (classifications) => {
    const index = sellClassifications.findIndex((item) => item.Id === classifications[0].Id);
    sellClassifications.splice(index, 1, classifications[0]);
    for (let i = 1; i < classifications.length; i++) {
      sellClassifications.splice(index + i, 0, classifications[i]);
    }
    for (let i = 0; i < sellClassifications.length; i++) {
      sellClassifications.Id = i + 1;
    }
    setSellClassifications([...sellClassifications]);
  };
  const resetPayments = () => {
    setForm({
      ...form,
      DataVencimento: DateNow()
    });
    setParcelNumber(null);
    setParcelPeriod(null);
    setParcelType('Sem parcelamento');
    setSellPayments([]);
  };
  const onItemChangeSellService = (item) => {
    item.ValorTotal = item.Quantidade * item.Valor - item.Desconto;
    calculateTotalService();
    calculateTax(client, taxClass, address);
    resetPayments();
    return item;
  };
  const onItemDeleteSellService = () => {
    calculateTotalService();
    calculateTax(client, taxClass, address);
    resetPayments();
  };
  const onItemDeleteTransaction = (transactions) => {
    let total = transactions.reduce((acc, current) => acc + current.Valor, 0);
    total = Math.round(total * 100) / 100;
    if (sellServices[0]) {
      sellServices[0].Quantidade = 1;
      sellServices[0].Desconto = 0;
      sellServices[0].Valor = total * -1;
      sellServices[0].ValorTotal = total * -1;
      calculateTotalService();
      calculateTax(client, taxClass, address);
      resetPayments();
    }
  };
  const addService = (service) => {
    const lastId = sellServices.length > 0 ? sellServices[sellServices.length - 1].Id : 0;
    const newItems = sellServices;
    newItems.push({
      Id: lastId + 1,
      ServicoId: service.Id,
      Quantidade: service.Quantidade,
      Valor: service.ValorServico,
      ServicoDescricao: service.Descricao,
      Desconto: service.Desconto,
      ValorTotal: service.ValorTotal,
      CodigoTributacao: service.CodigoTributacao,
      ListaServicos: service.ListaServicos,
      PlanoDeContas_Id: service.PlanoDeContas_Id,
      CentroDeCustos_Id: service.CentroDeCustos_Id,
      ServicoUnidadeMedida: service.UnidadeMedida_Nome
    });
    calculateTotalService();
    loadServiceDetails(service);
    setSellServices(newItems);
    const taxClass = taxClasses.find((element) => element.Id === service.ClasseDeImpostos_Id);
    setTaxClass(taxClass);
    calculateTax(client, taxClass, address);
    resetPayments();
  };
  const generateDiscrimination = async () => {
    setLoadingDiv(true);
    const sum = sellServices.reduce((partialSum, item) => partialSum + item.ValorTotal, 0);
    const bodyForm = {
      ...form,
      Valor: sum,
      PessoaId: client.Id,
      VendaServico: sellServices,
      ClasseDeImpostosId: taxClass?.Id,
      VendaImposto: {
        Desconto: saleTax?.Desconto,
        DescontoIncondicionado: saleTax?.DescontoIncondicionado,
        Deducao: saleTax?.Deducao
      },
      FormaPagamento: paymentForm?.Id,
      DocumentoId: document.Id,
      MunicipioId: invoiceCity.idMunicipio,
      TipoPagamento: parcelType
    };
    const { data } = await saleService.generateDiscrimination(bodyForm);
    setDiscrimination(data);
    setLoadingDiv(false);
  };

  const setPopUpClassification = (classification) => {
    setPopUpsVisibilities({
      ...popUpsVisibilities,
      divideClassification: true
    });
    setClassification(classification);
  };
  const setPopUpVisibility = (propName, value) => {
    setPopUpsVisibilities({
      ...popUpsVisibilities,
      [propName]: value
    });
  };
  const loadTaxClasses = async () => {
    const { data } = await taxClassService.getAll();
    setTaxClasses(data);
    return data;
  };
  const updateClientDetails = async (client, address) => {
    if (address) {
      if (address.RetemISS && !id) setRetainISS(true);
      else if (!id) setRetainISS(false);
    }
    calculateTax(client, taxClass, address);
  };

  const setClientAddress = async (client, idAddress, contact) => {
    if (client.TipoPessoa === 0) {
      setCpfCpnj(client.CPF);
      setAddress(client.Endereco);
      setContact({ Nome: contact });
      setContacts(null);
    } else {
      let addresses = [];
      if (client.EnderecoPrincipal != null) addresses.push(client.EnderecoPrincipal);
      addresses = addresses.concat(client.OutrosEnderecos);
      addresses = addresses.map((item) => ({
        ...item,
        EnderecoCompleto: [item.Logradouro, item.Numero, item.Complemento, item.Bairro]
          .filter(Boolean)
          .join(', ')
      }));
      let newAddress = addresses.filter((x) => x.Id === idAddress);
      newAddress = newAddress.length > 0 ? newAddress[0] : null;
      if (newAddress != null) {
        setCpfCpnj(newAddress.CNPJ ? newAddress.CNPJ : '');
        setAddress(newAddress);
      } else if (addresses.length === 1) {
        setCpfCpnj(addresses[0].CNPJ ? addresses[0].CNPJ : '');
        setAddress(addresses[0]);
      }
      setAddresses(addresses);
      const contacts = [];
      if (client.Contatos?.length > 0) {
        client.Contatos.forEach((item) => {
          contacts.push(item);
        });
      }
      if (addresses?.length > 0) {
        addresses.forEach((item1) => {
          if (item1.Contatos?.length > 0) {
            item1.Contatos.forEach((item2) => {
              contacts.push(item2);
            });
          }
        });
      }
      contacts.sort();
      if (!contacts) {
        setContact({ Nome: contact });
        setContacts(null);
      } else if (contacts.length > 0) {
        const contato = contacts.find((x) => x.Id === contact);
        setContact(contato);
        contacts.sort((a, b) => {
          if (a.Nome < b.Nome) {
            return -1;
          }
          if (a.Nome > b.Nome) {
            return 1;
          }
          return 0;
        });
        setContacts(contacts);
      }
    }
  };

  const loadTransactionNatures = async () => {
    const { data } = await saleService.getTransactionNatures();
    setTransactionNatures(data);
    return data;
  };
  const loadDocuments = async () => {
    const { data } = await documentService.getAll();
    setDocuments(data);
    return data;
  };
  const loadClients = async (filter) => {
    const { data } = await personService.getByName(filter);
    return data;
  };
  const loadPaymentForms = async () => {
    let { data } = await transactionService.getPaymentForms();
    data = data['000'];
    data = data.filter((item) => item.Id !== '2' && item.Id !== '3');
    data.sort((a, b) => {
      if (a.Descricao < b.Descricao) {
        return -1;
      }
      if (a.Descricao > b.Descricao) {
        return 1;
      }
      return 0;
    });
    setPaymentForms(data);
    return data;
  };
  const loadAccounts = async () => {
    const data = await accountService.getReceivingLocal();
    setAccounts(data);
    return data;
  };
  const loadPlanAccounts = async () => {
    const data = await planAccountService.getAllExpensesRevenues();
    setPlanAccounts(data);
    return data;
  };
  const loadCenterCosts = async () => {
    const { data } = await centerCostService.getAll();
    setCenterCosts(data);
    return data;
  };
  const loadSaleSettings = async (id, planAccounts, centerCosts) => {
    const newSaleSettings = [];
    const { data } = await businessWalletService.getSaleSettings(id);
    data.forEach((saleSetting) => {
      const itemPlanAccount = planAccounts.find(
        (element) => element.Id === saleSetting.IdPlanoDeContas
      );
      const itemCenterCost = centerCosts.find(
        (element) => element.Id === saleSetting.IdCentroDeCustos
      );
      const newSaleSetting = {
        Id: saleSetting.Id,
        CodigoTipoVenda: saleSetting.CodigoTipoVenda,
        NomeTipoVenda: saleSetting.NomeTipoVenda,
        PlanoDeContas: itemPlanAccount,
        CentroDeCustos: itemCenterCost
      };
      newSaleSettings.push(newSaleSetting);
    });
    setSaleSettings(newSaleSettings);
    return newSaleSettings;
  };
  const loadCardTaxes = async (id) => {
    const { data } = await cardService.getById(id);
    setCardTaxes(data);
    return data;
  };
  const redirectToInvoices = (id) => {
    if (id) history.push(`/invoices/${id}`);
    else history.push(`/invoices`);
  };
  const generateDividedTransaction = () => {
    const dividedTransactions = [];
    sellClassifications.forEach((element) => {
      dividedTransactions.push({
        Descricao: element.Descricao,
        Valor: element.Valor,
        IdPlanoDeContas: element.PlanoDeContas?.Id,
        IdCentroDeCustos: element.CentroDeCustos?.Id
      });
    });
    return dividedTransactions;
  };
  const generateTransaction = () => {
    let total = sellServices.reduce((acc, current) => acc + current.ValorTotal, 0);
    total = Math.round(total * 100) / 100;
    let totalClassications = sellClassifications.reduce((acc, current) => acc + current.Valor, 0);
    totalClassications = Math.round(totalClassications * 100) / 100;
    let newDate = form.DataVencimento;
    if (parcelType === 'Sem parcelamento' && cardTax) {
      if (paymentForm?.Descricao === 'Cart�o de Cr�dito')
        newDate = new Date(addDays(newDate, cardTax.DiaCredito));
      else if (paymentForm?.Descricao === 'Cart�o de D�bito')
        newDate = new Date(addDays(newDate, cardTax.DiaDebito));
    }
    const transaction = {
      Data: newDate,
      DataEmissao: form.Data,
      DataVencimento: newDate,
      Descricao: `Venda ${form.Numero}`,
      Valor: totalClassications,
      IdFormaPagamento: paymentForm?.Id,
      IdContaPagamento: account?.Id,
      NumeroParcelas: parcelNumber,
      listaLancamentoDivisao: generateDividedTransaction()
    };
    return transaction;
  };
  const generateClassifications = () => {
    const classifications = sellClassifications.map((x) => ({
      Descricao: x.Descricao,
      PlanoDeContas_Id: x.PlanoDeContas?.Id,
      CentroDeCustos_Id: x.CentroDeCustos?.Id,
      Valor: x.Valor
    }));
    return classifications;
  };
  const transmitInvoice = async (id) => {
    const invoicesSelectedsIds = [id];
    const transmitInvoicesRequests = invoicesSelectedsIds.map((invoiceSelected) =>
      invoiceService.transmit(invoiceSelected)
    );

    const transmitInvoicesResponses = await Promise.all(transmitInvoicesRequests);

    const isAllRequestsRight = transmitInvoicesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    if (isAllRequestsRight) {
      successNotification('Documento transmitido com sucesso!');
    } else {
      let erro = 'Houve um erro ao tentar transmitir um ou mais documentos:';
      transmitInvoicesResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro += data.MensagemUsuario;
          else erro += data;
        }
      });
      errorNotification(erro);
    }
    redirectToInvoices(id);
  };

  const printInvoice = async (id) => {
    const invoicesSelectedsIds = [id];
    const printInvoicesRequests = invoicesSelectedsIds.map((invoiceSelected) =>
      invoiceService.recoveryPDF(invoiceSelected)
    );

    const printInvoicesResponses = await Promise.all(printInvoicesRequests);

    const isAllRequestsRight = printInvoicesResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    printInvoicesResponses.forEach((currentResponse) => {
      if (currentResponse.status === 200) {
        const base64 = currentResponse.data;
        const url = urlBase64(base64);
        setDataViewer(url);
        setPopUpViewer(true);
      }
    });

    if (!isAllRequestsRight) {
      let erro = 'Houve um erro ao tentar gerar o documento:';
      printInvoicesResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro += data.MensagemUsuario;
          else erro += data;
        }
      });
      errorNotification(erro);
    }
  };

  const generatePayments = () => {
    const payments = sellPayments.map((x) => ({
      DataVencimento: new Date(x.DataVencimento),
      Valor: x.Valor,
      FormaPagamento: x.FormaPagamento?.Id,
      ContaId: x.ContaPagamento?.Id
    }));
    return payments;
  };

  const resetCardTax = async () => {
    setParcelNumber(1);
    setParcelPeriod(null);
    setParcelType('Sem parcelamento');
    setCardTax(null);
    setSellPayments([]);
    setCardTaxDebts([]);
    const taxDebts = await calculateTax(client, taxClass, address);
    updateClassification(taxDebts, []);
  };

  const addPayments = (period, parcel, paymentForm, account, date, classifications) => {
    if (parcelType !== 'Sem parcelamento') {
      if ((period || cardTax) && parcel && date) {
        if (card && cardTax) {
          const max = maxValue(cardTax.CartaoParcela, 'ParcelaFim');
          if (max !== Infinity && parcel > max) {
            warningNotification('O n�mero de parcelas � maior do que o permitido para este cart�o');
            return false;
          }
        }
        const newSellPayments = [];
        classifications = classifications || generateClassifications();
        const sum = classifications.reduce((partialSum, item) => partialSum + item.Valor, 0);
        let newDate = new Date(date);
        const values = numberUtil.parcel(sum, parcel);
        for (let i = 0; i < parcel; i++) {
          newSellPayments.push({
            Id: i,
            DataVencimento: new Date(newDate),
            Valor: values[i],
            FormaPagamento: paymentForm,
            ContaPagamento: account
          });
          if (card && cardTax) {
            if (paymentForm?.Descricao === 'Cart�o de Cr�dito')
              newDate = new Date(addDays(newDate, cardTax.DiaCredito));
            else newDate = new Date(addDays(newDate, cardTax.DiaDebito));
          } else newDate = calculateNewDate(newDate, period);
        }
        setSellPayments(newSellPayments);
      } else {
        setSellPayments([]);
      }
    } else {
      setSellPayments([]);
    }
    return true;
  };
  const addPayment = () => {
    if (sellPayments.length > 0) {
      const last = sellPayments[sellPayments.length - 1];
      setSellPayments([
        ...sellPayments,
        {
          Id: last.Id + 1,
          DataVencimento: last.DataVencimento,
          Valor: last.Valor,
          FormaPagamento: last.FormaPagamento,
          ContaPagamento: last.ContaPagamento
        }
      ]);
    } else {
      setSellPayments([
        {
          Id: 1,
          DataVencimento: DateNow(),
          Valor: 0,
          FormaPagamento: null,
          ContaPagamento: null
        }
      ]);
    }
  };
  const loadSellClassifications = (invoice, planAccounts, centerCosts) => {
    let sellClassifications = invoice.VendaClassificacao;
    let i = 0;
    sellClassifications = sellClassifications.map((x) => {
      i++;
      const itemPlanAccount = planAccounts.find((element) => element.Id === x.PlanoDeContas_Id);
      const itemCenterCost = centerCosts.find((element) => element.Id === x.CentroDeCustos_Id);
      return {
        Id: i,
        ...x,
        PlanoDeContas: {
          Id: itemPlanAccount?.Id,
          Nome: itemPlanAccount?.Nome
        },
        CentroDeCustos: {
          Id: itemCenterCost?.Id,
          Nome: itemCenterCost?.Nome
        }
      };
    });
    setSellClassifications(sellClassifications);
    if (sellClassifications.length === 1) {
      setPlanAccount(sellClassifications[0].PlanoDeContas);
      setCenterCost(sellClassifications[0].CentroDeCustos);
    }
  };
  const loadSellPayments = (invoice, paymentForms, accounts) => {
    let sellPayments = invoice.VendaPagamento;
    let i = 0;
    sellPayments = sellPayments.map((x) => {
      i++;
      const itemPaymentForm = paymentForms.find((element) => element.Id === x.FormaPagamento);
      const itemAccount = accounts.find((element) => element.Id === x.ContaId);
      return {
        Id: i,
        DataVencimento: new Date(x.DataVencimento),
        Valor: x.Valor,
        FormaPagamento: itemPaymentForm,
        ContaPagamento: itemAccount
      };
    });
    setSellPayments(sellPayments);
  };

  const loadStates = async () => {
    const { data } = await addressService.statesFromBrazil();
    setStates(data);
    return data;
  };

  const loadCities = async (id) => {
    const { data } = await addressService.citiesFromState(id);
    setCities(data);
    return data;
  };

  useEffect(async () => {
    setLoadingClient(true);
    setLoadingGeneral(true);
    setLoadingServices(true);
    setLoadingTaxs(true);
    setLoadingPayments(true);
    setLoadingClassifications(true);
    let simplesNacional = false;
    if (walletSelected?.CarteiraBusiness?.SimplesNacional || walletSelected?.CarteiraBusiness?.MEI)
      simplesNacional = true;
    setSimplesNacional(simplesNacional);
    if (id && id !== '0') {
      let response = await saleService.getById(id);
      const invoice = response.data;
      // ############## Load General ##############
      setInvoiced(invoice.Faturado === 'S');
      setForm({
        Numero: invoice.Numero,
        Data: new Date(invoice.Data),
        DataVencimento: new Date(invoice.DataVencimento),
        FormaPagamento: invoice.FormaPagamento,
        DiscriminacaoServico: invoice.DiscriminacaoServico,
        NumeroPrefeitura: invoice.NotaFiscal?.Numero,
        SituacaoNotaFiscal: invoice.SituacaoNotaFiscal,
        Faturado: invoice.Faturado,
        LancamentoBaixado: invoice.LancamentoBaixado
      });
      setParcelNumber(invoice.NumeroParcela);
      setParcelPeriod(invoice.Periodicidade);
      setParcelType(invoice.TipoPagamento);
      const documents = await loadDocuments();
      const transactionNatures = await loadTransactionNatures();
      setDocument(invoice.Documento);
      setInvoiceCities([invoice.Municipio]);
      setInvoiceCity(invoice.Municipio);
      setTransactionNature(invoice.NaturezaOperacao);
      setRetainISS(invoice.RetemISS);

      // ############## Load City ##############
      if (invoice.NaturezaOperacao && invoice.NaturezaOperacao.CodigoNFSe === '2') {
        const states = await loadStates();
        if (invoice.MunicipioOperacaoId) {
          const city = await addressService.getCityById(invoice.MunicipioOperacaoId);
          if (city.data) {
            const { data } = city;
            await loadCities(data.idEstado);
            setCity(data);
            const state = states.find((item) => item.idEstado === data.idEstado);
            setState(state);
          }
        }
        setHasState(true);
      } else {
        setHasState(false);
      }
      setLoadingGeneral(false);

      // ############## Load Client ##############
      response = await personService.getById(invoice.PessoaId);
      const client = response.data;
      setClient(client);
      await setClientAddress(client, invoice.EnderecoId, invoice.Contato);
      setLoadingClient(false);

      // ############## Load Services ##############
      let idService = 1;
      const apiSellService = invoice.VendaServico.map((obj) => ({
        ...obj,
        Id: idService++,
        PlanoDeContas_Id: obj.Servico.PlanoDeContas_Id,
        CentroDeCustos_Id: obj.Servico.CentroDeCustos_Id,
        ServicoUnidadeMedida: obj.Servico.UnidadeMedida_Nome
      }));
      setSellServices(apiSellService);
      const total = invoice.VendaServico.reduce((acc, current) => acc + current.ValorTotal, 0);
      setTotalService(total);
      if (invoice.VendaServico?.length > 0) {
        setTributeCode(invoice.VendaServico[0].Servico.CodigoTributacao);
        setServiceList(invoice.VendaServico[0].Servico.ListaServicos);
      }
      setLoadingServices(false);

      // ############## Load Taxs ##############
      const taxClasses = await loadTaxClasses();
      const taxClass = taxClasses.find((element) => element.Id === invoice.ClasseDeImpostosId);
      setTaxClass(taxClass);
      setSaleTax(invoice.VendaImposto);
      setLoadingTaxs(false);

      // ############## Load Payments ##############
      const paymentForms = await loadPaymentForms();
      const accounts = await loadAccounts();
      loadSellPayments(invoice, paymentForms, accounts);
      const itemAccount = accounts.find((element) => element.Id === invoice.ContaId);
      setAccount(itemAccount);
      const itemPaymentForm = paymentForms.find((element) => element.Id === invoice.FormaPagamento);
      setPaymentForm(itemPaymentForm);
      setLoadingPayments(false);

      // ############## Load Classifications ##############
      const planAccounts = await loadPlanAccounts();
      const centerCosts = await loadCenterCosts();
      loadSellClassifications(invoice, planAccounts, centerCosts);
      const saleSettings = await loadSaleSettings(
        walletSelected.CarteiraBusiness.Id,
        planAccounts,
        centerCosts
      );

      const cardTaxes = await loadCardTaxes(walletSelected.CarteiraBusiness.Id);
      const cardTax = cardTaxes.find((element) => element.Id === invoice.CartaoTaxaId);
      setCardTax(cardTax);
      const card =
        itemPaymentForm?.Descricao === 'Cart�o de Cr�dito' ||
        itemPaymentForm?.Descricao === 'Cart�o de D�bito';
      setCard(card);
      setLoadingClassifications(false);
    } else {
      // ############## Load General ##############
      const documents = await loadDocuments();
      const transactionNatures = await loadTransactionNatures();
      setDocument(documents[0]);
      const invoiceNumber = await saleService.getInvoiceNumber(documents[0]?.Id);
      if (documents[0]?.TipoDocumentoId !== 2) {
        const { data } = await addressService.getCityById(documents[0].MunicipioId);
        setInvoiceCities([data]);
        setInvoiceCity(data);
        setTransactionNature(transactionNatures[0]);
      }
      setForm({
        ...form,
        Numero: invoiceNumber,
        DiscriminacaoServico: documents[0]?.OutrasInformacoes
      });
      setLoadingGeneral(false);

      // ############## Load Client ##############
      setLoadingClient(false);

      // ############## Load Services ##############
      setLoadingServices(false);

      // ############## Load Taxs ##############
      const taxClasses = await loadTaxClasses();
      setLoadingTaxs(false);

      // ############## Load Payments ##############
      const paymentForms = await loadPaymentForms();
      const accounts = await loadAccounts();
      setLoadingPayments(false);

      // ############## Load Classifications ##############
      const planAccounts = await loadPlanAccounts();
      const centerCosts = await loadCenterCosts();
      const cardTaxes = await loadCardTaxes(walletSelected.CarteiraBusiness.Id);
      const saleSettings = await loadSaleSettings(
        walletSelected.CarteiraBusiness.Id,
        planAccounts,
        centerCosts
      );
      setLoadingClassifications(false);
    }
  }, []);

  const validate = async () => {
    if (
      loading ||
      loadingDiv ||
      loadingGeneral ||
      loadingClient ||
      loadingServices ||
      loadingPayments ||
      loadingClassifications ||
      loadingTaxs ||
      loadingCityDiv
    ) {
      warningNotification('Aguarde o carregamento dos dados.');
      return false;
    }
    if (sellServices.length === 0) {
      warningNotification('Pelo menos um serv��o deve ser adicionado.');
      return false;
    }
    if (parcelType !== 'Sem parcelamento') {
      if (!parcelNumber || parcelNumber <= 0 || parcelNumber > 1000) {
        warningNotification('N�mero de parcelas inv�lido.');
        return false;
      }
      if (!card && !parcelPeriod) {
        warningNotification('Periodicidade invalida.');
        return false;
      }
      const payments = generatePayments();
      let sum = payments.reduce((partialSum, item) => partialSum + item.Valor, 0);
      sum = Math.round(sum * 100) / 100;
      const classifications = generateClassifications();
      let sumClassifications = classifications.reduce(
        (partialSum, item) => partialSum + item.Valor,
        0
      );
      sumClassifications = Math.round(sumClassifications * 100) / 100;
      if (sumClassifications !== sum) {
        warningNotification('Valor l�quido � diferente do valor da classifica��o.');
        return false;
      }
    }
    return true;
  };

  const submitFormValidated = async (button) => {
    setLoading(true);
    let sum = sellServices.reduce((partialSum, item) => partialSum + item.ValorTotal, 0);
    sum = Math.round(sum * 100) / 100;
    const transaction = generateTransaction();
    const classifications = generateClassifications();
    const sumClassifications = classifications.reduce(
      (partialSum, item) => partialSum + item.Valor,
      0
    );
    const payments = parcelType !== 'Sem parcelamento' ? generatePayments() : null;
    let vendaImposto = null;
    if (saleTax)
      vendaImposto = {
        Desconto: saleTax.Desconto,
        DescontoIncondicionado: saleTax.DescontoIncondicionado,
        Deducao: saleTax.Deducao
      };

    const bodyForm = {
      ...form,
      FormaPagamento: paymentForm?.Id,
      ContaId: account?.Id,
      Valor: sum,
      PessoaId: client.Id,
      VendaServico: sellServices,
      VendaClassificacao: classifications,
      Lancamento: transaction,
      ValorLiquido: sumClassifications,
      VendaImposto: vendaImposto,
      ClasseDeImpostosId: taxClass?.Id,
      DocumentoId: document?.Id,
      NaturezaOperacaoId: transactionNature?.Id,
      MunicipioId: invoiceCity?.idMunicipio,
      EnderecoId: address?.Id,
      VendaPagamento: payments,
      NumeroParcela: parcelNumber,
      Periodicidade: parcelPeriod,
      TipoPagamento: parcelType,
      RetemISS: retainISS,
      MunicipioOperacaoId: city?.idMunicipio,
      Contato: contact?.Id ? contact?.Id : contact?.Nome,
      CartaoTaxaId: cardTax?.Id,
      NotaDebitoLancamentoIds: debitNoteIds
    };
    if (button === 'imprimir') {
      await printInvoice(id);
    } else if (button === 'pagar' || buttonName === 'pagar') {
      const response = await saleService.updatePayment(id, bodyForm);
      if (response.status === 200) {
        successNotification('Documento atualizado com sucesso!');
        redirectToInvoices(id);
      } else {
        const { data } = response;
        if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
        else errorNotification(data);
      }
    } else {
      let response = null;
      if (id && id !== '0') {
        response = await saleService.update(id, bodyForm);
        if (response.status === 200) {
          successNotification('Documento atualizado com sucesso!');
          if (buttonName === 'salvarTransmitir' || button === 'salvarTransmitir') {
            await transmitInvoice(id);
          } else redirectToInvoices(id);
        } else {
          const { data } = response;
          if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
          else errorNotification(data);
        }
      } else {
        response = await saleService.create(bodyForm);
        if (response.status === 200) {
          successNotification('Documento criado com sucesso!');
          const { data } = response;
          id = data;
          if (buttonName === 'salvarTransmitir' || button === 'salvarTransmitir') {
            await transmitInvoice(id);
          } else redirectToInvoices(id);
        } else {
          const { data } = response;
          if (data.MensagemUsuario !== undefined) errorNotification(data.MensagemUsuario);
          else errorNotification(data);
        }
      }
    }
    setLoading(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const button = e.nativeEvent?.submitter?.name;
    setButtonName(button);
    if (await validate()) {
      if (form.LancamentoBaixado) setPopUpConfirm(true);
      else submitFormValidated(button);
    }
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      {id && id !== '0' ? <h1>Atualizar Documento</h1> : <h1>Novo Documento</h1>}
      <form className="form-general-invoice" onSubmit={submitForm}>
        <ToggleInvoice title="Dados Gerais">
          {loadingGeneral ? (
            <LoadScreen />
          ) : (
            <>
              <FormRow withShrink>
                <FormFieldDate
                  titleLabel="Data de Emiss�o"
                  value={form.Data}
                  valueOnChange={(val) => updateFormValue('Data', val)}
                  className="fill-40-field"
                  required
                  disabled={invoiced}
                />
                <GenericField
                  titleLabel="N�mero"
                  enableInfo
                  onChangeValue={updateProperty}
                  msg="N�mero sequencial das notas emitidas"
                  valueInput={form.Numero}
                  classNameWrapper="fill-40-field"
                  required
                  name="Numero"
                  typeNumber
                  disabled={invoiced}
                />
                <FormFieldDrop
                  titleLabel="Tipo de Documento"
                  defaultValueSelect="Selecione..."
                  onChangeValue={async (val) => {
                    setDocument(val.value);
                    const invoiceNumber = await saleService.getInvoiceNumber(val.value.Id);
                    setForm({
                      ...form,
                      Numero: invoiceNumber,
                      DiscriminacaoServico: val.value?.OutrasInformacoes
                    });
                    if (val.value.TipoDocumentoId !== 2) {
                      const { data } = await addressService.getCityById(val.value.MunicipioId);
                      setInvoiceCities([data]);
                      setInvoiceCity(data);
                      setTransactionNature(transactionNatures[0]);
                    } else {
                      setInvoiceCities([]);
                      setInvoiceCity(null);
                      setTransactionNature(null);
                    }
                  }}
                  value={document}
                  className="fill-40-field"
                  infosSelect={documents}
                  dataItemKey="Id"
                  textField="Nome"
                  required
                  disabled={invoiced}
                />
              </FormRow>
              {document?.TipoDocumentoId !== 2 && document?.TipoDocumentoId !== 4 && (
                <FormRow withShrink>
                  <FormFieldDrop
                    titleLabel="Munic�pio de Incid�ncia do ISSQN"
                    defaultValueSelect="Selecione..."
                    onChangeValue={(val) => {
                      setInvoiceCity(val.value);
                    }}
                    value={invoiceCity}
                    className="fill-50-field"
                    infosSelect={invoiceCities}
                    dataItemKey="idMunicipio"
                    textField="nomeMunicipio"
                    required
                    disabled
                  />
                  <FormFieldDrop
                    titleLabel="Natureza da Opera��o"
                    defaultValueSelect="Selecione..."
                    onChangeValue={async (val) => {
                      setTransactionNature(val.value);
                      if (val.value && val.value.CodigoNFSe === '2') {
                        await loadStates();
                        setState(null);
                        setCity(null);
                        setHasState(true);
                      } else {
                        setState(null);
                        setCity(null);
                        setHasState(false);
                      }
                    }}
                    value={transactionNature}
                    className="fill-50-field"
                    infosSelect={transactionNatures}
                    dataItemKey="Id"
                    textField="Nome"
                    required
                    disabled={invoiced}
                  />
                </FormRow>
              )}
              {hasState && (
                <FormRow withShrink>
                  <FormFieldDrop
                    titleLabel="Estado"
                    defaultValueSelect="Selecione..."
                    infosSelect={states}
                    className="fill-50-field"
                    name="state"
                    onChangeValue={async (val) => {
                      setState(val.value);
                      if (val.value) {
                        setLoadingCityDiv(true);
                        const cities = await loadCities(val.value.idEstado);
                        setCity(null);
                        setLoadingCityDiv(false);
                      }
                    }}
                    value={state}
                    dataItemKey="idEstado"
                    textField="nomeEstado"
                    disabled={invoiced}
                  />
                  {loadingCityDiv ? (
                    <LoadScreen />
                  ) : (
                    <FormFieldDrop
                      titleLabel="Munic�pio"
                      defaultValueSelect="Selecione..."
                      infosSelect={cities}
                      className="fill-50-field"
                      name="city"
                      onChangeValue={async (val) => {
                        setCity(val.value);
                      }}
                      value={city}
                      dataItemKey="idMunicipio"
                      textField="nomeMunicipio"
                      disabled={invoiced}
                    />
                  )}
                </FormRow>
              )}
              {form.NumeroPrefeitura && (
                <FormRow withShrink>
                  <LabelField
                    titleLabel="N�mero na Prefeitura"
                    classNameWrapper="fill-50-field"
                    valueLabel={form.NumeroPrefeitura}
                  />
                  <LabelField
                    titleLabel="Situa��o do Documento"
                    classNameWrapper="fill-50-field"
                    valueLabel={getInvoiceStatusDescription(form.Faturado)}
                  />
                </FormRow>
              )}
            </>
          )}
        </ToggleInvoice>
        <ToggleInvoice title="Cliente" startExpanded>
          {loadingClient ? (
            <LoadScreen />
          ) : (
            <>
              <Client
                client={client}
                setClient={setClient}
                cpfCnpj={cpfCnpj}
                setCpfCpnj={setCpfCpnj}
                address={address}
                setAddress={setAddress}
                addresses={addresses}
                setAddresses={setAddresses}
                contact={contact}
                setContact={setContact}
                contacts={contacts}
                setContacts={setContacts}
                afterClientSelection={updateClientDetails}
                disabled={invoiced}
              />
              {address?.RetemISS && (
                <FormRow>
                  <h3 style={{ color: 'red', fontWeight: 'bold' }}>Cliente ret�m ISS</h3>
                </FormRow>
              )}
              <FormRow>
                <FormFieldCheckbox
                  label="ISS ser� retido"
                  classNameWrapper="fill-50-field"
                  value={retainISS}
                  onChange={(val) => {
                    setRetainISS(val.value);
                    calculateTax(client, taxClass, address, val.value);
                  }}
                  disabled={invoiced}
                />
              </FormRow>
            </>
          )}
        </ToggleInvoice>
        <ToggleInvoice title="Servi�os" startExpanded>
          {loadingServices ? (
            <LoadScreen />
          ) : (
            <>
              <FormRow>
                <GridInline
                  data={sellServices}
                  setData={setSellServices}
                  onItemChange={onItemChangeSellService}
                  deleteColumn={!invoiced}
                  onItemDelete={onItemDeleteSellService}
                  disabled={invoiced}
                >
                  <GridColumn field="ServicoDescricao" title="Descri��o" editable={false} />
                  <GridColumn
                    field="Quantidade"
                    title="Quantidade"
                    editable={!invoiced}
                    editor="numeric"
                    cell={NumberCell}
                  />
                  <GridColumn
                    field="ServicoUnidadeMedida"
                    title="Unidade de Medida"
                    editable={false}
                    cell={(props) => (
                      <td style={{ textAlign: 'right' }}>{props.dataItem.ServicoUnidadeMedida}</td>
                    )}
                  />
                  <GridColumn
                    field="Valor"
                    title="Valor"
                    editable={!invoiced}
                    editor="numeric"
                    cell={NumberCell}
                  />
                  <GridColumn
                    field="Desconto"
                    title="Desconto (valor)"
                    editable={!invoiced}
                    editor="numeric"
                    cell={NumberCell}
                  />
                  <GridColumn
                    field="ValorTotal"
                    title="Total"
                    editable={false}
                    editor="numeric"
                    format="{0:n2}"
                    footerCell={(props) => TotalValueCell(props, totalService)}
                  />
                </GridInline>
              </FormRow>
              <FormRow justifyEnd space10>
                <OrangeButton
                  onClick={() => setPopUpVisibility('addService', true)}
                  disable={invoiced}
                >
                  Adicionar Servi�o
                </OrangeButton>
              </FormRow>
              <FormRow>
                <GenericField
                  titleLabel="Complemento da Descri��o dos Servi�os"
                  classNameWrapper="fill-100-field"
                  isTextArea
                  valueInput={form.DiscriminacaoServico}
                  onChangeValue={(val) => {
                    updateFormValue('DiscriminacaoServico', val.value);
                  }}
                  disabled={invoiced}
                />
              </FormRow>
            </>
          )}
        </ToggleInvoice>
        {document?.TipoDocumentoId !== 2 && document?.TipoDocumentoId !== 4 && (
          <ToggleInvoice title="Impostos e Descontos">
            {!simplesNacional && (
              <FormRow withShrink>
                <FormFieldDrop
                  titleLabel="Classe de Impostos"
                  defaultValueSelect="Selecione..."
                  onChangeValue={(event) => {
                    // resetCardTax();
                    setTaxClass(event.value);
                    calculateTax(client, event.value, address);
                  }}
                  value={taxClass}
                  className="fill-40-field"
                  infosSelect={taxClasses}
                  dataItemKey="Id"
                  textField="Nome"
                  name="TaxClass"
                  required
                  disable={invoiced}
                />
                <LabelField
                  titleLabel="C�digo de Tributa��o do Munic�pio"
                  classNameWrapper="fill-40-field"
                  valueLabel={tributeCode}
                />
                <LabelField
                  titleLabel="Lista de Servi�os LC116/3"
                  classNameWrapper="fill-40-field"
                  valueLabel={serviceList}
                />
              </FormRow>
            )}

            {loadingDiv ? (
              <LoadScreen />
            ) : (
              <>
                <FormRow withShrink>
                  <GenericField
                    titleLabel="Valor do Servi�o"
                    valueInput={totalService}
                    readOnly
                    classNameWrapper="fill-50-field-tooltip"
                    disabled
                    iconEnabled={INTERNAL_ICON.REAIS_ICON}
                    required
                  />
                  <GenericField
                    titleLabel="{-} Dedu��es"
                    setValue={(val) => {
                      const newSaleTax = {
                        ...saleTax,
                        Deducao: val
                      };
                      setSaleTax(newSaleTax);
                    }}
                    onBlur={() => {
                      calculateTax(client, taxClass, address);
                    }}
                    valueInput={saleTax.Deducao}
                    classNameWrapper="fill-50-field-tooltip"
                    iconEnabled={INTERNAL_ICON.REAIS_ICON}
                    name="Deducao"
                    disabled={!simplesNacional || invoiced}
                  />
                </FormRow>

                <FormRow withShrink>
                  <GenericField
                    titleLabel="{-} Descontos Condicionados"
                    setValue={(val) => {
                      const newSaleTax = {
                        ...saleTax,
                        Desconto: val
                      };
                      setSaleTax(newSaleTax);
                    }}
                    onBlur={() => {
                      calculateTax(client, taxClass, address);
                    }}
                    valueInput={saleTax.Desconto}
                    classNameWrapper="fill-50-field"
                    iconEnabled={INTERNAL_ICON.REAIS_ICON}
                    name="Desconto"
                    msg="O desconto condicionado comp�e a base de c�lculo dos impostos"
                    enableInfo
                    disabled={invoiced}
                  />
                  <GenericField
                    titleLabel="{-} Descontos Incondicionados"
                    setValue={(val) => {
                      const newSaleTax = {
                        ...saleTax,
                        DescontoIncondicionado: val
                      };
                      setSaleTax(newSaleTax);
                    }}
                    onBlur={() => {
                      calculateTax(client, taxClass, address);
                    }}
                    valueInput={saleTax.DescontoIncondicionado}
                    classNameWrapper="fill-50-field"
                    iconEnabled={INTERNAL_ICON.REAIS_ICON}
                    name="DescontoIncondicionado"
                    msg="O desconto incondicionado n�o comp�e a base de c�lculo dos impostos"
                    enableInfo
                    disabled={invoiced}
                  />
                </FormRow>

                <FormRow>
                  <GenericField
                    titleLabel="{-} Reten��es Federais"
                    valueInput={saleTax.RetencaoFederal}
                    classNameWrapper="fill-50-field-tooltip-left"
                    readOnly
                    disabled
                    iconEnabled={INTERNAL_ICON.REAIS_ICON}
                    msg="As reten��es federais podem incluir PIS, CONFIS, CSLL, IRRF e INSS"
                    enableInfo
                  />

                  <GenericField
                    titleLabel="{=} Base de C�lculo"
                    valueInput={saleTax.ValorBaseCalculoISS}
                    classNameWrapper="fill-50-field-tooltip-right"
                    readOnly
                    disabled
                    iconEnabled={INTERNAL_ICON.REAIS_ICON}
                  />
                </FormRow>

                <FormRow withShrink>
                  <GenericField
                    titleLabel="{-} ISS Retido na Fonte"
                    valueInput={saleTax.ISSRetidoFonte}
                    iconEnabled={INTERNAL_ICON.REAIS_ICON}
                    classNameWrapper="fill-50-field-tooltip"
                    disabled
                    readOnly
                    required
                  />
                  <GenericField
                    titleLabel="{x} Al�quota"
                    name="aliquot"
                    readOnly
                    valueInput={saleTax.AliquotaISS}
                    disabled
                    iconEnabled={INTERNAL_ICON.PERCENTAGE_ICON}
                    classNameWrapper="fill-50-field-tooltip"
                    required
                  />
                </FormRow>

                <FormRow withShrink>
                  <GenericField
                    titleLabel="Valor L�quido"
                    disabled
                    readOnly
                    valueInput={saleTax.ValorLiquido}
                    iconEnabled={INTERNAL_ICON.REAIS_ICON}
                    classNameWrapper="fill-50-field-tooltip"
                    required
                  />

                  <GenericField
                    titleLabel="Valor do ISS"
                    valueInput={saleTax.ValorISS}
                    readOnly
                    disabled
                    iconEnabled={INTERNAL_ICON.REAIS_ICON}
                    classNameWrapper="fill-50-field-tooltip"
                    required
                  />
                </FormRow>
              </>
            )}
          </ToggleInvoice>
        )}
        <ToggleInvoice title="Forma de Pagamento" startExpanded>
          {loadingPayments ? (
            <LoadScreen />
          ) : (
            <>
              <FormRow withShrink>
                <FormFieldDrop
                  titleLabel="Forma de Pagamento"
                  defaultValueSelect="Selecione..."
                  onChangeValue={(val) => {
                    setPaymentForm(val.value);
                    const card =
                      val.value.Descricao === 'Cart�o de Cr�dito' ||
                      val.value.Descricao === 'Cart�o de D�bito';
                    setCard(card);
                    resetCardTax();
                  }}
                  value={paymentForm}
                  className="fill-50-field"
                  infosSelect={paymentForms}
                  dataItemKey="Id"
                  textField="Descricao"
                  name="FormaPagamento"
                />
                {card && (
                  <>
                    <FormFieldDrop
                      titleLabel="Cart�o"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        setCardTax(val.value);
                        const calculatedCardTax = calculateCardTax(
                          paymentForm,
                          parcelNumber,
                          val.value
                        );
                        launchCardTax(calculatedCardTax);
                        const itemAccount = accounts.find(
                          (element) => element.Id === val.value.ContaId
                        );
                        setAccount(itemAccount);
                        setParcelNumber(null);
                        setParcelPeriod(null);
                        setParcelType('Sem parcelamento');
                        setSellPayments([]);
                      }}
                      value={cardTax}
                      className="fill-50-field"
                      infosSelect={cardTaxes}
                      dataItemKey="Operadora"
                      textField="Operadora"
                    />
                  </>
                )}
                {!card && (
                  <>
                    <FormFieldDrop
                      titleLabel="Local de Pagamento"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        setAccount(val.value);
                        addPayments(
                          parcelPeriod,
                          parcelNumber,
                          paymentForm,
                          val.value,
                          form.DataVencimento
                        );
                      }}
                      value={account}
                      className="fill-50-field"
                      infosSelect={accounts}
                      dataItemKey="Id"
                      textField="Nome"
                    />
                  </>
                )}
              </FormRow>
              <FormRow withShrink>
                <FormFieldDrop
                  titleLabel="Tipo de Parcelamento"
                  defaultValueSelect="Selecione..."
                  onChangeValue={(val) => {
                    if (val.value) {
                      if (
                        val.value !== 'Sem parcelamento' &&
                        paymentForm?.Descricao === 'Cart�o de D�bito'
                      ) {
                        warningNotification('Cart�o de d�bito n�o pode ser parcelado');
                      } else {
                        setSellPayments([]);
                        let calculatedCardTax;
                        if (val.value === 'Sem parcelamento' && card) {
                          calculatedCardTax = calculateCardTax(paymentForm, null, cardTax);
                          launchCardTax(calculatedCardTax);
                        }
                        setParcelType(val.value);
                      }
                    }
                  }}
                  value={parcelType}
                  className="fill-30-field"
                  infosSelect={types}
                  name="TipoPagamento"
                  required
                />
                {parcelType !== 'Sem parcelamento' && !card && (
                  <>
                    <GenericField
                      titleLabel="N�mero de Parcelas"
                      onChangeValue={(val) => {
                        const {
                          target: { value }
                        } = val;
                        addPayments(parcelPeriod, value, paymentForm, account, form.DataVencimento);
                        setParcelNumber(value);
                      }}
                      valueInput={parcelNumber}
                      classNameWrapper="fill-30-field"
                      typeNumber
                    />
                    <FormFieldDrop
                      titleLabel="Periodicidade"
                      defaultValueSelect="Selecione..."
                      onChangeValue={(val) => {
                        const {
                          target: { value }
                        } = val;
                        addPayments(value, parcelNumber, paymentForm, account, form.DataVencimento);
                        setParcelPeriod(value);
                      }}
                      value={parcelPeriod}
                      name="Periodicidade"
                      className="fill-30-field"
                      infosSelect={frequencies}
                    />
                  </>
                )}
                {parcelType !== 'Sem parcelamento' && card && (
                  <>
                    <GenericField
                      titleLabel="N�mero de Parcelas"
                      onChangeValue={async (val) => {
                        const {
                          target: { value }
                        } = val;
                        const calculatedCardTax = calculateCardTax(paymentForm, value, cardTax);
                        const newClassifications = await launchCardTax(calculatedCardTax);
                        addPayments(
                          parcelPeriod,
                          value,
                          paymentForm,
                          account,
                          form.DataVencimento,
                          newClassifications
                        );
                        setParcelNumber(value);
                      }}
                      valueInput={parcelNumber}
                      classNameWrapper="fill-30-field"
                      typeNumber
                    />
                  </>
                )}
              </FormRow>
              <FormRow withShrink>
                <FormFieldDate
                  titleLabel="Data de Refer�ncia"
                  value={form.DataVencimento}
                  valueOnChange={(val) => {
                    updateFormValue('DataVencimento', val);
                    addPayments(parcelPeriod, parcelNumber, paymentForm, account, val);
                  }}
                  className="fill-30-field"
                  required
                />
              </FormRow>
              {parcelType !== 'Sem parcelamento' && (
                <>
                  <FormRow withShrink>
                    <GridInline
                      data={sellPayments}
                      setData={setSellPayments}
                      deleteColumn={!invoiced}
                    >
                      <GridColumn
                        field="DataVencimento"
                        title="Vencimento"
                        editable={!invoiced}
                        cell={DateCell}
                        footerCell={(props) => TotalTextCell(props)}
                      />
                      <GridColumn
                        field="Valor"
                        title="Valor a Receber"
                        editor="numeric"
                        cell={NumberCell}
                        editable={!invoiced}
                        footerCell={(props) => TotalCell(props, sellPayments, 'Valor')}
                      />
                      <GridColumn
                        field="FormaPagamento"
                        title="Forma de Pagamento"
                        editable={!invoiced}
                        cell={(props) =>
                          DropDownCell(props, paymentForms, 'Id', 'Descricao', invoiced)
                        }
                      />
                      <GridColumn
                        field="ContaPagamento"
                        title="Local de Pagamento"
                        editable={!invoiced}
                        cell={(props) => DropDownCell(props, accounts, 'Id', 'Nome', invoiced)}
                      />
                    </GridInline>
                  </FormRow>
                  <FormRow justifyEnd space10>
                    <OrangeButton
                      onClick={() => {
                        addPayment();
                      }}
                    >
                      Adicionar Parcela
                    </OrangeButton>
                  </FormRow>
                </>
              )}
            </>
          )}
        </ToggleInvoice>
        <ToggleInvoice title="Classifica��o" startExpanded>
          {loadingClassifications ? (
            <LoadScreen />
          ) : (
            <>
              <FormRow withShrink>
                <GridRowSpan
                  data={sellClassifications}
                  setData={setSellClassifications}
                  commandColumn={!invoiced}
                  popUpCommandColumn={(val) => setPopUpClassification(val)}
                  rowSpanField="Descricao"
                >
                  <GridColumn field="Descricao" title="Descri��o" editable={true} />
                  <GridColumn
                    field="PlanoDeContas"
                    title="Plano de Contas"
                    cell={(props) => DropDownCell(props, planAccounts, 'Id', 'Nome')}
                  />
                  <GridColumn
                    field="CentroDeCustos"
                    title="Centro de Custo"
                    cell={(props) => DropDownCell(props, centerCosts, 'Id', 'Nome')}
                    footerCell={(props) => TotalTextCell(props)}
                  />
                  <GridColumn
                    field="Valor"
                    title="Valor"
                    editor="numeric"
                    cell={NumberCell}
                    footerCell={(props) => TotalCell(props, sellClassifications, 'Valor')}
                  />
                </GridRowSpan>
              </FormRow>
            </>
          )}
        </ToggleInvoice>
        {document?.TipoDocumentoId !== 2 && document?.TipoDocumentoId !== 4 && (
          <ToggleInvoice title="Resumo da Nota Fiscal">
            {loadingDiv ? (
              <LoadScreen />
            ) : (
              <FormRow>
                <GenericField
                  titleLabel="Discrimina��o dos Servi�os"
                  classNameWrapper="fill-100-field"
                  isTextArea
                  rows={10}
                  valueInput={discrimination}
                  disabled
                />
              </FormRow>
            )}
            <FormRow justifyEnd space10>
              <OrangeButton
                onClick={() => {
                  generateDiscrimination();
                }}
                disable={invoiced}
              >
                Gerar Texto Discrimina��o da Nota
              </OrangeButton>
            </FormRow>
          </ToggleInvoice>
        )}
        {document?.TipoDocumentoId === 4 && (
          <ToggleInvoice title="Lan�amentos Associados" startExpanded>
            <Transaction
              invoiceId={id}
              setTransactionsIds={setDebitNoteIds}
              onItemDelete={onItemDeleteTransaction}
              deleteColumn={!invoiced}
            />
          </ToggleInvoice>
        )}
        <FormRow justifyEnd space15 marginTop40>
          {invoiced && (
            <>
              <OrangeButton type="submit" name="imprimir">
                Imprimir
              </OrangeButton>
              <OrangeButton type="submit" name="pagar">
                Atualizar Pagamento
              </OrangeButton>
              <WhiteButton onClick={() => redirectToInvoices(id)}>Cancelar</WhiteButton>
            </>
          )}
          {!invoiced && document?.TipoDocumentoId !== 2 && document?.TipoDocumentoId !== 4 && (
            <>
              <OrangeButton type="submit" name="salvarTransmitir">
                Salvar e Transmitir
              </OrangeButton>
              <OrangeButton type="submit" name="salvar">
                Salvar
              </OrangeButton>
              <WhiteButton onClick={() => redirectToInvoices(id)}>Cancelar</WhiteButton>
            </>
          )}
          {!invoiced && document?.TipoDocumentoId === 4 && (
            <OrangeButton type="submit" name="imprimir">
              Pr�via
            </OrangeButton>
          )}
          {!invoiced && (document?.TipoDocumentoId === 2 || document?.TipoDocumentoId === 4) && (
            <>
              <OrangeButton type="submit" name="salvarTransmitir">
                Salvar e Gerar
              </OrangeButton>
              <OrangeButton type="submit" name="salvar">
                Salvar
              </OrangeButton>
              <WhiteButton onClick={() => redirectToInvoices(id)}>Cancelar</WhiteButton>
            </>
          )}
        </FormRow>
      </form>
      <PopupAddService
        visible={popUpsVisibilities.addService}
        setVisible={(val) => setPopUpVisibility('addService', val)}
        addService={(service) => addService(service)}
      />
      <PopupAddClassification
        visible={popUpsVisibilities.addClassification}
        setVisible={(val) => setPopUpVisibility('addClassification', val)}
        addClassification={(classification) => addClassification(classification)}
        planAccounts={planAccounts}
        centerCosts={centerCosts}
      />
      <PopupDivideClassification
        visible={popUpsVisibilities.divideClassification}
        setVisible={(val) => setPopUpVisibility('divideClassification', val)}
        addClassification={(classifications) => addClassification(classifications)}
        planAccounts={planAccounts}
        centerCosts={centerCosts}
        classification={classification}
      />
      <PopUpViewer
        visible={popUpViewer}
        setVisible={setPopUpViewer}
        title="Pr�-visualiza��o"
        data={dataViewer}
        type="application/pdf"
      />
      <PopUpConfirm
        onConfirm={submitFormValidated}
        visible={popUpConfirm}
        setVisible={setPopUpConfirm}
        title="Atualizar Documento"
        message="Existem transa��es baixadas para este documento no NEXTFinance. Todas as transa��es do NEXTFinance ser�o substitu�das e retornar�o para o contas a receber. Deseja continuar?"
      />
    </>
  );
};

export default InvoiceRegistration;

export const convertNumberToPoints = (number) => {
  let intPartWithPoints = '';
  let intPart = number;

  for (let i = 0; i < intPart.length; i++) {
    const inverseIdx = intPart.length - (i + 1);

    if (i % 3 === 0 && i !== 0) intPartWithPoints = `.${intPartWithPoints}`;
    intPartWithPoints = intPart[inverseIdx] + intPartWithPoints;
  }

  return intPartWithPoints;
};

/**
 *
 * @param {string} stringNumber string with comma as decimal divisor
 * @returns {number} number from string parameter
 */
export const parseFloatFromApi = (stringNumber) => parseFloat(stringNumber.replaceAll(',', '.'));

export const parseFloatToApi = (decimalNumber) => {
  if (decimalNumber) {
    return decimalNumber.toString().replaceAll('.', ',');
  } else {
    return null;
  }
};

export const parcel = (value, number) => {
  let parcel;
  if (value >= 0)
    parcel = Math.floor(100 * value / number) / 100;
  else
    parcel = Math.ceiling(100 * value / number) / 100;

  let parcels = [0];

  if (parcel * number != value) {
    parcels[0] = value - (parcel * number)
  }

  for (let i = 0; i < number; i++) {
    let element = parcel;
    if (i == 0) {
      element += parcels[i];
      parcels[i] = element;
    } else {
      parcels.push(element);
    }
  }
  return parcels;
};

export const Round2 = (value) => {
  return Math.round(value * 100) / 100;
}

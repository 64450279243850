import pt from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import 'react-datepicker/dist/react-datepicker.css';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import caGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/pt/dateFields.json';
import numbers from 'cldr-numbers-full/main/pt/numbers.json';
import ptMessages from '../../../utils/pt.json';
import './styles.css';

load(likelySubtags, weekData, caGregorian, dateFields, numbers);
loadMessages(ptMessages, 'pt');

const Calendar = ({ date, setDate, wrapperClassName, ...other }) => {
  registerLocale('pt', pt);

  wrapperClassName = other.disabled ? `k-disabled ${wrapperClassName}` : wrapperClassName;
  return (
    <LocalizationProvider language="pt">
      <IntlProvider locale="pt">
        <DatePicker
          value={date}
          onChange={(val) => {
            setDate(val.value);
          }}
          wrapperClassName={wrapperClassName}
          {...other}
        />
      </IntlProvider>
    </LocalizationProvider>
  );
};

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  wrapperClassName: PropTypes.string
};

Calendar.defaultProps = {
  wrapperClassName: ''
};

export default Calendar;

import React, { useContext, useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { signIn } from '../../services/login';
import './style.css';

export default function LoginScreen() {
  const { setUser } = useContext(AuthContext);

  const [form, setForm] = useState({
    Email: process.env.REACT_APP_LOCAL_EMAIL || '',
    Senha: process.env.REACT_APP_LOCAL_PASS || ''
  });

  const changeFunction = ({ target }) => {
    setForm({
      ...form,
      [target.name]: target.value
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        signIn(form, setUser);
      }}
      className="LoginForm"
    >
      <h1>ACESSANDO {process.env.REACT_APP_API_URL}</h1>
      <label htmlFor="emailInput">
        Email
        <input
          type="text"
          name="Email"
          id="emailInput"
          value={form.Email}
          onChange={changeFunction}
        />
      </label>
      <label htmlFor="senhaInput">
        Senha
        <input
          type="password"
          name="Senha"
          id="senhaInput"
          value={form.Senha}
          onChange={changeFunction}
        />
      </label>
      <button
        type="submit"
        style={{
          width: '20vw',
          height: '20vh'
        }}
      >
        Login
      </button>
    </form>
  );
}

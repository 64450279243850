import React, { useEffect, useState } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import PropTypes from 'prop-types';
import GenericField from '../../formField/genericField';
import Popup from '../layout';
import FormRow from '../../formRow';
import GridInline from '../../grid';

const PopUpTaxClass = ({ visible, setVisible, taxClass }) => {
  const [name, setName] = useState(null);
  const [taxesISS, setTaxesISS] = useState([]);
  const [taxes, setTaxes] = useState([]);

  useEffect(async () => {
    if (taxClass) {
      setName(taxClass.Nome);
      setTaxesISS([
        {
          Id: 'ISS',
          BaseCalculo: taxClass.BaseCalculoISS,
          Aliquota: taxClass.AliquotaISS
        }
      ]);
      const taxes = [];
      taxes.push({
        Id: 'PIS',
        BaseCalculo: taxClass.BaseCalculoPIS,
        Aliquota: taxClass.AliquotaPIS
      });
      taxes.push({
        Id: 'COFINS',
        BaseCalculo: taxClass.BaseCalculoCOFINS,
        Aliquota: taxClass.AliquotaCOFINS
      });
      taxes.push({
        Id: 'CSLL',
        BaseCalculo: taxClass.BaseCalculoCSLL,
        Aliquota: taxClass.AliquotaCSLL
      });
      taxes.push({
        Id: 'IRRF',
        BaseCalculo: taxClass.BaseCalculoIRRF,
        Aliquota: taxClass.AliquotaIRRF
      });
      taxes.push({
        Id: 'INSS',
        BaseCalculo: taxClass.BaseCalculoINSS,
        Aliquota: taxClass.AliquotaINSS
      });
      setTaxes(taxes);
    } else {
      setTaxesISS([{ Id: 'ISS', BaseCalculo: 100, Aliquota: null }]);
      const taxes = [];
      taxes.push({ Id: 'PIS', BaseCalculo: 100, Aliquota: null });
      taxes.push({ Id: 'COFINS', BaseCalculo: 100, Aliquota: null });
      taxes.push({ Id: 'CSLL', BaseCalculo: 100, Aliquota: null });
      taxes.push({ Id: 'IRRF', BaseCalculo: 100, Aliquota: null });
      taxes.push({ Id: 'INSS', BaseCalculo: 100, Aliquota: null });
      setTaxes(taxes);
    }
  }, [taxClass, visible]);

  return (
    <Popup
      title="Classe de Impostos"
      width={700}
      visible={visible}
      setVisible={setVisible}
      cancelText="OK"
      haveConfirm={false}
    >
      <>
        <FormRow>
          <GenericField
            titleLabel="Nome"
            classNameWrapper="fill-50-field"
            valueInput={name}
            onChangeValue={(val) => {
              setName(val.value);
            }}
            disabled
          />
        </FormRow>
        <FormRow>
          <GridInline data={taxesISS} setData={setTaxesISS}>
            <GridColumn field="Id" title="Imposto Municipal" editable={false} />
            <GridColumn
              field="BaseCalculo"
              title="Base de C�lculo (%)"
              editable={false}
              editor="numeric"
              format="{0:n2}"
            />
            <GridColumn
              field="Aliquota"
              title="Al�quota (%)"
              editable={false}
              editor="numeric"
              format="{0:n2}"
            />
          </GridInline>
        </FormRow>
        <FormRow>
          <GridInline data={taxes} setData={setTaxes}>
            <GridColumn field="Id" title="Reten��es Federais" editable={false} />
            <GridColumn
              field="BaseCalculo"
              title="Base de C�lculo (%)"
              editable={false}
              editor="numeric"
              format="{0:n2}"
            />
            <GridColumn
              field="Aliquota"
              title="Al�quota (%)"
              editable={false}
              editor="numeric"
              format="{0:n2}"
            />
          </GridInline>
        </FormRow>
      </>
    </Popup>
  );
};

PopUpTaxClass.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpTaxClass;

import PropTypes from 'prop-types';
import { useState } from 'react';
import Popup from '../layout';
import FormFieldDrop from '../../formField/drop';
import FormRow from '../../formRow';

const PopUpCancelInvoice = ({ onConfirm, visible, setVisible, title, message }) => {
  const _message = message != null ? message : 'Voc� tem certeza que deseja realizar a opera��o?';
  const [reason, setReason] = useState({
    Id: '2',
    Descricao: 'Servi�o N�o Conclu�do'
  });
  const reasons = [
    {
      Id: '1',
      Descricao: 'Erro de Emiss�o'
    },
    {
      Id: '2',
      Descricao: 'Servi�o N�o Conclu�do'
    }
  ];
  const confirmCancel = () => {
    onConfirm('2');
  };

  return (
    <Popup
      title={title}
      width={700}
      visible={visible}
      setVisible={setVisible}
      confirmText="Sim"
      cancelText="N�o"
      onConfirm={confirmCancel}
    >
      <span style={{ marginTop: '10px' }}>{_message}</span>
      {/* <FormRow withShrink>
        <FormFieldDrop
          titleLabel="Motivo do Cancelamento"
          defaultValueSelect="Selecione..."
          onChangeValue={(val) => {
            setReason(val.value);
          }}
          value={reason}
          className="fill-50-field"
          infosSelect={reasons}
          dataItemKey="Id"
          textField="Descricao"
        />
      </FormRow> */}
    </Popup>
  );
};

PopUpCancelInvoice.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

export default PopUpCancelInvoice;

import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getReceivingLocal = async () => {
    const { data } = await axiosWalletRequest('Conta/ListarContasCorrentesEContasCaixas', METHOD.GET);
    return data;
};

export const getPaymentLocal = async () => {
    const { data } = await axiosWalletRequest('Conta/ListarContasCorrentesECaixasECartoesCredito', METHOD.GET);
    return data;
};

export const getReceivablePayableAccount = async () => {
    const { data } = await axiosWalletRequest('Conta/ListarContasAPagarAReceberEmCobranca', METHOD.GET);
    return data;
};

export const getById = (id) => axiosWalletRequest(`Conta/${id}`, METHOD.GET);
import React, { useState } from 'react';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import Calendar from '../calendar';
import { INTERNAL_BUTTON } from '../../button';
import './styles.css';

const DateFilter = ({ date1, date2, setDate }) => {
  const [_date1, _setDate1] = useState(date1);
  const [_date2, _setDate2] = useState(date2);
  const begin = 'De';
  const end = 'At�';
  return (
    <div className="calendar-invoice">
      {begin}
      <span> </span>
      <Calendar date={_date1} setDate={_setDate1} />
      <span> </span>
      {end}
      <span> </span>
      <Calendar date={_date2} setDate={_setDate2} />
      <span> </span>
      <div className="config-components-popup-button">
        <FloatingActionButton
          icon={INTERNAL_BUTTON.BUTTON_FILTER}
          onClick={() => setDate(_date1, _date2)}
        />
      </div>
    </div>
  );
};

export default DateFilter;

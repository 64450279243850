import { axiosWalletRequest, METHOD } from '../api/Requests';

export const getAll = () => axiosWalletRequest('Atendimento/', METHOD.GET);

export const getByDate = (dateInverval) => axiosWalletRequest('Atendimento/Filtrar', METHOD.POST, dateInverval);

export const getById = (id) => axiosWalletRequest(`Atendimento/${id}`, METHOD.GET);

export const create = (body) => axiosWalletRequest('Atendimento', METHOD.POST, body);

export const exclude = (id) => axiosWalletRequest(`Atendimento/${id}`, METHOD.DELETE);

export const update = (id, body) => axiosWalletRequest(`Atendimento/${id}`, METHOD.PUT, body);

export const send = (id) => axiosWalletRequest(`Atendimento/Enviar/${id}`, METHOD.GET);

export const generateSale = (id, idDocument) => axiosWalletRequest(`Atendimento/GerarVenda/${id}/${idDocument}`, METHOD.GET);

export const getAttendanceNumber = async () => {
    const { data } = await axiosWalletRequest(`Atendimento/ObterNumeroAtendimento`, METHOD.GET);
    return data;
}

export const getLast = async () => {
    const { data } = await axiosWalletRequest(`Atendimento/ObterUltimoAtendimentoPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.Data);
    return null;
}

export const getFirst = async () => {
    const { data } = await axiosWalletRequest(`Atendimento/ObterPrimeiroAtendimentoPorData`, METHOD.GET);
    if (data !== undefined && data !== null)
        return new Date(data.Data);
    return null;
}

export const getParcelsByDate = (dateInverval) => axiosWalletRequest('Atendimento/FiltrarParcela', METHOD.POST, dateInverval);

export const getAttendanceTypes = async () => await axiosWalletRequest(`Atendimento/ListarTipoAtendimento`, METHOD.GET);

export const createAttendanceType = async (body) => await axiosWalletRequest('Atendimento/CriarTipoAtendimento', METHOD.POST, body);

export const updateAttendanceType = (body, id) => axiosWalletRequest(`Atendimento/AtualizarTipoAtendimento/${id}`, METHOD.PUT, body);

export const excludeAttendanceType = (id) => axiosWalletRequest(`Atendimento/ExcluirTipoAtendimento/${id}`, METHOD.DELETE);

export const generateInvoice = async (id) => await axiosWalletRequest(`Atendimento/GerarNotaFiscal/${id}`, METHOD.GET);

export const generateContract = (id, idContractType) => axiosWalletRequest(`Atendimento/GerarContrato/${id}/${idContractType}`, METHOD.GET);

export const generatePDF = (id) => axiosWalletRequest(`Atendimento/GerarPDF/${id}`, METHOD.GET);
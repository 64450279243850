import { axiosWalletRequest, METHOD } from '../api/Requests';
import { sortArrayByField } from '../utils/arrays';

export const getAll = async () => {
  const response = await axiosWalletRequest('PlanoDeContas', METHOD.GET);

  return {
    ...response,
    data: sortArrayByField(response.data, 'Nome')
  };
};

export const getAllExpensesRevenues = async () => {
  const { data } = await getAll();
  return data.filter(element => (element.NomeTipoPlanoDeContas === 'Receita' || element.NomeTipoPlanoDeContas === 'Despesa'))
}

export const getAllRevenues = async () => {
  const { data } = await getAll();
  return data.filter(element => element.NomeTipoPlanoDeContas === 'Receita')
}

export const getAllExpenses = async () => {
  const { data } = await getAll();
  return data.filter(element => element.NomeTipoPlanoDeContas === 'Despesa')
}

export const getById = (id) => axiosWalletRequest(`PlanoDeContas/${id}`, METHOD.GET);

export const create = (bodyRequest) => axiosWalletRequest('PlanoDeContas', METHOD.POST, bodyRequest);

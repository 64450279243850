import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { convertNumberToPoints } from '../../../../../../utils/number';

const PercentageInput = ({ className, setValue, value, ...others }) => {
  const sendToEnd = (props) => {
    // TODO
  };

  const [valueView, setValueView] = useState('');

  // TODO poderia ser um useRef
  const [haveQuote, setHaveQuote] = useState(false);

  useEffect(() => {
    const convertValueToView = () => {
      let intPart;
      let decimalPart;

      if (value || value === 0) {
        const parts = value.toString().split('.');
        [intPart, decimalPart] = parts;
      } else {
        intPart = '';
        decimalPart = '';
      }

      let newValueView = convertNumberToPoints(intPart);
      if (decimalPart) {
        setHaveQuote(true);
        newValueView = `${newValueView},${decimalPart}`;
      }
      setValueView(newValueView);
    };

    convertValueToView();
  }, [value]);

  const updateValue = (digit) => {
    const isQuote = digit === ',';
    const isBackspace = digit === 'Backspace';

    if (isQuote) {
      if (!haveQuote) setHaveQuote(true);
    }

    let intPartWithPoints;
    let decimalPart;
    if (value) {
      const parts = value.toString().split('.');
      [intPartWithPoints, decimalPart] = parts;
    } else {
      intPartWithPoints = '';
      decimalPart = '';
    }

    if (isQuote) {
      if (!haveQuote && (value || value === 0)) {
        setValueView(`${valueView},`);
      }
    } else {
      if (haveQuote) {
        if (isBackspace) {
          if (!decimalPart) {
            setHaveQuote(false);
            setValue(parseFloat(value.toString().substring(0, value.toString().length - 1)));
            return;
          }

          decimalPart = decimalPart.substring(0, decimalPart.length - 1);
        } else {
          decimalPart = decimalPart ? decimalPart + digit : digit;

          // if (digit === '0') setValueView(`${valueView}0`);
        }
      } else {
        let intPart = intPartWithPoints;
        intPartWithPoints = '';

        intPart = isBackspace ? intPart.substring(0, intPart.length - 1) : intPart + digit;

        intPartWithPoints = intPart;
      }

      setValue(
        `${intPartWithPoints}${haveQuote ? '.' : ''}${decimalPart !== undefined ? decimalPart : ''}`
      );
    }
  };

  return (
    <input
      {...others}
      type="text"
      value={valueView}
      className={className}
      onKeyDown={({ key }) => {
        if (/[0-9,]|Backspace/.test(key)) updateValue(key);
      }}
      onFocus={sendToEnd}
      onSelect={sendToEnd}
    />
  );
};

PercentageInput.propTypes = {
  className: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
};

PercentageInput.defaultProps = {
  className: ''
};

export default PercentageInput;

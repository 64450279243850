import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GridColumn } from '@progress/kendo-react-grid';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import GenericField, { INTERNAL_ICON } from '../../../components/formField/genericField';
import FormRow from '../../../components/formRow';
import * as taxClassService from '../../../services/taxClass';
import LoadScreen from '../../load';
import { successNotification, defaultErrorNotification } from '../../../components/notification';
import { parseFloatToApi } from '../../../utils/number';
import GridInline from '../../../components/grid';
import NumberCell from '../../../components/grid/numberCell';

const TaxClassRegistration = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [taxesISS, setTaxesISS] = useState([]);
  const [taxes, setTaxes] = useState([]);

  useEffect(async () => {
    if (id) {
      const response = await taxClassService.getById(id);
      const taxClass = response.data;
      setName(taxClass.Nome);
      setTaxesISS([
        {
          Id: 'ISS',
          BaseCalculo: taxClass.BaseCalculoISS,
          Aliquota: taxClass.AliquotaISS
        }
      ]);
      const taxes = [];
      taxes.push({
        Id: 'PIS',
        BaseCalculo: taxClass.BaseCalculoPIS,
        Aliquota: taxClass.AliquotaPIS
      });
      taxes.push({
        Id: 'COFINS',
        BaseCalculo: taxClass.BaseCalculoCOFINS,
        Aliquota: taxClass.AliquotaCOFINS
      });
      taxes.push({
        Id: 'CSLL',
        BaseCalculo: taxClass.BaseCalculoCSLL,
        Aliquota: taxClass.AliquotaCSLL
      });
      taxes.push({
        Id: 'IRRF',
        BaseCalculo: taxClass.BaseCalculoIRRF,
        Aliquota: taxClass.AliquotaIRRF
      });
      taxes.push({
        Id: 'INSS',
        BaseCalculo: taxClass.BaseCalculoINSS,
        Aliquota: taxClass.AliquotaINSS
      });
      setTaxes(taxes);
    } else {
      setTaxesISS([{ Id: 'ISS', BaseCalculo: 100, Aliquota: null }]);
      const taxes = [];
      taxes.push({ Id: 'PIS', BaseCalculo: 100, Aliquota: null });
      taxes.push({ Id: 'COFINS', BaseCalculo: 100, Aliquota: null });
      taxes.push({ Id: 'CSLL', BaseCalculo: 100, Aliquota: null });
      taxes.push({ Id: 'IRRF', BaseCalculo: 100, Aliquota: null });
      taxes.push({ Id: 'INSS', BaseCalculo: 100, Aliquota: null });
      setTaxes(taxes);
    }
  }, []);

  const redirectToTaxClasses = () => history.push('/taxClasses');
  const submitCreate = async (form) => {
    const res = await taxClassService.create(form);
    return res.status === 200 ? `Classe de impostos foi criada com sucesso!` : null;
  };
  const submitEdit = async (form) => {
    const res = await taxClassService.update(id, form);
    return res.status === 200 ? `Classe de impostos foi atualizada com sucesso!` : null;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    let messageSucess = null;

    const formBody = {
      Nome: name,
      AliquotaISS: taxesISS[0]?.Aliquota,
      AliquotaPIS: taxes[0]?.Aliquota,
      AliquotaCOFINS: taxes[1]?.Aliquota,
      AliquotaCSLL: taxes[2]?.Aliquota,
      AliquotaIRRF: taxes[3]?.Aliquota,
      AliquotaINSS: taxes[4]?.Aliquota,
      BaseCalculoISS: taxesISS[0]?.BaseCalculo,
      BaseCalculoPIS: taxes[0]?.BaseCalculo,
      BaseCalculoCOFINS: taxes[1]?.BaseCalculo,
      BaseCalculoCSLL: taxes[2]?.BaseCalculo,
      BaseCalculoIRRF: taxes[3]?.BaseCalculo,
      BaseCalculoINSS: taxes[4]?.BaseCalculo
    };

    if (id) messageSucess = await submitEdit(formBody);
    else messageSucess = await submitCreate(formBody);

    setLoading(false);

    if (messageSucess) {
      successNotification(messageSucess);
      redirectToTaxClasses();
    } else defaultErrorNotification();
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      {id ? <h1>Atualizar Classe de Impostos</h1> : <h1>Nova Classe de Impostos</h1>}
      <form className="form-taxClass" onSubmit={submitForm}>
        <FormRow>
          <GenericField
            titleLabel="Nome"
            classNameWrapper="fill-50-field"
            valueInput={name}
            onChangeValue={(val) => {
              setName(val.value);
            }}
            required
          />
        </FormRow>
        <FormRow>
          <GridInline data={taxesISS} setData={setTaxesISS}>
            <GridColumn field="Id" title="Imposto Municipal" />
            <GridColumn
              field="BaseCalculo"
              title="Base de C�lculo (%)"
              editable={true}
              editor="numeric"
              cell={NumberCell}
            />
            <GridColumn
              field="Aliquota"
              title="Al�quota (%)"
              editable={true}
              editor="numeric"
              cell={NumberCell}
            />
          </GridInline>
        </FormRow>
        <FormRow>
          <GridInline data={taxes} setData={setTaxes}>
            <GridColumn field="Id" title="Reten��es Federais" />
            <GridColumn
              field="BaseCalculo"
              title="Base de C�lculo (%)"
              editable={true}
              editor="numeric"
              cell={NumberCell}
            />
            <GridColumn
              field="Aliquota"
              title="Al�quota (%)"
              editable={true}
              editor="numeric"
              cell={NumberCell}
            />
          </GridInline>
        </FormRow>
        <FormRow justifyEnd space15 marginTop40>
          <OrangeButton type="submit">Salvar</OrangeButton>
          <WhiteButton onClick={() => history.goBack()}>Cancelar</WhiteButton>
        </FormRow>
      </form>
    </>
  );
};

export default TaxClassRegistration;

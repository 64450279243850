import { useEffect, useState } from 'react';
import './style.css';

import { convertNumberToPoints } from '../../../../../../utils/number';

const moneyInput = ({ className, setValue, value, ...others }) => {
  const sendToEnd = (props) => {
    // TODO
  };

  const [valueView, setValueView] = useState('0,00');
  const [classInput, setClassInput] = useState('');

  const invertSignal = () => setValue(value * -1);

  const convertValueToView = () => {
    if (value === 0) setClassInput('');
    else if (value > 0) setClassInput('positive');
    else if (value < 0) setClassInput('negative');

    if (value === '') value = 0;

    const parts = value.toString().split('.');

    const [intPartWithSignal, decimalPartSplitted] = parts;

    let decimalPart;
    if (decimalPartSplitted && decimalPartSplitted.length > 1) decimalPart = decimalPartSplitted;
    else if (decimalPartSplitted) decimalPart = `${decimalPartSplitted}0`;
    else decimalPart = '00';

    let intPartWithPoints = '';

    let signal;
    let intPart;

    if (intPartWithSignal[0] === '-') {
      signal = '-';
      intPart = intPartWithSignal.slice(1);
    } else {
      signal = '';
      intPart = intPartWithSignal;
    }

    intPartWithPoints = convertNumberToPoints(intPart);

    setValueView(`${signal}${intPartWithPoints},${decimalPart}`);
  };

  useEffect(() => convertValueToView(), [value]);

  const deleteDigit = () => setValue(Math.floor(value * 10) / 100);

  // FIXME a partir da 15� casa de milhar para de funcionar
  const updateValue = (digit) => {
    const parts = (value * 10).toFixed(1).toString().split('.');

    let [, decimalPart] = parts;

    decimalPart = decimalPart ? decimalPart + digit : `0${digit}`;

    setValue(parseFloat(`${parts[0]}.${decimalPart}`));
  };

  return (
    <input
      {...others}
      type="text"
      value={valueView}
      className={`${className} ${classInput} money-input`}
      onKeyDown={({ key, ...otherProps }) => {
        if (/[0-9]/.test(key)) updateValue(key);
        else if (/Backspace/.test(key)) deleteDigit();
        else if (/-/.test(key)) invertSignal();

        sendToEnd(otherProps);
      }}
      onFocus={sendToEnd}
      onSelect={sendToEnd}
      onChange={() => {
        // do nothing
      }}
    />
  );
};

export default moneyInput;

import React, { useEffect, useState, useContext } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { useHistory } from 'react-router-dom';
import { useForms } from '../../../hooks';
import { AuthContext } from '../../../auth/AuthContext';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import FormRow from '../../../components/formRow';
import * as planAccountService from '../../../services/planAccount';
import * as centerCostService from '../../../services/centerCost';
import GridInline from '../../../components/grid';
import DropDownCell from '../../../components/grid/dropDownCell';
import { recoverWalletByJwt } from '../../../services/wallet';
import './style.css';
import {
  errorNotification,
  successNotification,
  defaultErrorNotification,
  warningNotification
} from '../../../components/notification';
import LoadScreen from '../../load';
import * as businessWalletService from '../../../services/businessWallet';

const Classification = () => {
  const { walletSelected, setWalletSelected } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [saleSettings, setSaleSettings] = useState([]);
  const [planAccounts, setPlanAccounts] = useState([]);
  const [centerCosts, setCenterCosts] = useState([]);
  const initialForm = {
    Id: '',
    ConfiguracaoVenda: null
  };
  const [form, updateFormValue, setForm, updateFormProperty] = useForms(initialForm);

  const loadSaleSettings = async (id, planAccounts, centerCosts) => {
    const { data: saleSettingsFromApi } = await businessWalletService.getSaleSettings(id);
    const newSaleSettings = [];
    saleSettingsFromApi.forEach((saleSetting) => {
      const itemPlanAccount = planAccounts.find(
        (element) => element.Id === saleSetting.IdPlanoDeContas
      );
      const itemCenterCost = centerCosts.find(
        (element) => element.Id === saleSetting.IdCentroDeCustos
      );
      const newSaleSetting = {
        Id: saleSetting.Id,
        NomeTipoVenda: saleSetting.NomeTipoVenda,
        PlanoDeContas: itemPlanAccount,
        CentroDeCustos: itemCenterCost
      };
      newSaleSettings.push(newSaleSetting);
    });
    setSaleSettings(newSaleSettings);
  };

  const loadPlanAccounts = async () => {
    const data = await planAccountService.getAllExpenses();
    setPlanAccounts(data);
    return data;
  };

  const loadCenterCosts = async () => {
    const { data } = await centerCostService.getAll();
    setCenterCosts(data);
    return data;
  };

  const history = useHistory();

  const redirectToHomeList = () => history.push('/home');

  useEffect(async () => {
    setLoading(true);
    let { Id } = initialForm;
    if (walletSelected.CarteiraBusiness != null) {
      Id = walletSelected.CarteiraBusiness.Id;
      const planAccounts = await loadPlanAccounts();
      const centerCosts = await loadCenterCosts();
      await loadSaleSettings(Id, planAccounts, centerCosts);
    } else {
      warningNotification('N�o existe um carteira Next Business a carteira do Next Finance.');
    }
    setForm({
      Id,
      ConfiguracaoVenda: null
    });
    setLoading(false);
  }, []);

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    const newSaleSettings = saleSettings.map((x) => ({
      Id: x.Id,
      PlanoDeContasId: x.PlanoDeContas?.Id,
      CentroDeCustosId: x.CentroDeCustos?.Id
    }));
    const userBodyForm = {
      Id: form.Id,
      ConfiguracaoVenda: newSaleSettings
    };

    const updateWalletPromise = await businessWalletService.updateSellClassification(
      form.Id,
      userBodyForm
    );

    const [updateWalletResponse] = await Promise.all([updateWalletPromise]);

    if (updateWalletResponse.status === 200) {
      const walletData = await recoverWalletByJwt();
      setWalletSelected(walletData);
      successNotification('Carteira atualizada com sucesso!');
    } else {
      defaultErrorNotification();
    }

    setLoading(false);
  };

  if (loading) return <LoadScreen />;
  return (
    <div id="settings-page">
      <h1>Classifica��es Padr�o</h1>
      <h5 id="label-h5">
        Fa�a a associa��o dos seguintes itens do seu plano de contas e centro de custo para
        automatizar a classifica��o de documentos emitidos.
      </h5>
      <form onSubmit={submitForm}>
        <FormRow>
          <GridInline data={saleSettings} setData={setSaleSettings}>
            <GridColumn field="NomeTipoVenda" title="Nome" editable={true} />
            <GridColumn
              field="PlanoDeContas"
              title="Plano de Contas"
              editable={true}
              cell={(props) => DropDownCell(props, planAccounts, 'Id', 'Nome')}
            />
            <GridColumn
              field="CentroDeCustos"
              title="Centro de Custo"
              editable={true}
              cell={(props) => DropDownCell(props, centerCosts, 'Id', 'Nome')}
            />
          </GridInline>
        </FormRow>
        <FormRow justifyEnd space15 marginTop40>
          <OrangeButton type="submit">Salvar</OrangeButton>
          <WhiteButton onClick={() => history.goBack()}>Cancelar</WhiteButton>
        </FormRow>
      </form>
    </div>
  );
};

export default Classification;

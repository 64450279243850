const moneyFormatter = new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" });

/**
 * 
 * @param {number} entry Valor que deseja formatar.
 * @param {boolean} withoutCoin Para desativar a moeda e retornar somente o valor.
 * Seu valor padr�o � `FALSE`
 * @returns {string} Valor formatado.
 */
export const toMoney = (entry, withoutCoin = false) => {
  if (entry != null) {
    const formatted = moneyFormatter.format(entry);
    return withoutCoin ? formatted.slice(3) : formatted;
  }
  return null;
}

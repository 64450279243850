/* eslint no-restricted-syntax: 0 */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FloatingActionButton } from '@progress/kendo-react-buttons';
import PopUpButton, { INTERNAL_BUTTON, INTERNAL_ITEMS } from '../../../components/button';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import FormRow from '../../../components/formRow';
import { CalendarWithButtons } from '../../../components/dateComponents';
import Table from '../../../components/table';
import PopUpError from '../../../components/popUps/popUpError';
import './styles.css';
import LoadScreen from '../../load';
import * as contractService from '../../../services/contract';
import * as transactionService from '../../../services/transaction';
import { successNotification, warningNotification } from '../../../components/notification';
import { FirstDayOfMonth, LastDayOfMonth, DateNow } from '../../../utils/date';
import PopUpConfirm from '../../../components/popUps/popUpConfirm';

const GenerateInvoice = () => {
  const filterDate = window.localStorage.getItem('FILTER_DATE');
  const editId = window.localStorage.getItem('EDIT_ID');
  const history = useHistory();
  const [contractParcels, setContractParcels] = useState([]);
  const [date, setDate] = useState(filterDate ? new Date(filterDate) : DateNow());
  const [parcelsSelectedsIds, setParcelsSelectedsIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popUpConfirmGenerate, setPopUpConfirmGenerate] = useState(false);
  const [popUpError, setPopUpError] = useState(false);
  const [popUpErrorTitle, setPopUpErrorTitle] = useState('');
  const [popUpErrorMessage, setPopUpErrorMessage] = useState('');
  const [rowIndex, setRowIndex] = useState(0);
  const [paymentForms, setPaymentForms] = useState([]);

  const columns = [
    {
      field: 'Numero',
      title: 'Contrato',
      width: 90,
      cell: (props) => <td style={{ textAlign: 'right' }}>{props.dataItem.Numero}</td>,
      columnMenuCheckbox: true
    },
    {
      field: 'PessoaNome',
      title: 'Nome',
      width: 300,
      columnMenuCheckbox: true
    },
    {
      field: 'FormaPagamento',
      title: 'Forma de Pagamento',
      columnMenu: true
    },
    {
      field: 'TipoDocumentoNome',
      title: 'Tipo',
      columnMenuCheckbox: true
    },
    {
      field: 'DataVencimento',
      title: 'Data de Vencimento da Parcela',
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      columnMenu: true
    },
    {
      field: 'Valor',
      title: 'Valor (R$)',
      isCash: true,
      filter: 'numeric',
      format: '{0:c}',
      totalCell: true,
      columnMenu: true
    }
  ];

  const loadPaymentForms = async () => {
    let { data } = await transactionService.getPaymentForms();
    data = data['000'];
    data.sort((a, b) => {
      if (a.Descricao < b.Descricao) {
        return -1;
      }
      if (a.Descricao > b.Descricao) {
        return 1;
      }
      return 0;
    });
    setPaymentForms(data);
    return data;
  };

  const seekScroll = async (data) => {
    if (editId != null) {
      const index = data.findIndex((item) => item.Id === editId);
      setRowIndex(index);
    } else {
      setRowIndex(data.length - 1);
    }
  };

  const fetchContractParcels = async (paymentForms) => {
    setLoading(true);
    const dateInterval = {
      DataInicio: FirstDayOfMonth(date),
      DataFim: LastDayOfMonth(date)
    };
    let { data } = await contractService.getParcelsByDate(dateInterval);
    data = data.map((item) => {
      const dataVencimento = new Date(item.DataVencimento);
      dataVencimento.setHours(0, 0, 0, 0);
      const itemPaymentForm = paymentForms.find((element) => element.Id === item.FormaPagamento);
      return {
        Id: item.LancamentoId,
        ...item,
        DataVencimento: dataVencimento,
        FormaPagamento: itemPaymentForm ? itemPaymentForm.Descricao : undefined
      };
    });
    setContractParcels(data);
    seekScroll(data);
    setLoading(false);
  };

  const redirectToInvoices = () => history.push('/invoices');

  const generateInvoice = async () => {
    setLoading(true);
    const generateInvoiceResponses = [];

    for await (const parcelSelected of parcelsSelectedsIds) {
      const response = await contractService.generateInvoice(parcelSelected);
      generateInvoiceResponses.push(response);
    }

    const isAllRequestsRight = generateInvoiceResponses.reduce(
      (acc, currentResponse) => acc && currentResponse.status === 200,
      true
    );

    setLoading(false);
    if (isAllRequestsRight) {
      successNotification('Documento(s) gerado(s) com sucesso!');
      redirectToInvoices();
    } else {
      const erro = ['Houve um erro ao tentar gerar um ou mais documentos:'];
      generateInvoiceResponses.forEach((element) => {
        if (element.status !== 200) {
          const { data } = element;
          if (data.MensagemUsuario !== undefined) erro.push(data.MensagemUsuario);
          else erro.push(data);
        }
      });
      setPopUpErrorTitle('Erro ao Gerar Documento');
      setPopUpErrorMessage(erro);
      setPopUpError(true);
      fetchContractParcels(paymentForms);
    }
  };

  useEffect(async () => {
    setLoading(true);
    const paymentForms = await loadPaymentForms();
    await fetchContractParcels(paymentForms);
    setLoading(false);
  }, [date]);

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      <div id="contract-list">
        <h1>Gerar Documentos</h1>

        <div className="buttons-contract-list">
          <CalendarWithButtons
            date={date}
            setDate={(val) => {
              setDate(val);
              window.localStorage.setItem('FILTER_DATE', val);
            }}
            service={contractService}
          />
          <PopUpButton internalButton={INTERNAL_BUTTON.BUTTON_THREEPOINTS} />
        </div>
        <Table
          name="ContractParcels"
          data={contractParcels}
          columns={columns}
          dataItemKey="Id"
          haveCheckbox
          setItemKeysSelected={setParcelsSelectedsIds}
          style={{ minHeight: 500, marginTop: 20, height: '100%' }}
          sortable={true}
          rowIndex={rowIndex}
          haveOnClick={false}
        />
        <FormRow justifyEnd space10>
          <OrangeButton
            onClick={() => {
              if (parcelsSelectedsIds.length > 0) setPopUpConfirmGenerate(true);
              else warningNotification('Nenhum contrato selecionado');
            }}
          >
            Gerar Documentos
          </OrangeButton>
          <WhiteButton onClick={() => history.goBack()}>Cancelar</WhiteButton>
        </FormRow>
      </div>
      <PopUpConfirm
        onConfirm={generateInvoice}
        visible={popUpConfirmGenerate}
        setVisible={setPopUpConfirmGenerate}
        title="Confirma��o"
        message="Voc� tem certeza que deseja gerar o documento para as parcelas dos contratos selecionadas?"
      />
      <PopUpError
        visible={popUpError}
        setVisible={setPopUpError}
        title={popUpErrorTitle}
        message={popUpErrorMessage}
      />
    </>
  );
};

export default GenerateInvoice;

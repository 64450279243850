import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormFieldDrop, FormFieldGeneric } from '../../formField';
import FormRow from '../../formRow';
import Popup from '../layout';
import { create, getAllRevenues } from '../../../services/planAccount';
import { useForms } from '../../../hooks';

const emptyForm = {
  Nome: '',
  CodigoCadastral: '',
  Descricao: '',

  planAccount: {}
};

const PopUpPlanAccount = ({ visible, setVisible, whenCreateNewPlanAccount }) => {
  const [form, updateForm, , updateProperty] = useForms(emptyForm);
  const [planAccounts, setPlanAccounts] = useState([]);

  const save = async () => {
    await create({
      ...form,
      IdPai: form.planAccount.Id
    });

    return whenCreateNewPlanAccount();
  };

  useEffect(() => {
    const fetchPlanAccounts = async () => {
      const data = await getAllRevenues();
      setPlanAccounts(data);
    };

    fetchPlanAccounts();
  }, []);

  return (
    <Popup
      visible={visible}
      setVisible={setVisible}
      title="Criar Plano de Contas"
      confirmText="Salvar"
      cancelText="Cancelar"
      onConfirm={save}
    >
      <FormRow withoutMarginTop>
        <FormFieldDrop
          titleLabel="Subplano de contas de:*"
          defaultValueSelect="Selecione..."
          className="fill-100-field"
          infosSelect={planAccounts}
          value={form.planAccount}
          onChangeValue={updateForm}
          name="planAccount"
          textField="Nome"
          dataItemKey="Id"
          information
          msg='Um plano de contas sempre deve ter um pai, exceto "Despesa" e "Receita".'
        />
      </FormRow>

      <FormRow>
        <FormFieldGeneric
          titleLabel="Nome"
          classNameWrapper="fill-100-field-tooltip"
          name="Nome"
          onChangeValue={updateForm}
          valueInput={form.Nome}
          required
        />
      </FormRow>

      <FormRow>
        <FormFieldGeneric
          titleLabel="C�digo"
          classNameWrapper="fill-100-field"
          name="CodigoCadastral"
          onChangeValue={updateForm}
          valueInput={form.CodigoCadastral}
          enableInfo
          msg="O c�digo serve para ajudar no preenchimento de outros formul�rios."
        />
      </FormRow>

      <FormRow>
        <FormFieldGeneric
          titleLabel="Descri��o"
          classNameWrapper="fill-100-field-tooltip"
          name="Descricao"
          onChangeValue={updateForm}
          valueInput={form.Descricao}
        />
      </FormRow>
    </Popup>
  );
};

PopUpPlanAccount.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  whenCreateNewPlanAccount: PropTypes.func.isRequired
};

export default PopUpPlanAccount;

import { axiosWalletRequest, METHOD } from '../api/Requests';
import { stringBase64ToByteArray, mimeType } from '../utils/download';

export const getAll = () => axiosWalletRequest('ProdutoBusiness/', METHOD.GET);

export const getById = (id) => axiosWalletRequest(`ProdutoBusiness/${id}`, METHOD.GET);

export const create = (body) => axiosWalletRequest('ProdutoBusiness', METHOD.POST, body);

export const exclude = (id) => axiosWalletRequest(`ProdutoBusiness/${id}`, METHOD.DELETE);

export const update = (id, body) => axiosWalletRequest(`ProdutoBusiness/${id}`, METHOD.PUT, body);

export const getProductClasses = async () => await axiosWalletRequest(`ProdutoBusiness/ListarClasseProduto`, METHOD.GET);

export const createProductClass = async (body) => await axiosWalletRequest('ProdutoBusiness/CriarClasseProduto', METHOD.POST, body);

export const updateProductClass = (body, id) => axiosWalletRequest(`ProdutoBusiness/AtualizarClasseProduto/${id}`, METHOD.PUT, body);

export const excludeProductClass = (id) => axiosWalletRequest(`ProdutoBusiness/ExcluirClasseProduto/${id}`, METHOD.DELETE);

export const createFile = (id, file, main) => {
    const fileName = file.name;
    const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
    const name = fileName.substr(0, fileName.lastIndexOf('.'));
    return axiosWalletRequest(`ProdutoBusiness/InserirImagem/${id}/${name}/${ext}/${main}`, METHOD.POST, file);
}

export const getFile = async (id) => {
    let { data } = await axiosWalletRequest(`ProdutoBusiness/ListarImagem/${id}`, METHOD.GET);
    let i = 1;
    data = data.map((item) => ({
        Id: i++,
        uid: i,
        name: item.Nome,
        extension: item.Extensao,
        size: item.TamanhoEmKBytes,
        getRawFile: () => {
            const byteArray = stringBase64ToByteArray(item.Dados);
            return new File([byteArray], item.Nome, { type: mimeType(item.Extensao) });
        }
    }));
    return data;
};

export const excludeFile = (id) => axiosWalletRequest(`ProdutoBusiness/ExcluirImagem/${id}`, METHOD.DELETE);

export const getShopping = (filter) => axiosWalletRequest('ProdutoBusiness/FiltrarCompra', METHOD.POST, filter);
/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GridColumn } from '@progress/kendo-react-grid';
import { OrangeButton, WhiteButton } from '../../../components/buttonsComponent';
import FormFieldDrop from '../../../components/formField/drop';
import FormFieldCheckbox from '../../../components/formField/checkbox';
import GenericField, { INTERNAL_ICON } from '../../../components/formField/genericField';
import FormRow from '../../../components/formRow';
import { PopupClassTax, PopupCenterCost, PopupPlanAccount } from '../../../components/popUps';
import PopupMeasureUnit from '../../../components/popUps/popUpMeasureUnit';
import PopUpError from '../../../components/popUps/popUpError';
import ToggleInvoice from '../../../components/toggleInvoice';
import { useForms } from '../../../hooks';
import * as centerCostService from '../../../services/centerCost';
import * as planAccountService from '../../../services/planAccount';
import * as classTaxService from '../../../services/taxClass';
import * as serviceService from '../../../services/service';
import { AuthContext } from '../../../auth/AuthContext';
import { successNotification, errorNotification, warningNotification } from '../../../components/notification';
import LoadScreen from '../../load';
import GridInline from '../../../components/grid';
import NumberCell from '../../../components/grid/numberCell';
import PopUpConfirm from '../../../components/popUps/popUpConfirm';

const inittialClassTaxFields = {
  Nome: '',
  Id: null,
  BaseCalculo: null,
  Aliquota: null,
  BaseCalculoFederal: null,
  AliqPIS: null,
  AliqCOFINS: null,
  AliqCSLL: null,
  AliqIRRF: null,
  AliqINSS: null,
  Editavel: false
};

const initialForm = {
  codigo: '',
  serviceDescription: '',
  codigoTrib: '',
  listService: '',
  valueService: 0,
  aliquota: 0,

  classTaxFields: inittialClassTaxFields,
  centerCost: null,
  planAccount: null,
  additionalDescription: '',
  unit: null
};

const initialMeasureUnit = {
  Nome: '',
  Editavel: false
};

const ServiceRegistration = () => {
  const { id } = useParams();
  const history = useHistory();
  const { walletSelected } = useContext(AuthContext);
  const [form, updateProperty, setForm, updateFormValue] = useForms(initialForm);
  const [centerCosts, setCenterCosts] = useState([]);
  const [planAccounts, setPlansAccounts] = useState([]);
  const [taxClasses, setTaxClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [simplesNacional, setSimplesNacional] = useState(false);
  const [units, setUnits] = useState([]);
  const [taxClass, setTaxClass] = useState([]);
  const [measureUnit, setMeasureUnit] = useState(initialMeasureUnit);
  const [measureUnits, setMeasureUnits] = useState([]);
  const [loadingUnit, setLoadingUnit] = useState(false);
  const [taxesISS, setTaxesISS] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [debitNote, setDebitNote] = useState(false);
  const [popUpConfirm, setPopUpConfirm] = useState(false);

  const [popupVisibility, , , updatePopupVisibility] = useForms({
    newClassTax: false,
    editClassTax: false,
    createCenterCost: false,
    createPlanAccount: false,
    addMeasureUnit: false,
    editMeasureUnit: false
  });

  const loadTaxes = async (taxClass) => {
    if (taxClass) {
      setTaxesISS([
        {
          Id: 'ISS',
          BaseCalculo: taxClass.BaseCalculoISS,
          Aliquota: taxClass.AliquotaISS
        }
      ]);
      const taxes = [];
      taxes.push({
        Id: 'PIS',
        BaseCalculo: taxClass.BaseCalculoPIS,
        Aliquota: taxClass.AliquotaPIS
      });
      taxes.push({
        Id: 'COFINS',
        BaseCalculo: taxClass.BaseCalculoCOFINS,
        Aliquota: taxClass.AliquotaCOFINS
      });
      taxes.push({
        Id: 'CSLL',
        BaseCalculo: taxClass.BaseCalculoCSLL,
        Aliquota: taxClass.AliquotaCSLL
      });
      taxes.push({
        Id: 'IRRF',
        BaseCalculo: taxClass.BaseCalculoIRRF,
        Aliquota: taxClass.AliquotaIRRF
      });
      taxes.push({
        Id: 'INSS',
        BaseCalculo: taxClass.BaseCalculoINSS,
        Aliquota: taxClass.AliquotaINSS
      });
      setTaxes(taxes);
    }
  }
  const fetchTaxClass = async (idTaxClass) => {
    const { data } = await classTaxService.getById(idTaxClass);
    setTaxClass(data);
    loadTaxes(data);
    return data;
  };

  const fetchTaxClasses = async () => {
    const { data } = await classTaxService.getAll();
    setTaxClasses(data);
    return data
  };

  const fetchCenterCosts = async () => {
    const { data } = await centerCostService.getAll();
    setCenterCosts(data);
    return data;
  };

  const fetchPlanAccounts = async () => {
    const data = await planAccountService.getAllRevenues();
    setPlansAccounts(data);
    return data;
  };

  const loadMeasureUnits = async (measureUnit) => {
    setLoadingUnit(true);
    const { data } = await serviceService.getMeasureUnits();
    setMeasureUnits(data);
    setMeasureUnit(measureUnit);
    setLoadingUnit(false);
    return data;
  };

  useEffect(() => {
    const initScreen = async () => {
      setLoading(true);
      const measureUnits = await loadMeasureUnits();
      const centerCosts = await fetchCenterCosts();
      const planAccounts = await fetchPlanAccounts();
      let simplesNacional = false;
      if (walletSelected?.CarteiraBusiness?.SimplesNacional || walletSelected?.CarteiraBusiness?.MEI)
        simplesNacional = true;
      setSimplesNacional(simplesNacional);
      let taxClasses = [];
      if (!simplesNacional)
        taxClasses = await fetchTaxClasses();

      if (id) {
        const { data } = await serviceService.getById(id);
        const centerCost = centerCosts.find((element) => element.Id === data.CentroDeCustos_Id);
        const planAccount = planAccounts.find((element) => element.Id === data.PlanoDeContas_Id);
        const taxClass = taxClasses.find((element) => element.Id === data.ClasseDeImpostos_Id);
        setForm({
          codigo: data.Codigo,
          serviceDescription: data.Descricao,
          codigoTrib: data.CodigoTributacao,
          listService: data.ListaServicos,
          valueService: data.Valor,
          centerCost,
          planAccount,
          additionalDescription: data.DescricaoAdicional
        });
        let itemMeasureUnit = measureUnits.find(
          (element) => element.Id === data.UnidadeMedida_Id
        );
        itemMeasureUnit = {
          ...itemMeasureUnit,
          Editavel: true
        };
        setMeasureUnit(itemMeasureUnit);
        setTaxClass(taxClass);
        setDebitNote(data.NotaDebito);
        loadTaxes(taxClass);
      }
      setLoading(false);
    };

    initScreen();
  }, []);

  const redirectToServiceList = () => history.push('/services');

  const submitCreateService = async (bodyForm) => {
    const res = await serviceService.create(bodyForm);

    return res.status === 200 ? `Servi�o ${form.codigo} foi criado!` : null;
  };

  const submitEditService = async (bodyForm) => {
    const res = await serviceService.update(id, bodyForm);

    return res.status === 200 ? `Servi�o ${form.codigo} foi atualizado!` : null;
  };

  const setPopUpVisibility = (propName, value) => {
    updatePopupVisibility({
      ...popupVisibility,
      [propName]: value
    });
  };

  const validate = async () => {
    if (form.valueService < 0) {
      warningNotification('O campo valor do servi�o deve ser maior ou igual a zero.');
      return false;
    }
    return true;
  };

  const submitFormValidated = async (debitNote) => {
    setLoading(true);
    const bodyForm = {
      Descricao: form.serviceDescription,
      CodigoTributacao: form.codigoTrib,
      Valor: form.valueService,
      Codigo: form.codigo,
      ListaServicos: form.listService,
      Carteira_Id: walletSelected.IdCarteira,
      CentroDeCustos_Id: form.centerCost?.Id,
      ClasseDeImpostos_Id: (simplesNacional) ? null : taxClass?.Id,
      PlanoDeContas_Id: form.planAccount?.Id,
      DescricaoAdicional: form.additionalDescription,
      UnidadeMedida_Id: measureUnit?.Id,
      NotaDebito: debitNote
    };
    let response = null;
    if (id) response = await serviceService.update(id, bodyForm);
    else response = await serviceService.create(bodyForm);
    if (response.status === 200) {
      successNotification('Servi�o criado/editado com sucesso!');
      redirectToServiceList();
    } else {
      const { data } = response;
      errorNotification(data.MensagemUsuario);
    }
    setLoading(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (await validate()) {
      if (debitNote) {
        let response = null;
        if (id) response = await serviceService.checkDebitNote(id);
        else response = await serviceService.checkDebitNote(null);
        const { data } = response;
        if (data) setPopUpConfirm(true);
        else submitFormValidated(true);
      }
      else submitFormValidated(false);
    }
  };

  return loading ? (
    <LoadScreen />
  ) : (
    <>
      {id ? <h1>Atualizar Servi�o</h1> : <h1>Criar Servi�o</h1>}
      <form onSubmit={submitForm}>
        <FormRow>
          <GenericField
            titleLabel="C�digo"
            classNameWrapper="fill-15-field"
            valueInput={form.codigo}
            onChangeValue={updateProperty}
            name="codigo"
            required
            msg="C�digo atribuido internamente ao servi�o. Exemplos: S-001, 001/1, 002 etc."
            enableInfo
          />
        </FormRow>

        <FormRow>
          <GenericField
            titleLabel="Descri��o do Servi�o"
            classNameWrapper="fill-100-field"
            isTextArea
            valueInput={form.serviceDescription}
            onChangeValue={(val) => {
              updateFormValue('serviceDescription', val.value);
            }}
            required
          />
        </FormRow>
        <FormRow>
          <GenericField
            titleLabel="Complemento da Descri��o do Servi�o"
            classNameWrapper="fill-100-field"
            isTextArea
            valueInput={form.additionalDescription}
            onChangeValue={(val) => {
              updateFormValue('additionalDescription', val.value);
            }}
          />
        </FormRow>

        <FormRow withShrink>
          <GenericField
            titleLabel="C�digo de Tributa��o do Munic�pio"
            classNameWrapper="fill-25-field"
            msg="Informe-se sobre este n�mero com seu contador"
            enableInfo
            valueInput={form.codigoTrib}
            onChangeValue={updateProperty}
            name="codigoTrib"
          />
          <GenericField
            titleLabel="Lista de Servi�os LC116/3"
            classNameWrapper="fill-25-field"
            enableInfo
            msg="Informe-se sobre este n�mero com seu contador"
            valueInput={form.listService}
            onChangeValue={updateProperty}
            name="listService"
          />
          <FormFieldDrop
            titleLabel="Unidade de Medida"
            defaultValueSelect="Selecione..."
            onChangeValue={(val) => {
              const measureUnit = val.value;
              if (measureUnit == null) setMeasureUnit(initialForm);
              else {
                measureUnit.Editavel = true;
                setMeasureUnit(measureUnit);
              }
            }}
            value={measureUnit}
            className="fill-25-field"
            infosSelect={measureUnits}
            dataItemKey="Id"
            textField="Nome"
            enablePencil={measureUnit?.Editavel}
            enableIcon
            onClickIcon={() => updatePopupVisibility('addMeasureUnit', true)}
            onClickPencil={() => updatePopupVisibility('editMeasureUnit', true)}
          />

          <GenericField
            titleLabel="Valor do Servi�o"
            classNameWrapper="fill-25-field"
            iconEnabled={INTERNAL_ICON.REAIS_ICON}
            valueInput={form.valueService}
            setValue={(val) => {
              updateFormValue('valueService', val)
            }}
            required
            min="0"
          />
        </FormRow>

        {simplesNacional ?
          null
          :
          <ToggleInvoice title="Impostos" startExpanded>
            <FormRow>
              <FormFieldDrop
                titleLabel="Classe de Impostos"
                infosSelect={taxClasses}
                onChangeValue={(event) => {
                  if (event.value) {
                    fetchTaxClass(event.value.Id);
                  }
                }}
                value={taxClass}
                defaultValueSelect="Selecione..."
                className="fill-50-field"
                dataItemKey="Id"
                textField="Nome"
                name="TaxClass"
              />

            </FormRow>
            <FormRow>
              <GridInline data={taxesISS} setData={setTaxesISS}>
                <GridColumn field="Id" title="Imposto Municipal" editable={false} />
                <GridColumn
                  field="BaseCalculo"
                  title="Base de C�lculo (%)"
                  editable={false}
                  editor="numeric"
                  cell={NumberCell}
                />
                <GridColumn
                  field="Aliquota"
                  title="Al�quota (%)"
                  editable={false}
                  editor="numeric"
                  cell={NumberCell}
                />
              </GridInline>
            </FormRow>
            <FormRow>
              <GridInline data={taxes} setData={setTaxes}>
                <GridColumn field="Id" title="Reten��es Federais" editable={false} />
                <GridColumn
                  field="BaseCalculo"
                  title="Base de C�lculo (%)"
                  editable={false}
                  editor="numeric"
                  cell={NumberCell}
                />
                <GridColumn
                  field="Aliquota"
                  title="Al�quota (%)"
                  editable={false}
                  editor="numeric"
                  cell={NumberCell}
                />
              </GridInline>
            </FormRow>
          </ToggleInvoice>
        }

        <ToggleInvoice title="Classifica��o" startExpanded>
          <FormRow withShrink>
            <FormFieldDrop
              titleLabel="Plano de contas"
              defaultValueSelect="Selecione..."
              infosSelect={planAccounts}
              textField="Nome"
              value={form.planAccount}
              onChangeValue={updateProperty}
              name="planAccount"
              dataItemKey="Nome"
              className="fill-50-field"
              enableIcon
              onClickIcon={() => updatePopupVisibility('createPlanAccount', true)}
            />

            <FormFieldDrop
              titleLabel="Centro de Custo"
              defaultValueSelect="Selecione..."
              infosSelect={centerCosts}
              textField="Nome"
              value={form.centerCost}
              onChangeValue={updateProperty}
              name="centerCost"
              className="fill-50-field"
              enableIcon
              onClickIcon={() => updatePopupVisibility('createCenterCost', true)}
            />
          </FormRow>
          <FormRow>
            <FormFieldCheckbox
              label="Padr�o para Nota de D�bito"
              classNameWrapper="fill-50-field"
              value={debitNote}
              onChange={(val) => {
                setDebitNote(val.value);
              }}
            />
          </FormRow>
        </ToggleInvoice>
        <FormRow justifyEnd space15 marginTop40>
          <OrangeButton type="submit">Salvar</OrangeButton>
          <WhiteButton onClick={() => history.goBack()}>Cancelar</WhiteButton>
        </FormRow>
      </form>

      <PopupClassTax
        visible={popupVisibility.newClassTax}
        setVisible={(val) => updatePopupVisibility('newClassTax', val)}
        setTaxClass={setTaxClass}
        setTaxClasses={setTaxClasses}
      />

      <PopupClassTax
        visible={popupVisibility.editClassTax}
        setVisible={(val) => updatePopupVisibility('editClassTax', val)}
        entity={taxClass}
        isEdit
        setTaxClass={setTaxClass}
        setTaxClasses={setTaxClasses}
      />

      <PopupCenterCost
        visible={popupVisibility.createCenterCost}
        setVisible={(val) => updatePopupVisibility('createCenterCost', val)}
        whenCreateNewCenterCost={fetchCenterCosts}
      />

      <PopupPlanAccount
        visible={popupVisibility.createPlanAccount}
        setVisible={(val) => updatePopupVisibility('createPlanAccount', val)}
        whenCreateNewPlanAccount={fetchPlanAccounts}
      />
      <PopupMeasureUnit
        visible={popupVisibility.addMeasureUnit}
        setVisible={(val) => updatePopupVisibility('addMeasureUnit', val)}
        reloadMeasureUnits={loadMeasureUnits}
      />
      <PopupMeasureUnit
        visible={popupVisibility.editMeasureUnit}
        setVisible={(val) => updatePopupVisibility('editMeasureUnit', val)}
        reloadMeasureUnits={loadMeasureUnits}
        entity={measureUnit}
        isEdit
      />
      <PopUpConfirm
        onConfirm={() => submitFormValidated(true)}
        onCancel={() => submitFormValidated(false)}
        visible={popUpConfirm}
        setVisible={setPopUpConfirm}
        title="Atualizar Servi�o"
        message="J� existe um servi�o padr�o para nota de d�bito definido. Deseja tornar este servi�o o padr�o para nota de d�bito?"
      />
    </>
  );
};

export default ServiceRegistration;
